import { RoleModel } from '@hmedia/legenda-ds-api-client'
import { useMutation, useQuery } from 'react-query'
import API from '../../services/DSApiService'
import { RoleEnum } from '../../services/security/privileges'

const getUsers = async () => (await API.getUsers()).data
const setUserEnabled = async (username: string, enabled: boolean) =>
  await API.setUserEnabled(username, enabled)
const setUserRole = async (username: string, role: string) => await API.setUserRole(username, role)

const getAllRoles = async () => (await API.getAllRoles()).data
const createRole = async (title: string) => (await API.createRole(title)).data
const deleteRole = async (roleToDelete: string, replacementRole: string) =>
  await API.deleteRole(roleToDelete, replacementRole)
const getAllAuthorities = async () => (await API.getAllAuthorities()).data
const setRolePrivileges = async (params: SetPrivilegeToRoleMutationParams) =>
  (await API.setPrivilegesToRole(params.rolename, params.privilegeIds)).data

/**
 *
 * Queries, mutations
 */
export const useUsersQuery = () => useQuery('auth-users', () => getUsers())

export const useSetUserEnabledMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation(
    (params: UserEnabledMutationParams) => setUserEnabled(params.username, params.enabled),
    { onSuccess: onSuccess, onError: onError },
  )

export const useSetUserRoleMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((params: UserRoleMutationParams) => setUserRole(params.username, params.roleName), {
    onSuccess: onSuccess,
    onError: onError,
  })

export const useAllRolesQuery = () => useQuery('all-roles', () => getAllRoles())

export const useAllAuthoritiesQuery = () => useQuery('all-authorities', () => getAllAuthorities())

export const useSetPrivilegeToRoleMutation = (
  onSuccess?: (data: RoleModel) => void,
  onError?: (error) => void,
) =>
  useMutation((params: SetPrivilegeToRoleMutationParams) => setRolePrivileges(params), {
    onSuccess: onSuccess,
    onError: onError,
  })

export const useCreateRoleMutation = (
  onSuccess?: (data: RoleModel) => void,
  onError?: (error) => void,
) =>
  useMutation((params: CreateRoleMutationParams) => createRole(params.title), {
    onSuccess: onSuccess,
    onError: onError,
  })

export const useDeleteRoleMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation(
    (params: DeleteRoleMutationParams) => deleteRole(params.roleToDelete, params.replacementRole),
    { onSuccess: onSuccess, onError: onError },
  )

/**
 *
 * Params
 */
type UserEnabledMutationParams = { username: string; enabled: boolean }
type UserRoleMutationParams = { username: string; roleName: string }
type SetPrivilegeToRoleMutationParams = { rolename: string; privilegeIds: number[] }
type CreateRoleMutationParams = { title: string }
type DeleteRoleMutationParams = { roleToDelete: string; replacementRole: string }
