import { ApiError } from '@hmedia/legenda-ds-api-client'
import { Button, ConfigProvider, Layout } from 'antd'

import 'antd/dist/reset.css'

import huHU from 'antd/es/locale/hu_HU'
import { AxiosError } from 'axios'
import 'fontsource-roboto/100.css'
import 'fontsource-roboto/300.css'
import 'fontsource-roboto/400.css'
import 'fontsource-roboto/500.css'
import 'fontsource-roboto/700.css'
import 'fontsource-roboto/900.css'
import React, { FC, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ActiveMenu from 'components/ActiveMenu/ActiveMenu'
import LoginView from 'components/LoginView/LoginView'
import Routing from 'components/Routing/Routing'
import { ScrollableContent } from 'components/ScrollableContent/ScrollableContent'
import './index.css'
import AuthService from 'services/authService'
import API from 'services/DSApiService'
import { PermissionProvider } from 'services/security/permissionContext'
import { TokenService } from 'services/security/tokenService'

export const App: FC = (props) => {
  const [token, setToken] = useState<string | undefined>(undefined)
  const { reset } = useQueryErrorResetBoundary()

  const errorHandler = (error: AxiosError<ApiError> | any, query) => {
    const errMsg = error?.errorMessage || error?.response?.data?.errorMessage || error?.message
    if (error?.errorCode === 'refresh-token-expired') {
      reset()
      API.logout()
      setToken(undefined)
      toast.error(error?.errorMessage)
    } else {
      console.error(errMsg)
    }
  }
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: import.meta.env.PROD,
      },
    },
    mutationCache: new MutationCache({ onError: errorHandler }),
    queryCache: new QueryCache({ onError: errorHandler }),
  })

  useEffect(() => {
    const tokenData = AuthService.getCurrentUser()
    if (!tokenData?.accessToken) {
      setToken(undefined)
      return
    }

    if (TokenService.isAccessTokenExpired()) {
      AuthService.refreshToken()
        .then(() => setToken(AuthService.getCurrentUser()?.accessToken))
        .catch((err) => setToken(undefined))
    } else {
      setToken(tokenData.accessToken)
    }
  }, [])

  const onLogin = (username, password, onError) => {
    AuthService.login(username, password)
      .then((resp) => setToken(resp.data?.accessToken))
      .catch((err) => onError(err))
  }

  const onLogout = () => {
    API.logout()
    setToken(undefined)
  }

  return (
    <ConfigProvider locale={huHU} theme={{ hashed: false, token: { 
      borderRadius: 0,
    } }}>
      <ErrorBoundary
        onReset={reset}
        fallbackRender={({ resetErrorBoundary, error }) => (
          <div>
            Hiba történt: {error.message}
            <Button onClick={() => resetErrorBoundary()}>Próbáld újra!</Button>
          </div>
        )}
      >
        <QueryClientProvider client={queryClient}>
          <Router>
            <PermissionProvider grantedPrivileges={AuthService.getCurrentUserPrivileges()}>
              {(token && (
                <Layout id={'applayout'} hasSider={true} {...props}>
                  <ActiveMenu onLogout={onLogout} />
                  <Layout.Content>
                    <ScrollableContent>
                      {/* <ScrollToTop /> */}
                      <Routing />
                      {props.children}
                    </ScrollableContent>
                  </Layout.Content>
                </Layout>
              )) || <LoginView onLogin={onLogin} />}
            </PermissionProvider>
          </Router>
          <ToastContainer style={{ width: '420px' }} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </ConfigProvider>
  )
}
