import { dayjs } from '@hmedia/legenda-ds-common'
import 'assets/fonts/fonts.css'
import classNames from 'classnames'
import type { FC } from 'react'
import type { InformTypeConstantDTO } from '../../domains/inform/models'
import './styles.css'
import styl from './styles.module.css'

interface Props {
  type: InformTypeConstantDTO
  width?: number
  height?: number
  title?: string | null
  html?: string | null
  isTodayShown?: boolean
}

export const InformPreview: FC<Props> = ({
  type = 'INFO',
  width = 960,
  height = 540,
  title = null,
  isTodayShown = false,
  html = null,
  ...props
}) => {
  return type === 'ACTUALITY' ? (
    <ActualityPreview
      title={title}
      width={1080}
      height={460}
      html={html}
      isTodayShown={isTodayShown}
    />
  ) : (
    <InfoPreview title={title} width={width} height={height} html={html} />
  )
}

const InfoPreview: FC<Omit<Props, 'isTodayShown' | 'type'>> = ({
  width,
  height,
  title,
  html,
  ...props
}) => {
  return (
    <div
      className={styl.previewContainer}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      {title && <div className={styl.previewTitle}>{title}</div>}
      <div className={styl.previewBody} dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

type ActualityProps = Omit<Props, 'type'>

const ActualityPreview: FC<ActualityProps> = ({
  width,
  height,
  title,
  html,
  isTodayShown,
  ...props
}) => {
  const todayDate = dayjs().format('DD. MM. YYYY.')

  return (
    <div
      className={classNames(styl.previewContainer, styl.actuality)}
      style={{
        width: `${width}px`,
        height: `${height * 0.7}px`,
      }}
    >
      <div className={styl.actualityWrapper}>
        {title && (
          <div className={styl.previewTitle}>
            {title}
            {isTodayShown && <span className={styl.dateOfToday}>{' - ' + todayDate}</span>}
          </div>
        )}
        <div className={styl.previewBody} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  )
}
