import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Input, Form, Checkbox, Button } from 'antd'
import { FC } from 'react'
import { toast } from 'react-toastify'
import * as S from './style'

type FormValues = {
  username: string
  password: string
}

type Props = {
  onLogin: (username, password, onError?: (err) => void) => void
}

const LoginView: FC<Props> = ({ onLogin, ...props }) => {
  const initialValues: FormValues = {
    username: '',
    password: '',
  }

  return (
    <S.LoginView>
      <S.LoginWrapper>
        <S.LoginTitle>Bejelentkezés</S.LoginTitle>
        <Form<FormValues>
          name="normal_login"
          initialValues={initialValues}
          onFinish={(values) =>
            onLogin(values.username, values.password, (err) => {
              toast.error(err.response?.data?.errorMessage, { closeButton: true, autoClose: 8000 })
            })
          }
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Kötelező megadni!',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="felhasználónév" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Kötelező megadni!',
              },
            ]}
          >
            <Input prefix={<LockOutlined />} type="password" placeholder="jelszó" />
          </Form.Item>
          <Form.Item>
            <S.LoginSubmitButton type="primary" size="medium" htmlType="submit">
              Bejelentkezés
            </S.LoginSubmitButton>
          </Form.Item>
        </Form>
        <S.LoginFootNote>
          A belépéshez szükség lesz a Legenda felhasználónevedre és jelszavadra.
        </S.LoginFootNote>
        <S.VersionNumber>v.{window['__ENV']?.VITE_VERSION}</S.VersionNumber>
      </S.LoginWrapper>
    </S.LoginView>
  )
}

export default LoginView
