import { Privilege, Role } from './security/privileges'
import TokenService from './security/tokenService'
import DSApiService from './DSApiService'
import { JwtResponse } from '@hmedia/legenda-ds-api-client'
import API from './DSApiService'

const login = async (username, password) =>
  DSApiService.login(username, password).then((response) => {
    if (response.data.accessToken) {
      TokenService.setUser(response.data)
    }

    return response
  })

const logout = () => {
  TokenService.removeUser()
}

const getCurrentUser = (): JwtResponse => {
  return JSON.parse(localStorage.getItem('user')) as JwtResponse
}
const getCurrentUserPrivileges = (): Privilege[] => getCurrentUser()?.roles as Privilege[] | []

const refreshToken = async () => {
  const accessToken = (await API.refreshToken(TokenService.getLocalRefreshToken())).data.accessToken
  TokenService.updateLocalAccessToken(accessToken)
}

const AuthService = {
  login,
  logout,
  getCurrentUser,
  getCurrentUserPrivileges,
  refreshToken,
}

export default AuthService
