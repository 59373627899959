import { DatePicker } from '@hmedia/legenda-ds-react'
import { Form, Input, Modal } from 'antd'
import huHU from 'antd/lib/date-picker/locale/hu_HU'
import { useForm } from 'antd/es/form/Form'
import type { Dayjs } from 'dayjs'
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import type { FC } from 'react'
const { RangePicker } = DatePicker

export type FormValues = {
  name: string
  timeRange: [Dayjs, Dayjs]
}

interface CreateSeasonFormProps {
  visible: boolean
  onCreate?: (values: FormValues) => void
  onCancel?: () => void
}

const initialValues = { name: null, timeRange: null }
const requiredMessage = 'Kötelező megadni'

const requiredRule = { required: true, message: requiredMessage }

const CreateSeasonForm: FC<CreateSeasonFormProps> = ({ visible, onCancel, onCreate }) => {
  const [form] = useForm()

  return (
    <>
      <Modal
        title="Szezon létrehozása"
        width={820}
        open={visible}
        okText="Létrehozás"
        cancelText="Mégsem"
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values)
              form.resetFields()
            })
            .catch((errorInfo: ValidateErrorEntity<FormValues>) => {
              const desc = errorInfo.errorFields.map((err) => {
                return err.errors.join('/n/r')
              })
            })
        }}
        onCancel={(_evt): void => {
          form.resetFields()
          onCancel()
        }}
      >
        <Form layout="inline" form={form} initialValues={initialValues} autoComplete="off">
          <Form.Item label="Megnevezés" name="name" rules={[requiredRule]}>
            <Input />
          </Form.Item>
          <Form.Item label="Ettől-eddig tart" name="timeRange" rules={[requiredRule]}>
            <RangePicker locale={huHU} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateSeasonForm
