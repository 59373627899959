import {
  AddUniqueDepartureRequest,
  UpdateUniqueDepartureStatusesRequest,
} from '@hmedia/legenda-ds-api-client'
import { useMutation, useQuery } from 'react-query'
import API from '../../services/DSApiService'

const fetchDeparturesByDate = async (date: string) => (await API.getAllDeparturesByDate(date)).data

const createUniqueDepartures = async (request: AddUniqueDepartureRequest) =>
  await API.createUniqueDepartures(request)
const updateUniqueDepartures = async (request: UpdateUniqueDepartureStatusesRequest) =>
  await API.updateUniqueDepartureStatus(request)

/**
 * Mutations, queries
 */

export const useDeparturesByDateQuery = (date: string) =>
  useQuery(['departures', date], () => fetchDeparturesByDate(date))

export const useCreateUniqueDeparturesMutation = (
  onSuccess?: () => void,
  onError?: (error) => void,
) =>
  useMutation((params: AddUniqueDepartureRequest) => createUniqueDepartures(params), {
    onSuccess: onSuccess,
    onError: onError,
  })

export const useUpdateUniqueDeparturesMutation = (
  onSuccess?: () => void,
  onError?: (error) => void,
) =>
  useMutation((params: UpdateUniqueDepartureStatusesRequest) => updateUniqueDepartures(params), {
    onSuccess: onSuccess,
    onError: onError,
  })
