import {
  AddDepartureRequest,
  Cruise,
  DepartureModelDayEnum,
  DepartureModelGateEnum,
  LocalTime,
  SeasonModel,
  UpdateDepartureStatusesRequestDayOfWeekEnum,
  UpdateDepartureStatusesRequestGateEnum,
} from '@hmedia/legenda-ds-api-client'
import { useMutation, useQuery } from 'react-query'
import API from '../../services/DSApiService'

const syncDeparturesBySeason = async (seasonId: string, year: string) =>
  API.syncDepartures(seasonId, year)

const fetchDeparturesBySeason = async (seasonId: string) =>
  (await API.getAllDeparturesBySeason(seasonId)).data

const fetchSeasons = async () => (await API.getSeasons()).data

const createSeason = async (params: CreateSeasonMutation) =>
  (await API.createSeason(params.name, params.startDate, params.endDate)).data

const updateDepartures = async (params: UpdateDeparturesMutation) =>
  API.updateDepartures({
    seasonId: params.seasonId,
    cruiseId: params.cruiseId,
    startTime: params.startTime as LocalTime,
    dayOfWeek: params.dayOfWeek as unknown as UpdateDepartureStatusesRequestDayOfWeekEnum,
    startDays: params.startDays,
    isFull: params.isFull,
    isCancelled: params.isCancelled,
    gate: params.gate as unknown as UpdateDepartureStatusesRequestGateEnum,
    isPublished: params.isPublished,
    includesUnique: params.includesUniques,
  })

const getInternalCruisesNotMapped = async () => (await API.getInternalCruisesNotMapped()).data

const fetchCruises = async () => (await API.getCruises()).data

const createCruise = async (params: CreateUpdateCruiseMutation) =>
  (await API.createCruise(params.name, params.description, params.internalName, params.internalId))
    .data

const updateCruise = async (params: CreateUpdateCruiseMutation) =>
  (
    await API.updateCruise(
      params.id,
      params.name,
      params.description,
      params.internalName,
      params.internalId,
    )
  ).data

const createDepartures = async (addDepartureRequest: AddDepartureRequest) =>
  await API.createDepartures(addDepartureRequest)

const deleteCruise = async (id: number) => API.deleteCruise(id)
const deleteSeason = async (id: number) => API.deleteSeason(id)

/**
 *
 * Queries, mutations
 */

export const useCreateDeparturesMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((params: AddDepartureRequest) => createDepartures(params), {
    onSuccess: onSuccess,
    onError: onError,
  })

export const useDeparturesQuery = (seasonId: string) =>
  useQuery(['departures', seasonId], () => fetchDeparturesBySeason(seasonId))

export const useSyncDeparturesQuery = (
  seasonId: string,
  year: string,
  onSuccess?: (data) => void,
  onError?: (error) => void,
) =>
  useQuery(['departures-sync', seasonId, year], () => syncDeparturesBySeason(seasonId, year), {
    enabled: false,
    onSuccess: onSuccess,
    onError: onError,
  })

export const useSeasonsQuery = () => useQuery(['seasons'], () => fetchSeasons())

export const useCreateSeasonMutation = (
  onSuccess?: (data: SeasonModel) => void,
  onError?: (error) => void,
) =>
  useMutation((params: CreateSeasonMutation) => createSeason(params), {
    onSuccess: onSuccess,
    onError: onError,
  })

export const useUpdateDeparturesMutation = (
  onSuccess?: (data) => void,
  onError?: (error) => void,
) =>
  useMutation((params: UpdateDeparturesMutation) => updateDepartures(params), {
    onSuccess: onSuccess,
    onError: onError,
  })

export const useInternalCruisesNotMappedQuery = () =>
  useQuery(['internalCruisesNotMapped'], () => getInternalCruisesNotMapped())

export const useCruisesQuery = () => useQuery(['cruises'], () => fetchCruises())

export const useCreateUpdateCruiseMutation = (
  onSuccess?: (data: Cruise) => void,
  onError?: (error) => void,
) =>
  useMutation(
    (params: CreateUpdateCruiseMutation) => {
      return params.id ? updateCruise(params) : createCruise(params)
    },
    { onSuccess: onSuccess, onError: onError },
  )

export const useDeleteCruiseMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((id: number) => deleteCruise(id), { onSuccess: onSuccess, onError: onError })

export const useDeleteSeasonMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((id: number) => deleteSeason(id), { onSuccess: onSuccess, onError: onError })

export type CreateSeasonMutation = { name: string; startDate: string; endDate: string }
export type CreateUpdateCruiseMutation = {
  name?: string
  description?: string
  internalName?: string
  internalId?: string
  id?: string
}

export type UpdateDeparturesMutation = {
  seasonId: number
  cruiseId: number
  startTime: string
  dayOfWeek?: DepartureModelDayEnum
  startDays?: Array<string>
  isFull?: boolean
  isCancelled?: boolean
  gate?: DepartureModelGateEnum
  isPublished?: boolean
  includesUniques?: boolean
}
