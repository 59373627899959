import { PlusOutlined } from '@ant-design/icons'
import { SeasonModel } from '@hmedia/legenda-ds-api-client'
import { Button, Empty, notification, Space, Tabs } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import CreateSeasonForm, { FormValues } from './CreateSeasonModalForm'
import { DepartureListContainer } from './DepartureListContainer'
import { useCreateSeasonMutation, useSeasonsQuery } from './queries'
const { TabPane } = Tabs

export type Props = {
  pageTitle?: string
}

export const TIMETABLEVIEW_META: ViewMeta = {
  path: '/timetable',
  title: 'Menetrend',
}

const TimetableView: FC<Props> = ({ pageTitle }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false)
  const [activeSeasonTabKey, setActiveSeasonTabKey] = useState<string>()
  const queryClient = useQueryClient()
  const seasonsQuery = useSeasonsQuery()
  const createSeasonMutation = useCreateSeasonMutation((newSeason) => {
    queryClient.invalidateQueries('seasons')
    setModalIsVisible(false)
    setActiveSeasonTabKey(`${newSeason.id}`)
  })

  const styles: any = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  }

  const onFormSubmit = (values: FormValues) => {
    createSeasonMutation.mutate({
      name: values.name,
      startDate: values.timeRange[0].format('YYYY-MM-DD'),
      endDate: values.timeRange[1].format('YYYY-MM-DD'),
    })
  }
  const onSeasonDelete = () => {
    seasonsQuery.refetch()
    setActiveSeasonTabKey(getDefaultActiveTabKey(seasonsQuery.data))
  }

  const getDefaultActiveTabKey = (data?: SeasonModel[]) => {
    return (
      data?.find((season) => season.isRunningSeason)?.id?.toString() || data?.[0]?.id?.toString()
    )
  }

  useEffect(() => {
    setActiveSeasonTabKey(getDefaultActiveTabKey(seasonsQuery.data))

    return () => {}
  }, [seasonsQuery.data])

  return (
    <>
      <div style={{ width: '100%' }}>
        <h1 style={{ marginTop: '2rem' }}>Menetrend</h1>
        <Space direction="horizontal">
          <Button onClick={() => setModalIsVisible(true)} type="primary" icon={<PlusOutlined />}>
            Új szezon hozzáadása
          </Button>
        </Space>
        {(seasonsQuery.data?.length !== 0 && (
          <Tabs
            defaultActiveKey={getDefaultActiveTabKey(seasonsQuery.data)}
            type="line"
            onChange={(activeKey) => {
              setActiveSeasonTabKey(activeKey)
              return queryClient.invalidateQueries(['departures', activeKey])
            }}
            style={{
              marginTop: '1rem',
              backgroundColor: 'white',
              padding: '1rem',
              width: '100%',
              minHeight: '80vh',
            }}
            activeKey={activeSeasonTabKey}
          >
            {seasonsQuery.data?.map((season: SeasonModel) => (
              <TabPane
                tab={`${season.name} (${season.startDay} - ${season.endDay})`}
                key={season.id}
              >
                <DepartureListContainer season={season} onDelete={onSeasonDelete} />
              </TabPane>
            ))}
          </Tabs>
        )) || (
          <div style={styles}>
            <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} />
          </div>
        )}
      </div>
      {modalIsVisible && (
        <CreateSeasonForm
          visible={modalIsVisible}
          onCreate={onFormSubmit}
          onCancel={() => setModalIsVisible(false)}
        />
      )}
    </>
  )
}

export default TimetableView
