import { Select } from 'antd'
import { DepartureModelGateEnum } from '@hmedia/legenda-ds-api-client'

interface GateSelectorProps {
  value?: DepartureModelGateEnum
  onChange?: (value: DepartureModelGateEnum) => void
  size?: 'small' | 'middle' | 'large'
  className?: string
  disabled?: boolean
  style?: React.CSSProperties
}

export const GateSelector: React.FC<GateSelectorProps> = ({
  value,
  onChange,
  size = 'small',
  className,
  disabled,
  style,
}) => {
  return (
    <Select
      placeholder="Válasszon kaput"
      value={value}
      size={size}
      onChange={onChange}
      className={className}
      disabled={disabled}
      style={style}
    >
      {Object.entries(DepartureModelGateEnum).map(([key, value]) => (
        <Select.Option key={value}>{`GATE ${key.slice(-1)}`}</Select.Option>
      ))}
    </Select>
  )
}
