import { FC } from 'react'
import NumberFormat from 'react-number-format'

interface Props {
  amount: number
  currency: 'EUR' | 'HUF'
}

export const CurrencyFormat: FC<Props> = (props) => {
  const { amount, currency } = props
  return (
    <>
      <NumberFormat
        value={amount}
        thousandSeparator={' '}
        thousandsGroupStyle={'thousand'}
        displayType={'text'}
        prefix={currency === 'EUR' && '€ '}
        suffix={currency === 'HUF' && ' Ft'}
      />
    </>
  )
}
