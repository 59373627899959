import { RoleModel, UserDto } from '@hmedia/legenda-ds-api-client'
import { Select, Space, Switch, Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { getUserRole, Role, RoleEnum } from '../../services/security/privileges'
import { switchChildrens } from '../uniqueDepartures/UniqueDeparturesView'
import {
  useAllRolesQuery,
  useSetUserEnabledMutation,
  useSetUserRoleMutation,
  useUsersQuery,
} from './queries'

export type Props = {
  pageTitle?: string
}

export const USERSVIEW_META: ViewMeta = {
  path: '/users',
  title: 'Felhasználók',
}

const UsersView: FC<Props> = (props) => {
  const usersQuery = useUsersQuery()
  const setUserEnabledMutation = useSetUserEnabledMutation(() => usersQuery.refetch())
  const setUserRoleMutation = useSetUserRoleMutation(() => {
    usersQuery.refetch()
    toast.warn(`A felhasználó új jogköre csak a következő belépése után lesz érvényben!`)
  })
  const roleQuery = useAllRolesQuery()

  const isControlDisabled = (username): boolean => ['miki', 'turcsana'].includes(username)

  const tableCols: ColumnType<UserDto>[] = [
    {
      title: 'Teljes név',
      key: 'fullName',
      dataIndex: 'fullName',
      align: 'left',
      sortOrder: 'ascend',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName, 'en', { sensitivity: 'base' }),
      render: (fullName: string, user: UserDto) => <span>{fullName}</span>,
    },
    {
      title: 'felhasználónév',
      key: 'username',
      dataIndex: 'username',
      align: 'left',
      render: (username: string, user: UserDto) => <span>{username}</span>,
    },
    {
      title: 'Engedélyezve?',
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      align: 'center',
      render: (isEnabled: boolean, user: UserDto) => {
        return (
          <span>
            <Switch
              disabled={isControlDisabled(user.username)}
              loading={usersQuery.isFetching || usersQuery.isLoading}
              checked={isEnabled}
              {...switchChildrens}
              onChange={(bool) =>
                setUserEnabledMutation.mutate({ username: user.username, enabled: bool })
              }
            />
          </span>
        )
      },
    },
    {
      title: 'Jogkör',
      key: 'role',
      dataIndex: 'role',
      align: 'left',
      render: (roles: Role[], user: UserDto) => {
        return (
          <span>
            <Select<RoleEnum>
              style={{ minWidth: '150px' }}
              value={getUserRole(user, roleQuery.data)?.name as RoleEnum}
              placeholder={'nincs megadva'}
              disabled={isControlDisabled(user.username)}
              onChange={(value) => {
                setUserRoleMutation.mutate({ username: user.username, roleName: value })
              }}
            >
              {roleQuery.data &&
                roleQuery.data.map((role) => {
                  return <Select.Option key={role.name}>{role.title}</Select.Option>
                })}
            </Select>
          </span>
        )
      },
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <h1 style={{ marginTop: '2rem' }}>Felhasználók</h1>
      <Space direction="horizontal">
        {/* <Button onClick={() => {}} type="primary" icon={<PlusOutlined />}>
                    Új felhasználó hozzáadása
                </Button> */}
      </Space>
      <Table
        pagination={{ position: ['bottomCenter'] }}
        columns={tableCols}
        sortDirections={['ascend']}
        dataSource={usersQuery.data}
        rowKey={'key'}
      />
    </div>
  )
}

export default UsersView
