import escapeHtml from 'escape-html'
import { Text } from 'slate'

export const slateSerializer = (value) => {
  const serialize = (node) => {
    if (Text.isText(node)) {
      let str = escapeHtml(node.text)
      if (node['highlighted1'] === true) return `<span class='highlighted-1'>${str}</span>`
      if (node['highlightedActuality'] === true)
        return `<span class='highlighted-actuality'>${str}</span>`
      if (node['bold'] === true)
        return `<span class='highlighted-bold'><strong>${str}</strong></span>`
      return str
    }

    const children = node.children.map((n) => serialize(n)).join('')

    switch (node.type) {
      case 'paragraph':
        return `<p>${children}</p>`
      default:
        return children
    }
  }

  return serialize({ children: value })
}
