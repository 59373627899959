import {
  DeleteOutlined,
  DownOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import {
  AddDepartureRequestOnDaysEnum,
  DepartureModel,
  DepartureModelDayEnum,
  DepartureModelGateEnum,
  SeasonModel,
  WeeklyDepartureResponse,
} from '@hmedia/legenda-ds-api-client'
import { CheckCircle, QuestionCircle } from '@styled-icons/fa-regular'
import { Ban, Ship } from '@styled-icons/fa-solid'
import { Button, Checkbox, Col, Dropdown, Menu, Popconfirm, Row, Space, Spin, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import classNames from 'classnames'
import { MenuItem } from 'rc-menu'
import React, { FC, HTMLAttributes, useState } from 'react'
import { useQueryClient } from 'react-query'
import { DepartureDescUpdater } from '../../components/DepartureDescUpdater/DepartureDescUpdater'
import { days, formattedLocalTime } from '../../utils/helpers'
import CruiseManagerModal from './CruiseManagerModal'
import DepartureEditorDrawerForm from './DepartureEditorDrawerForm'
import DepartureSyncModal from './DepartureSyncModal'
import {
  UpdateDeparturesMutation,
  useCreateDeparturesMutation,
  useCruisesQuery,
  useDeleteSeasonMutation,
  useDeparturesQuery,
  useSyncDeparturesQuery,
  useUpdateDeparturesMutation,
} from './queries'
import styles from './styles.module.css'

type Props = {
  season: SeasonModel
  onDelete: () => void
}

const DepartureListContainer: FC<Props> = ({ season, onDelete }) => {
  const seasonId = season.id
  const queryClient = useQueryClient()
  const [cruiseModalIsVisible, setCruiseModalIsVisible] = useState(false)
  const [departureSyncModalIsVisible, setDepartureSyncModalIsVisible] = useState(false)
  const [createDepartureDrawerIsOpen, setCreateDepartureDrawerIsOpen] = useState(false)
  const departuresQuery = useDeparturesQuery(`${seasonId}`)
  const cruisesQuery = useCruisesQuery()
  const [selectedDate, setSelectedDate] = useState(new Date().getFullYear().toString())

  const { data, isLoading } = departuresQuery
  const syncDeparturesQuery = useSyncDeparturesQuery(`${seasonId}`, selectedDate, (data) => {
    departuresQuery.refetch()
    queryClient.invalidateQueries('departures')
    setDepartureSyncModalIsVisible(false)
  })

  const startSync = () => syncDeparturesQuery.refetch()
  const deleteSeasonMutation = useDeleteSeasonMutation(() => onDelete())

  return (
    <>
      <Spin spinning={isLoading || syncDeparturesQuery.isLoading || departuresQuery.isLoading}>
        <div className={styles.container}>
          <h1>{`${season.name} (${season.startDay} - ${season.endDay})`}</h1>
          <Space direction="horizontal" style={{ marginTop: '1rem', width: '100%' }}>
            <Button
              type="default"
              icon={<SyncOutlined />}
              onClick={() => setDepartureSyncModalIsVisible(true)}
            >
              Járatindulások szinkronizálása a Legendával...
            </Button>
            <Button
              type="default"
              onClick={() => {
                setCruiseModalIsVisible(true)
              }}
              icon={<Ship width={20} style={{ marginRight: '0.7rem' }} />}
            >
              Szolgáltatás kezelő
            </Button>
            <Button icon={<PlusOutlined />} onClick={() => setCreateDepartureDrawerIsOpen(true)}>
              Új indulás hozzáadása
            </Button>
            <Popconfirm
              title="Biztos, hogy törlöd az egész szezont és minden kapcsolódó járatát?"
              okText="Törlöm"
              okType="danger"
              onConfirm={() => deleteSeasonMutation.mutate(seasonId)}
              cancelText="mégsem"
            >
              <Button type="text" danger style={{ marginLeft: 'auto' }} icon={<DeleteOutlined />}>
                Szezon törlése
              </Button>
            </Popconfirm>
          </Space>
          <DepartueListLayout
            className={styles.departueListLayout}
            published="indul?"
            indulas="indulás"
            jaratnev="szolgáltatás"
            gate="kapu"
            isNotHeader={false}
            napibontas={<div style={{ marginLeft: '35%' }}>napi bontás</div>}
          />
          {data?.map((item, idx, arr) => {
            let extraClasses = []
            if (idx === 0) extraClasses.push('first')
            if (idx === arr.length - 1) extraClasses.push('last')
            return (
              <DepartureListItem
                key={idx}
                item={item}
                className={extraClasses.join(' ')}
                onMutationSuccess={() => departuresQuery.refetch()}
              />
            )
          })}
        </div>
      </Spin>
      {cruiseModalIsVisible && (
        <CruiseManagerModal
          visible={cruiseModalIsVisible}
          onCancel={(e) => setCruiseModalIsVisible(false)}
        />
      )}
      {departureSyncModalIsVisible && (
        <DepartureSyncModal
          visible={departureSyncModalIsVisible}
          onCreate={(year: string) => {
            setSelectedDate(year)
            startSync()
          }}
          onCancel={(e) => setDepartureSyncModalIsVisible(false)}
        />
      )}
      {createDepartureDrawerIsOpen && (
        <DepartureEditorDrawerForm
          visible={createDepartureDrawerIsOpen}
          seasonId={seasonId}
          cruises={cruisesQuery.data}
          onCreate={() => {
            queryClient.invalidateQueries('departures')
            setCreateDepartureDrawerIsOpen(false)
          }}
          onCancel={(e) => setCreateDepartureDrawerIsOpen(false)}
          onClose={(e) => setCreateDepartureDrawerIsOpen(false)}
        />
      )}
    </>
  )
}

type DepartueListLayoutProps = {
  published: React.ReactNode
  indulas: React.ReactNode
  jaratnev: React.ReactNode
  gate: React.ReactNode
  napibontas: React.ReactNode
  descriptionUpdater?: React.ReactNode
  isNotHeader?: boolean
} & HTMLAttributes<HTMLElement>

const DepartueListLayout: FC<DepartueListLayoutProps> = ({
  published,
  indulas,
  jaratnev,
  napibontas,
  gate,
  descriptionUpdater,
  isNotHeader = true,
  ...props
}) => {
  return (
    <>
      <Row {...props}>
        <Col span={9}>
          <Row justify="space-between" className={classNames({ isNotHeader: isNotHeader })}>
            <Col className={styles.publishedCol}>{published}</Col>
            <Col className={styles.indulasCol}>{indulas}</Col>
            <Col className={styles.jaratnevCol} offset={0} span={10}>
              {jaratnev}
            </Col>
            <Col className={styles.gateCol} span={5}>
              {gate}
            </Col>
          </Row>
          {descriptionUpdater && (
            <Row justify="start">
              <Col span={24} style={{ padding: '10px 5px 0 5px' }}>
                <Row gutter={5}>
                  <Col span={3}>
                    <strong>Kieg. infó:</strong>
                  </Col>
                  <Col span={19}>{descriptionUpdater}</Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
        <Col span={15}>{napibontas}</Col>
      </Row>
    </>
  )
}

type ItemProps = {
  item: WeeklyDepartureResponse
  onMutationSuccess: () => void
} & HTMLAttributes<HTMLElement>

const DepartureListItem: FC<ItemProps> = ({ item, onMutationSuccess, ...props }) => {
  const firstOfDeps = Object.values(item.departuresByDay)[0]

  return (
    <DepartueListLayout
      className={`departureListItem ${props.className}`}
      published={<PublishedDropdown item={item} onMutationSuccess={onMutationSuccess} />}
      indulas={formattedLocalTime(item.startTime)}
      jaratnev={item.cruise.displayNameMap['en']}
      gate={<GateDropdown item={item} onMutationSuccess={onMutationSuccess} />}
      napibontas={
        <DailyDepartureContainer
          onMutationSuccess={onMutationSuccess}
          departures={item.departuresByDay}
        />
      }
      descriptionUpdater={
        <DepartureDescUpdater
          initialDesc={firstOfDeps.description}
          cruiseId={firstOfDeps.cruise.id}
          startAt={firstOfDeps.startAt.toString()}
          onSuccessTextChange={() => onMutationSuccess()}
        />
      }
    />
  )
}

type DailyDepartureContainerProps = {
  departures: { [key: string]: DepartureModel }
  onMutationSuccess: () => void
}
const DailyDepartureContainer: FC<DailyDepartureContainerProps> = ({
  departures,
  onMutationSuccess,
  ...props
}) => {
  const firstDeparture = Object.values(departures)[0]

  return (
    <>
      <Row>
        <Col
          style={{
            padding: '0 2rem 0 1rem',
            borderLeft: '1px solid lightgrey',
          }}
        >
          <div style={{ fontWeight: 800 }}>Indul?</div>
          <div style={{ fontWeight: 800, marginTop: '2px' }}>Kapu?</div>
        </Col>
        {Object.keys(days).map((day) => {
          let departure = departures[day] as DepartureModel
          return (
            <Col key={day}>
              <DailyDeparture
                day={day as DepartureModelDayEnum}
                cruiseId={firstDeparture?.cruise.id}
                description={firstDeparture?.description}
                seasonId={firstDeparture?.season.id}
                startAt={firstDeparture?.startAt as string}
                onMutationSuccess={onMutationSuccess}
                item={departure}
              />
            </Col>
          )
        })}
      </Row>
    </>
  )
}

type DailyDepartureProps = {
  day: DepartureModelDayEnum
  cruiseId: number
  startAt: string
  seasonId: number
  description: string
  item?: DepartureModel
  onMutationSuccess: () => void
}

const DailyDeparture: FC<DailyDepartureProps> = ({
  item,
  day,
  cruiseId,
  startAt,
  seasonId,
  description,
  onMutationSuccess,
  ...props
}) => {
  const createDepartureMutation = useCreateDeparturesMutation(() => onMutationSuccess?.())
  const updateDepartureStatusMutation = useUpdateDeparturesMutation(() => onMutationSuccess?.())

  const updatePublishedStatus = (published?: boolean, gate?: DepartureModelGateEnum) => {
    updateDepartureStatusMutation.mutate({
      cruiseId: item.cruise.id,
      seasonId: item.season.id,
      startTime: item.startAt as string,
      dayOfWeek: item.day,
      isPublished: published,
      gate: gate,
    })
  }

  const createInstantDeparture = () => {
    createDepartureMutation.mutate({
      cruiseId: cruiseId.toString(),
      startAt: startAt,
      onDays: [day as unknown as AddDepartureRequestOnDaysEnum],
      seasonId: seasonId.toString(),
      description: description,
    })
  }

  return (
    <>
      {item?.id ? (
        <div className={styles.dailyDeparture}>
          <Checkbox
            key={item.id}
            checked={item.isPublished}
            onChange={(e: CheckboxChangeEvent) => {
              updatePublishedStatus(e.target.checked)
            }}
          >
            {days[item.day]}
          </Checkbox>
          <GateDropdown departure={item} day={day} onMutationSuccess={onMutationSuccess} />
        </div>
      ) : (
        <Tooltip title="Ezen a napon nincs ilyen járatindulás.">
          <div className={styles.dailyDepartureEmpty} onClick={(e) => createInstantDeparture()}>
            <span>{days[day]}</span>
            <PlusCircleTwoTone color="green" style={{ fontSize: '24px' }} />
          </div>
        </Tooltip>
      )}
    </>
  )
}

type PublishedDropdownProps = {
  item: WeeklyDepartureResponse
  onMutationSuccess: () => void
}

const PublishedDropdown: FC<PublishedDropdownProps> = ({ item, onMutationSuccess }) => {
  const indulIcon = <CheckCircle width={16} color="green" />
  const indeterminateIcon = <QuestionCircle width={16} color="orange" />
  const nemIndulIcon = <Ban width={16} color="lightgrey" />
  const seasonId = Object.values(item.departuresByDay)[0].season.id

  const updatePublishedStatus = (published: boolean) => {
    updateDepartureStatusMutation.mutate({
      cruiseId: item.cruise.id,
      seasonId: seasonId,
      startTime: item.startTime as string,
      isPublished: published,
    })
  }

  const publishedMenu = (
    <Menu>
      <MenuItem onClick={(e) => updatePublishedStatus(true)}>
        {indulIcon} <span style={{ paddingLeft: '6px' }}>Mind indul</span>
      </MenuItem>
      <MenuItem onClick={(e) => updatePublishedStatus(false)}>
        {nemIndulIcon} <span style={{ paddingLeft: '6px' }}>Mind kimarad</span>
      </MenuItem>
    </Menu>
  )
  const isAllPublished = item.isAllPublished?.valueOf()

  const updateDepartureStatusMutation = useUpdateDeparturesMutation(() => onMutationSuccess())

  return (
    <Dropdown.Button
      type="default"
      size="small"
      placement="bottomCenter"
      icon={<DownOutlined />}
      overlay={publishedMenu}
      trigger={['click']}
    >
      {isAllPublished == null ? indeterminateIcon : isAllPublished ? indulIcon : nemIndulIcon}
    </Dropdown.Button>
  )
}

const getGateMenu = (
  updateGateStatus?: (gate: DepartureModelGateEnum, includeUniques: boolean) => void,
) => (
  <Menu>
    {Object.keys(DepartureModelGateEnum).map((key) => {
      let gate = key as DepartureModelGateEnum
      return (
        <Popconfirm
          okType="default"
          title="Az egyedi indulásokat is módosítsa?"
          okText="Igen"
          cancelText="Nem"
          cancelButtonProps={{ type: 'primary' }}
          okButtonProps={{ type: 'text' }}
          onConfirm={(e) => {
            updateGateStatus(gate, true)
          }}
          onCancel={(e) => {
            updateGateStatus(gate, false)
          }}
          key={key}
        >
          <MenuItem key={key}>{gate}</MenuItem>
        </Popconfirm>
      )
    })}
  </Menu>
)

type GateDropdownProps = {
  item?: WeeklyDepartureResponse
  onMutationSuccess: () => void
  departure?: DepartureModel
  day?: DepartureModelDayEnum
}

const GateDropdown: FC<GateDropdownProps> = ({ item, day, departure, onMutationSuccess }) => {
  const seasonId = departure?.season.id || Object.values(item.departuresByDay)[0].season.id
  const indeterminateIcon = <QuestionCircle width={16} color="orange" />
  const gate = departure?.gate || item?.allGate?.valueOf()
  const cruiseId = departure?.cruise.id || item.cruise.id
  const startTime = (departure?.startAt || item.startTime) as string
  const updateDepartureStatusMutation = useUpdateDeparturesMutation(() => onMutationSuccess())

  const baseUpdateProps: UpdateDeparturesMutation = {
    cruiseId: cruiseId,
    seasonId: seasonId,
    startTime: startTime,
  }

  const updateGateStatus = (gate: DepartureModelGateEnum, includeUniques: boolean) => {
    let props = { ...baseUpdateProps, ...{ gate: gate } }
    props = day ? { ...props, ...{ dayOfWeek: day } } : props
    const finalProps = Object.assign({}, props, { includesUniques: includeUniques })

    updateDepartureStatusMutation.mutate(finalProps)
  }

  return (
    <Dropdown.Button
      type="default"
      size="small"
      placement="bottomCenter"
      icon={<DownOutlined />}
      overlay={getGateMenu(updateGateStatus)}
      trigger={['click']}
    >
      {gate == null ? indeterminateIcon : gate}
    </Dropdown.Button>
  )
}

export { DepartureListContainer }
