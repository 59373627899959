import { FC, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

const Scrollable = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
`

export const ScrollableContent: FC = (props) => {
  const ref = useRef<HTMLDivElement>(null)
  const location = useLocation()

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }, [location.pathname]) // location.pathname változásakor fut le

  return <Scrollable ref={ref}>{props.children}</Scrollable>
}
