import { RunnableContentDTO, ScreenModel } from '@hmedia/legenda-ds-api-client'
import { Image, Modal, Table, TableColumnType } from 'antd'
import { FC, useState } from 'react'
import { ContentMutation, useRunnableContentQuery } from './queries'

type Props = {
  visible: boolean
  screen: ScreenModel
  onCancel: (e) => void
  onClose: (e) => void
  onContentSelect: (selectedContent: ContentMutation) => void
}

export const ContentSelectorModal: FC<Props> = ({
  visible,
  onCancel,
  screen,
  onContentSelect,
  ...props
}) => {
  const runnableContentQuery = useRunnableContentQuery()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedContent, setSelectedContent] = useState<RunnableContentDTO>()

  const tableCols: TableColumnType<RunnableContentDTO>[] = [
    {
      title: '',
      dataIndex: 'thumnailURL',
      key: 'thumb',
      render: (thumnailURL: string, rc: RunnableContentDTO) => {
        return <Image height={80} src={thumnailURL} preview={false} />
      },
    },
    {
      title: 'Tartalom',
      dataIndex: 'title',
    },
    {
      title: 'Kapu?',
      dataIndex: 'gate',
    },
  ]

  const selectRow = (content: RunnableContentDTO) => {
    const key = content.contentType + content.contentId
    setSelectedRowKeys([key])
    setSelectedContent(content)
  }

  return (
    <Modal
      title={`"${screen.name}" tartalom beállítása`}
      open={visible}
      width={720}
      onOk={(e) =>
        onContentSelect({
          screenId: screen?.id,
          contentType: selectedContent.contentType,
          contentId: selectedContent.contentId?.toString() || '',
        })
      }
      okText="Kiválasztás"
      cancelText="mégsem"
      closeIcon={false}
      closable={false}
      onCancel={onCancel}
    >
      <Table
        rowSelection={{
          type: 'radio',
          selectedRowKeys,
          onSelect: (content: RunnableContentDTO) => {
            setSelectedContent(content)
            setSelectedRowKeys([content.contentType + content.contentId])
          },
          onChange: (selectedKeys) => {
            setSelectedRowKeys(selectedKeys)
          },
        }}
        onRow={(content) => ({
          onClick: (e) => {
            console.log(e.target)
            selectRow(content)
          },
        })}
        pagination={false}
        rowKey={(r) => {
          return r.contentType + r.contentId
        }}
        scroll={{ y: '60vh' }}
        columns={tableCols}
        loading={runnableContentQuery.isLoading}
        dataSource={runnableContentQuery.data}
      />
    </Modal>
  )
}
