import { RoleModel } from '@hmedia/legenda-ds-api-client'
import { Form, Modal, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { FC } from 'react'
import { useAllRolesQuery } from './queries'

export type DeleteFormValues = {
  role: string
  replacementRole: string
}

interface DeleteRoleModalProps {
  roleToDelete: RoleModel
  visible: boolean
  onCreate?: (values: DeleteFormValues) => void
  onCancel?: () => void
}

const requiredMessage = 'Kötelező megadni'

const requiredRule = { required: true, message: requiredMessage }

const DeleteRoleModal: FC<DeleteRoleModalProps> = ({
  visible,
  roleToDelete,
  onCancel,
  onCreate,
}) => {
  const roleQuery = useAllRolesQuery()
  const initialValues = { role: roleToDelete, replacementRole: null }
  const [form] = useForm()

  const getAvailableRoles = () => roleQuery.data.filter((r) => r.id !== roleToDelete.id)

  return (
    <>
      <Modal
        title={`${roleToDelete.title} jogkör törlése`}
        width={400}
        open={visible}
        okText="Törlés"
        cancelText="Mégsem"
        onOk={() => {
          form
            .validateFields()
            .then((values: DeleteFormValues) => {
              onCreate({ role: roleToDelete.name, replacementRole: values.replacementRole })
              form.resetFields()
            })
            .catch((errorInfo: ValidateErrorEntity<DeleteFormValues>) => {
              const desc = errorInfo.errorFields.map((err) => {
                return err.errors.join('/n/r')
              })
            })
        }}
        onCancel={(_evt): void => {
          form.resetFields()
          onCancel()
        }}
      >
        <p>
          Válaszd ki, hogy melyik jogkörbe kerüljenek a(z) "{roleToDelete.title}" jogkör
          felhasználói.
        </p>
        <Form layout="inline" form={form} initialValues={initialValues} autoComplete="off">
          <Form.Item label="Az új jogkör" name="replacementRole" rules={[requiredRule]}>
            <Select placeholder="Válasszon szolgáltatást" style={{ minWidth: '200px' }}>
              {getAvailableRoles().map((role) => {
                return <Select.Option key={role.name}>{role.title}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default DeleteRoleModal
