import {
  type AddDepartureRequestGateEnum,
  type AddDepartureRequestOnDaysEnum,
  type Cruise,
  type DepartureModelDayEnum,
  DepartureModelGateEnum,
  LocalTime,
} from '@hmedia/legenda-ds-api-client'
import { TimePicker } from '@hmedia/legenda-ds-react'
import {
  Button,
  Checkbox,
  type CheckboxOptionType,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from 'antd'
import huHU from 'antd/es/date-picker/locale/hu_HU'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import type { Dayjs } from 'dayjs'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import type { FC } from 'react'
import { daysShort } from '../../utils/helpers'
import { useCreateDeparturesMutation } from './queries'
import { GateSelector } from 'components/GateSelector/GateSelector'

export type FormValues = {
  days: DepartureModelDayEnum[]
  cruiseId: string
  gate: DepartureModelGateEnum
  startAt: Dayjs
  description?: string
}

interface DepartureEditorFormProps {
  seasonId: number
  cruises: Cruise[]
  visible: boolean
  onCreate?: () => void
  onCancel?: (e) => void
  onClose?: (e) => void
}

const requiredMessage = 'Kötelező megadni'

const requiredRule = { required: true, message: requiredMessage }

const DepartureEditorDrawerForm: FC<DepartureEditorFormProps> = ({
  visible,
  cruises,
  seasonId,
  onCancel,
  onCreate,
  onClose,
}) => {
  const initialValues: Partial<FormValues> = {}
  const departureMutation = useCreateDeparturesMutation(() => onCreate())
  const [form] = useForm<FormValues>()

  const formButtons = (
    <Row justify="end">
      <Col>
        <Space>
          <Button
            onClick={(_evt): void => {
              form.resetFields()
              onCancel(_evt)
            }}
          >
            Mégsem
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  departureMutation.mutate({
                    cruiseId: values.cruiseId,
                    seasonId: seasonId.toString(),
                    onDays: values.days as unknown as AddDepartureRequestOnDaysEnum[],
                    startAt: values.startAt.format('HH:mm'),
                    gate: values.gate as unknown as AddDepartureRequestGateEnum,
                    description: values.description,
                  })
                  form.resetFields()
                  onCreate()
                })
                .catch((errorInfo) => {
                  console.log(errorInfo)
                  // const desc = errorInfo.message || errorInfo.errorFields.map((err) => {
                  //     return err.errors.join('/n/r')
                  // })
                })
            }}
          >
            Beküldés
          </Button>
        </Space>
      </Col>
    </Row>
  )

  const getDaysOptions = (): CheckboxOptionType[] => {
    return Object.entries(daysShort).map(([value, label]) => {
      return { label: label, value: value }
    })
  }
  return (
    <>
      <Spin spinning={departureMutation.isLoading}>
        <Drawer
          title={'Új indulás hozzáadása'}
          placement={'right'}
          width={'500px'}
          open={visible}
          size="large"
          onClose={onClose}
        >
          <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            autoComplete="off"
            onChange={(e) => {
              e.target.toString()
            }}
          >
            <Form.Item label={'Indulási napok'} name="days" rules={[requiredRule]}>
              <Checkbox.Group options={getDaysOptions()}></Checkbox.Group>
            </Form.Item>
            <Space direction="horizontal" size={'small'}>
              <Form.Item label="Szolgáltatás" name="cruiseId" rules={[requiredRule]}>
                <Select placeholder="Válasszon szolgáltatást" style={{ minWidth: '200px' }}>
                  {cruises.map((cr) => (
                    <Select.Option key={cr.id}>{cr.displayNameMap['en']}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Kapu" name="gate" rules={[requiredRule]}>
                <GateSelector />
              </Form.Item>
            </Space>
            <Form.Item label="Indulás ideje" name="startAt" rules={[requiredRule]}>
              <TimePicker allowClear={true} format="HH:mm" locale={huHU} />
            </Form.Item>

            <Form.Item label="Kiegészítő információ" name="description" rules={[]}>
              <TextArea rows={3} />
            </Form.Item>
          </Form>

          <Divider />
          {formButtons}
        </Drawer>
      </Spin>
    </>
  )
}

export default DepartureEditorDrawerForm
