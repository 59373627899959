import Icon, {
  DesktopOutlined,
  EllipsisOutlined,
  NotificationOutlined,
  StarOutlined,
} from '@ant-design/icons'
import { RunningContentContentTypeEnum, ScreenModel } from '@hmedia/legenda-ds-api-client'
import { Badge, Button, Dropdown, Image, Space, Typography } from 'antd'
import { FC } from 'react'
import styles from './css.module.css'
import { useWakeUpScreenByMACMutation } from './queries'
const { Title, Text } = Typography

type ListProps = {
  items: ScreenModel[]
  onNameChange?: (id: string, name: string) => void
  onMenuClick?: (screen: ScreenModel) => void
}

const ScreenItemList: FC<ListProps> = (props) => {
  let { items, onNameChange, onMenuClick } = props

  return (
    <>
      {items.map((item) => {
        return (
          <ScreenItem
            onMenuClick={onMenuClick}
            key={item.id}
            screen={item}
            onNameChange={onNameChange}
          />
        )
      })}
    </>
  )
}

type ItemProps = {
  screen: ScreenModel
  onNameChange?: (id: string, name: string) => void
  onMenuClick?: (screen: ScreenModel) => void
}

const ScreenItem: FC<ItemProps> = (props) => {
  const { screen, onNameChange, onMenuClick } = props
  const wakeUpMutation = useWakeUpScreenByMACMutation()

  let filtered = Object.keys(screen.runningContent).filter((key) => key !== 'contentType')
  const contentTitle =
    (screen.runningContent.contentType === RunningContentContentTypeEnum.MEDIAFILE &&
      screen.runningContent?.mediaFileInstance?.title) ||
    filtered
      .map((sc) => screen.runningContent[sc]?.['meta']?.['title'])
      .filter((sc) => sc !== undefined)[0]

  const thumbnailUrl =
    (screen.runningContent.contentType === RunningContentContentTypeEnum.MEDIAFILE &&
      screen.runningContent?.mediaFileInstance?.thumbnailURL) ||
    Object.keys(screen.runningContent)
      .map((key) => screen.runningContent[key]?.['meta']?.['thumnailURL'])
      .filter((sc) => sc !== undefined)[0]

  const runningActuality = screen.actuality?.contentMap?.[screen.contentType]

  const menuItems = [
    {
      key: 'setcontent',
      onClick: () => {
        onMenuClick(screen)
      },
      label: 'Tartalom beállítása',
    },
    /* {
            key: "wakeup",
            icon: <PoweroffOutlined />,
            onClick: () => wakeUpMutation.mutate({ mac: screen.device?.id }),
            label: "Kijelző bekapcsolása"
        } */
  ]

  return (
    <div className={styles.screenItem}>
      {(thumbnailUrl && <Image src={thumbnailUrl} height={60} />) || (
        <DesktopOutlined
          style={{ fontSize: '24px' }}
          className={screen.connected ? styles.statusColorOnline : styles.statusColorOffline}
        />
      )}
      <Space direction="vertical" size={0} style={{ minWidth: '350px' }}>
        <Title
          level={4}
          editable={{
            onChange: (name: string) => onNameChange && onNameChange(screen.id, name),
            tooltip: false,
          }}
        >
          {screen.name}
        </Title>
        <span>Lejátszás alatt: {contentTitle || '-'}</span>
      </Space>
      <Space direction="vertical" size={'small'}>
        {screen.info && (
          <Space direction="horizontal" className={styles.infoContent}>
            <Icon component={NotificationOutlined} />
            Futó infó:
            <Text>{screen.info?.name}</Text>
          </Space>
        )}
        {runningActuality && (
          <Space direction="horizontal" className={styles.infoContent}>
            <Icon component={StarOutlined} />
            Futó aktualitás:
            <Text>{screen.actuality?.name}</Text>
          </Space>
        )}
      </Space>
      <Space className={styles.macModelNumber}>
        MAC cím:
        <Text copyable={true}>{screen.device?.id}</Text>
      </Space>
      <Badge status={screen.connected ? 'success' : 'error'} text={screen.status} />
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        <Button icon={<EllipsisOutlined />} shape="circle" />
      </Dropdown>
    </div>
  )
}
export default ScreenItemList
