import type { Cruise } from '@hmedia/legenda-ds-api-client'
import { Alert, Button, Col, Divider, Drawer, Form, Input, Radio, Row, Space, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import type { FC } from 'react'
import { useCreateUpdateCruiseMutation, useInternalCruisesNotMappedQuery } from './queries'

export type FormValues = {
  name?: string | undefined
  description?: string | undefined
  internalId?: string | undefined
  internalName?: string | undefined
}

interface CruiseEditorFormProps {
  cruise?: Cruise
  visible: boolean
  onCreate?: (cruise: Cruise) => void
  onCancel?: (e) => void
  onClose?: (e) => void
}

const requiredMessage = 'Kötelező megadni'

const requiredRule = { required: true, message: requiredMessage }

const CruiseEditorDrawerForm: FC<CruiseEditorFormProps> = ({
  visible,
  cruise,
  onCancel,
  onCreate,
  onClose,
}) => {
  const initialValues: FormValues = cruise
    ? {
        name: cruise.displayNameMap['en'],
        description: cruise.description,
        internalId: cruise.internalId,
        internalName: cruise.internalName,
      }
    : {}
  const notMappedCruisesQuery = useInternalCruisesNotMappedQuery()
  const cruiseMutation = useCreateUpdateCruiseMutation((cruise) => onCreate(cruise))
  const [form] = useForm<FormValues>()

  const formButtons = (
    <Row justify="end">
      <Col>
        <Space>
          <Button
            onClick={(_evt): void => {
              form.resetFields()
              onCancel(_evt)
            }}
          >
            Mégsem
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  cruiseMutation.mutate(
                    {
                      name: values.name,
                      description: values.description,
                      internalId: values.internalId,
                      internalName: notMappedCruisesQuery.data?.[values.internalId],
                      id: cruise?.id.toString(),
                    },
                    { onSuccess: () => form.resetFields() },
                  )
                })
                .catch((errorInfo: ValidateErrorEntity<FormValues>) => {
                  const desc = errorInfo.errorFields.map((err) => {
                    return err.errors.join('/n/r')
                  })
                })
            }}
          >
            Beküldés
          </Button>
        </Space>
      </Col>
    </Row>
  )
  return (
    <>
      <Spin spinning={cruiseMutation.isLoading}>
        <Drawer
          title={cruise ? `${initialValues.name} szerkesztése` : 'Szolgáltatás hozzáadása'}
          placement={'right'}
          width={'500px'}
          open={visible}
          size="large"
          // extra={formButtons}
          onClose={onClose}
        >
          <Form layout="vertical" form={form} initialValues={initialValues} autoComplete="off">
            <Form.Item label="Kijelzőkön megjelenő elnevezés" name="name" rules={[requiredRule]}>
              <Input />
            </Form.Item>
            <Form.Item label="Kiegészítő információ" name="description" rules={[]}>
              <TextArea rows={5} />
            </Form.Item>
            {!initialValues.internalId && (
              <Form.Item
                label="Összekapcsolás a belső rendszerrel"

                // rules={[requiredRule]}
              >
                <Form.Item name="internalId">
                  <Radio.Group
                    onChange={(e) => {
                      return e.target.value
                    }}
                  >
                    {!!Object.entries(notMappedCruisesQuery.data || {}).length && (
                      <Space direction="vertical">
                        <Radio value={''} key={''}>
                          nincs összekötés
                        </Radio>

                        {Object.entries(notMappedCruisesQuery.data || {}).map((entry) => (
                          <Radio value={entry[0]} key={entry[0]}>
                            {entry[1]} (ID: {entry[0]})
                          </Radio>
                        ))}
                      </Space>
                    )}
                  </Radio.Group>
                </Form.Item>
                {!Object.entries(notMappedCruisesQuery.data || {}).length && (
                  <Alert
                    description="Nincs szolgáltatás, amivel össze lehetne kapcsolni. A belső rendszerben egy szolgáltatásnak legalább egy járattal rendelkeznie kell a következő egy évben, hogy itt megjelenjen."
                    type="info"
                    showIcon
                  />
                )}
              </Form.Item>
            )}
          </Form>
          {initialValues.internalId && (
            <Alert
              type={'success'}
              message={'Szolgáltatások összerendelve'}
              description={
                <span>
                  <strong>{initialValues.internalName} </strong>({initialValues.internalId})
                </span>
              }
              showIcon={true}
            />
          )}
          <Divider />
          {formButtons}
        </Drawer>
      </Spin>
    </>
  )
}

export default CruiseEditorDrawerForm
