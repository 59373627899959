import { UserDto, RoleModel } from '@hmedia/legenda-ds-api-client'
import { find } from 'lodash'

export type Role = 'ROLE_ADMIN' | 'ROLE_USER'

export enum RoleEnum {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
}

export const RoleEnumConst = {
  ROLE_ADMIN: { value: 'ROLE_ADMIN', title: 'Adminisztrátor' },
  ROLE_USER: { value: 'ROLE_USER', title: 'Felhasználó' },
} as const
export type RoleEnumConst = keyof typeof RoleEnumConst

export type Privilege =
  | 'MENU_ACCESS__SCREENS'
  | 'MENU_ACCESS__TIMETABLE'
  | 'MENU_ACCESS__DEPARTURES'
  | 'MENU_ACCESS__PRICES'
  | 'MENU_ACCESS__INFO'
  | 'MENU_ACCESS__ACTUALITY'
  | 'MENU_ACCESS__USERS'
  | 'MENU_ACCESS__PERMISSIONS'
  | 'MENU_ACCESS__SETTINGS'
  | 'MENU_ACCESS__EXCHANGE'

export const getRoleName = (role: RoleEnum): String => {
  switch (role) {
    case RoleEnum.ROLE_ADMIN:
      return 'Adminisztrátor'
    case RoleEnum.ROLE_USER:
      return 'Felhasználó'
    default:
      return role
  }
}

export const getUserRole = (user: UserDto, roles: RoleModel[]): RoleModel | undefined => {
  let userRole = find(Array.from(user.privileges), (priv) => priv.startsWith('ROLE_', 0))

  return find(roles, (item) => item.name === userRole)
}
