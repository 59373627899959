import generateCalendar from 'antd/es/calendar/generateCalendar'
import generatePicker from 'antd/es/date-picker/generatePicker'
import { PickerTimeProps } from 'antd/es/time-picker'
import dayjs, { type Dayjs } from 'dayjs'
import hu from 'dayjs/locale/hu'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import weekday from 'dayjs/plugin/weekday'
import dayjsGenerateConfig from 'rc-picker/es/generate/dayjs'
import React from 'react'

dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(weekday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekYear)
dayjs.extend(weekOfYear)

dayjs.locale(hu)
dayjs.tz.setDefault('Europe/Budapest')

/**
 * DatePicker
 */
export const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

/**
 * Calendar
 */
export const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig)

/**
 * TimePicker
 */
export interface TimePickerProps
  extends Omit<PickerTimeProps<Dayjs>, 'picker'> {}

export const TimePicker = React.forwardRef<any, TimePickerProps>(
  (props, ref) => {
    return <DatePicker {...props} picker='time' mode={undefined} ref={ref} />
  }
)

TimePicker.displayName = 'TimePicker'
