import { Popconfirm, Typography } from 'antd'
import { FC, ReactNode, useState } from 'react'
import { useDescriptionsMutation, useUniqueDescriptionsMutation } from './queries'
const { Paragraph } = Typography

type Props = {
  departureId?: number
  cruiseId?: number
  startAt?: string
  startDate?: string
  initialDesc?: string
  emptyLabel?: string | ReactNode
  onSuccessTextChange: () => void
}
export const DepartureDescUpdater: FC<Props> = ({
  departureId,
  initialDesc,
  cruiseId,
  startAt,
  startDate,
  emptyLabel = 'nincs megadva',
  onSuccessTextChange,
  ...props
}) => {
  const uniqueDescMutation = useUniqueDescriptionsMutation(() => onSuccessTextChange())
  const descMutation = useDescriptionsMutation(() => onSuccessTextChange())
  const [textValue, setTextValue] = useState(initialDesc)
  const [popupVisible, setPopupVisible] = useState(false)

  const onChange = (value: string, includeUniques: boolean) => {
    if (departureId !== undefined) {
      uniqueDescMutation.mutate({ departureId: departureId, text: value, startDate: startDate })
    } else {
      descMutation.mutate({
        cruiseId: cruiseId,
        includesUnique: includeUniques,
        startAt: startAt,
        text: value,
      })
    }
    setPopupVisible(false)
  }

  return (
    <Popconfirm
      okType="default"
      title="Az egyedi indulások kiegészítő információit is módosítsa?"
      okText="Igen"
      visible={popupVisible}
      cancelText="Nem"
      cancelButtonProps={{ type: 'primary' }}
      okButtonProps={{ type: 'text' }}
      onConfirm={(e) => {
        onChange(textValue, true)
      }}
      onCancel={(e) => {
        onChange(textValue, false)
      }}
    >
      <Paragraph
        editable={{
          onChange: (text: string) => {
            if (textValue !== text) {
              setTextValue(text)
              if (departureId !== undefined) {
                onChange(text, false)
              } else setPopupVisible(true)
            }
          },
          tooltip: false,
        }}
        {...props}
      >
        {initialDesc || <span style={{ color: 'lightslategrey' }}>{emptyLabel}</span>}
      </Paragraph>
    </Popconfirm>
  )
}
