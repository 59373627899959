import { CloseOutlined } from '@ant-design/icons'
import {
  InformModel,
  ScreenModel,
  ScreenModelContentTypeEnum,
  ScreenModelStatusEnum,
} from '@hmedia/legenda-ds-api-client'
import { Modal, Switch, Table, TableColumnType } from 'antd'
import { FC } from 'react'
import { useQueryClient } from 'react-query'
import { useScreensQuery } from '../screens/queries'
import { switchChildrens } from '../uniqueDepartures/UniqueDeparturesView'
import { getInformTypeName, InformDTO } from './models'
import { useSendInfoToScreenMutation } from './queries'

type Props = {
  visible: boolean
  inform?: InformDTO
  onClose: (e) => void
}

export const ScreenSelectorModal: FC<Props> = ({ visible, onClose, inform, ...props }) => {
  const screenQuery = useScreensQuery()
  const sendInfoToScreenMutation = useSendInfoToScreenMutation(() => screenQuery.refetch())

  const onChangeOne = (screenId: string, isDisplaying: boolean) => {
    sendInfoToScreenMutation.mutate({
      informId: inform?.id?.toString(),
      screenIds: [screenId],
      toDisplay: isDisplaying,
    })
  }

  const tableCols: TableColumnType<ScreenModel>[] = [
    {
      title: 'Kijelző neve',
      dataIndex: 'name',
    },
    {
      title: 'megjelenítve?',
      dataIndex: 'info',
      align: 'right',
      width: '130px',
      render: (info: InformModel, screen, idx) => {
        const isDisplaying = info !== null && info?.id === inform?.id?.toString()
        return (
          <Switch
            {...switchChildrens}
            loading={screenQuery.isLoading}
            checked={isDisplaying}
            onChange={(value) => {
              onChangeOne(screen?.id, value)
            }}
          />
        )
      },
    },
  ]

  const informTypeName = getInformTypeName(inform?.type)
  return (
    <Modal
      title={`${informTypeName} megjelenítése az alábbi kijelző(k)ön`}
      open={visible}
      width={460}
      closeIcon={<CloseOutlined />}
      closable={true}
      onCancel={onClose}
      footer={null}
      maskClosable={true}
    >
      <p>
        Kiválasztott {informTypeName?.toLowerCase()}: <strong>"{inform?.name}"</strong>
      </p>
      <Table
        pagination={false}
        rowKey={(scr) => {
          return scr.id
        }}
        scroll={{ y: '60vh' }}
        columns={tableCols}
        size={'small'}
        loading={screenQuery.isLoading}
        dataSource={screenQuery.data}
      />
    </Modal>
  )
}
