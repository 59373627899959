import { PlusOutlined } from '@ant-design/icons'
import { Cruise, MediaFileDTO, MediaFileDTOFileTypeEnum } from '@hmedia/legenda-ds-api-client'
import { Button, Col, Drawer, Form, Input, Row, Space, Spin, Upload } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { UploadFile } from 'antd/es/upload/interface'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import API from '../../services/DSApiService'
import {
  useCreateMediaFileMutation,
  useUpdateMediaFileMutation,
  useUploadFileMutation,
} from './queries'

export type FormValues = {
  title?: string
  fileName?: string
}

interface MediaFileEditorFormProps {
  mediafile?: MediaFileDTO
  visible: boolean
  onCreate?: (mediafile: MediaFileDTO) => void
  onCancel?: (e) => void
  onClose?: (e) => void
}

const requiredMessage = 'Kötelező megadni'

const requiredRule = { required: true, message: requiredMessage }

const MediaFileEditorDrawerForm: FC<MediaFileEditorFormProps> = ({
  visible,
  mediafile,
  onCancel,
  onCreate,
  onClose,
}) => {
  const initialValues: FormValues = mediafile
    ? {
        title: mediafile.title,
        fileName: mediafile.filename,
      }
    : {}

  const createMediafileMutation = useCreateMediaFileMutation((mfile) => {
    return onCreate(mfile)
  })
  const updateMediafileMutation = useUpdateMediaFileMutation((mfile) => onCreate(mfile))

  const [form] = useForm<FormValues>()
  const [fileList, setfileList] = useState<UploadFile>()

  const uploadFileMutation = useUploadFileMutation()

  const handleChange = (info: { file: UploadFile; fileList: UploadFile[]; event: any }) => {
    if (info.file.status === 'uploading') {
      return
    }
    if (info.file.status === 'done') {
      toast.info(info.file)
      setfileList(info.file)
      return info.file
    }
  }
  const formButtons = (
    <Row justify="end">
      <Col>
        <Space>
          <Button
            onClick={(_evt): void => {
              form.resetFields()
              onCancel(_evt)
            }}
          >
            Mégsem
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  createMediafileMutation.mutate({
                    title: values.title,
                    filename: fileList.response?.fileName,
                  })
                  form.resetFields()
                  onCreate({
                    title: values.title,
                    filename: fileList.response?.fileName,
                    fileType: MediaFileDTOFileTypeEnum.IMAGE,
                  })
                })
                .catch((errorInfo: ValidateErrorEntity<FormValues>) => {
                  const desc = errorInfo.errorFields.map((err) => {
                    return err.errors.join('/n/r')
                  })
                })
            }}
          >
            Beküldés
          </Button>
        </Space>
      </Col>
    </Row>
  )
  return (
    <>
      <Spin spinning={createMediafileMutation.isLoading || updateMediafileMutation.isLoading}>
        <Drawer
          title={mediafile ? `Médiafájl szerkesztése` : 'Médiafájl hozzáadása'}
          placement={'right'}
          width={'500px'}
          open={visible}
          size="large"
          extra={formButtons}
          onClose={onClose}
        >
          <Form layout="vertical" form={form} initialValues={initialValues} autoComplete="off">
            <Form.Item label="Megnevezés" name="title" rules={[requiredRule]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Médiafájl feltöltése"
              name="image"
              rules={[requiredRule]}
              getValueFromEvent={handleChange}
              valuePropName="data"
            >
              <Upload
                action={`${API.baseURL}/api/v1.0/mediafiles/upload`}
                listType="picture-card"
                onChange={handleChange}
                showUploadList={true}
                accept="image/*,video/*"
                maxCount={1}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Feltöltés</div>
                </div>
              </Upload>
            </Form.Item>
          </Form>
          {formButtons}
        </Drawer>
      </Spin>
    </>
  )
}

export default MediaFileEditorDrawerForm
