import axios from 'axios'

const httpClient = axios.create({
  baseURL: `/api`,
  timeout: 3000,
  headers: {
    get: { Accept: 'application/json' },
    post: { Accept: 'application/json' },
  },
})

export { httpClient }
