/* tslint:disable */
/* eslint-disable */
/**
 * Legenda DS API
 * Legenda Digital Signage API server
 *
 * The version of the OpenAPI document: latest-SNAPSHOT
 * Contact: developer@hobbesmedia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AddDepartureRequest
 */
export interface AddDepartureRequest {
  /**
   *
   * @type {string}
   * @memberof AddDepartureRequest
   */
  seasonId: string
  /**
   *
   * @type {Array<string>}
   * @memberof AddDepartureRequest
   */
  onDays: Array<AddDepartureRequestOnDaysEnum>
  /**
   *
   * @type {string}
   * @memberof AddDepartureRequest
   */
  cruiseId: string
  /**
   *
   * @type {string}
   * @memberof AddDepartureRequest
   */
  startAt: string
  /**
   *
   * @type {string}
   * @memberof AddDepartureRequest
   */
  gate?: AddDepartureRequestGateEnum
  /**
   *
   * @type {string}
   * @memberof AddDepartureRequest
   */
  description: string
}

/**
 * @export
 * @enum {string}
 */
export enum AddDepartureRequestOnDaysEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}
/**
 * @export
 * @enum {string}
 */
export enum AddDepartureRequestGateEnum {
  GATE1 = 'GATE1',
  GATE2 = 'GATE2',
  GATE3 = 'GATE3',
}

/**
 *
 * @export
 * @interface AddUniqueDepartureRequest
 */
export interface AddUniqueDepartureRequest {
  /**
   *
   * @type {string}
   * @memberof AddUniqueDepartureRequest
   */
  date: string
  /**
   *
   * @type {string}
   * @memberof AddUniqueDepartureRequest
   */
  cruiseId: string
  /**
   *
   * @type {string}
   * @memberof AddUniqueDepartureRequest
   */
  startAt: string
  /**
   *
   * @type {string}
   * @memberof AddUniqueDepartureRequest
   */
  gate?: AddUniqueDepartureRequestGateEnum
  /**
   *
   * @type {string}
   * @memberof AddUniqueDepartureRequest
   */
  description: string
}

/**
 * @export
 * @enum {string}
 */
export enum AddUniqueDepartureRequestGateEnum {
  GATE1 = 'GATE1',
  GATE2 = 'GATE2',
  GATE3 = 'GATE3',
}

/**
 *
 * @export
 * @interface ApiError
 */
export interface ApiError {
  /**
   *
   * @type {number}
   * @memberof ApiError
   */
  httpStatus: number
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  errorCode: string
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  errorMessage?: string
  /**
   *
   * @type {Array<object>}
   * @memberof ApiError
   */
  subErrors?: Array<object>
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  timestamp: string
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  debugMessage?: string
}
/**
 *
 * @export
 * @interface AppSettingDTO
 */
export interface AppSettingDTO {
  /**
   *
   * @type {string}
   * @memberof AppSettingDTO
   */
  id: AppSettingDTOIdEnum
  /**
   *
   * @type {string}
   * @memberof AppSettingDTO
   */
  value: string
}

/**
 * @export
 * @enum {string}
 */
export enum AppSettingDTOIdEnum {
  EURO_EXCHANGE_RATE = 'EURO_EXCHANGE_RATE',
  TICKETPRICE_TAGS = 'TICKETPRICE_TAGS',
  EXTERNAL_EXCHANGE_USE_FETCH = 'EXTERNAL_EXCHANGE_USE_FETCH',
  EXTERNAL_EXCHANGE_RATE_FETCH_DELAY_IN_SECONDS = 'EXTERNAL_EXCHANGE_RATE_FETCH_DELAY_IN_SECONDS',
}

/**
 *
 * @export
 * @interface AppSettingUpdateRequest
 */
export interface AppSettingUpdateRequest {
  /**
   *
   * @type {Array<AppSettingDTO>}
   * @memberof AppSettingUpdateRequest
   */
  settings: Array<AppSettingDTO>
}
/**
 *
 * @export
 * @interface AuthorityModel
 */
export interface AuthorityModel {
  /**
   *
   * @type {string}
   * @memberof AuthorityModel
   */
  privilege: string
  /**
   *
   * @type {string}
   * @memberof AuthorityModel
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof AuthorityModel
   */
  id: number
}
/**
 *
 * @export
 * @interface Bar
 */
export interface Bar {
  /**
   *
   * @type {Meta}
   * @memberof Bar
   */
  meta: Meta
  /**
   *
   * @type {{ [key: string]: DepartureModel; }}
   * @memberof Bar
   */
  nextDepartures: { [key: string]: DepartureModel }
}
/**
 *
 * @export
 * @interface BeszalloHid
 */
export interface BeszalloHid {
  /**
   *
   * @type {Meta}
   * @memberof BeszalloHid
   */
  meta: Meta
  /**
   *
   * @type {{ [key: string]: DepartureModel; }}
   * @memberof BeszalloHid
   */
  nextDepartures: { [key: string]: DepartureModel }
}
/**
 *
 * @export
 * @interface BeszalloHid1V2
 */
export interface BeszalloHid1V2 {
  /**
   *
   * @type {Meta}
   * @memberof BeszalloHid1V2
   */
  meta: Meta
  /**
   *
   * @type {Array<DepartureModel>}
   * @memberof BeszalloHid1V2
   */
  nextDepartures: Array<DepartureModel>
}
/**
 *
 * @export
 * @interface CreateRoleRequest
 */
export interface CreateRoleRequest {
  /**
   *
   * @type {string}
   * @memberof CreateRoleRequest
   */
  title: string
}
/**
 *
 * @export
 * @interface CreateSeasonRequest
 */
export interface CreateSeasonRequest {
  /**
   *
   * @type {string}
   * @memberof CreateSeasonRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateSeasonRequest
   */
  startDate: string
  /**
   *
   * @type {string}
   * @memberof CreateSeasonRequest
   */
  endDate: string
}
/**
 *
 * @export
 * @interface CreateUpdateCruiseRequest
 */
export interface CreateUpdateCruiseRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUpdateCruiseRequest
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CreateUpdateCruiseRequest
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof CreateUpdateCruiseRequest
   */
  internalName?: string
  /**
   *
   * @type {string}
   * @memberof CreateUpdateCruiseRequest
   */
  internalId?: string
  /**
   *
   * @type {string}
   * @memberof CreateUpdateCruiseRequest
   */
  id?: string
}
/**
 *
 * @export
 * @interface CreateUpdateInformRequest
 */
export interface CreateUpdateInformRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUpdateInformRequest
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof CreateUpdateInformRequest
   */
  contentJSON: string
  /**
   *
   * @type {string}
   * @memberof CreateUpdateInformRequest
   */
  contentHTML: string
  /**
   *
   * @type {boolean}
   * @memberof CreateUpdateInformRequest
   */
  isTodayShown: boolean
  /**
   *
   * @type {string}
   * @memberof CreateUpdateInformRequest
   */
  type: CreateUpdateInformRequestTypeEnum
  /**
   *
   * @type {string}
   * @memberof CreateUpdateInformRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateUpdateInformRequest
   */
  id?: string
}

/**
 * @export
 * @enum {string}
 */
export enum CreateUpdateInformRequestTypeEnum {
  INFO = 'INFO',
  ACTUALITY = 'ACTUALITY',
}

/**
 *
 * @export
 * @interface CreateUpdateMediaFileRequest
 */
export interface CreateUpdateMediaFileRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUpdateMediaFileRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateUpdateMediaFileRequest
   */
  filename: string
}
/**
 *
 * @export
 * @interface Cruise
 */
export interface Cruise {
  /**
   *
   * @type {number}
   * @memberof Cruise
   */
  id: number
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Cruise
   */
  displayNameMap: { [key: string]: string }
  /**
   *
   * @type {string}
   * @memberof Cruise
   */
  description: string
  /**
   *
   * @type {string}
   * @memberof Cruise
   */
  internalId?: string
  /**
   *
   * @type {string}
   * @memberof Cruise
   */
  internalName?: string
}
/**
 *
 * @export
 * @interface DeleteInformRequest
 */
export interface DeleteInformRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteInformRequest
   */
  id: string
}
/**
 *
 * @export
 * @interface DeleteRoleRequest
 */
export interface DeleteRoleRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteRoleRequest
   */
  roleName: string
  /**
   *
   * @type {string}
   * @memberof DeleteRoleRequest
   */
  replacementRole: string
}
/**
 *
 * @export
 * @interface DepartureModel
 */
export interface DepartureModel {
  /**
   *
   * @type {string}
   * @memberof DepartureModel
   */
  day: DepartureModelDayEnum
  /**
   *
   * @type {Cruise}
   * @memberof DepartureModel
   */
  cruise: Cruise
  /**
   *
   * @type {SeasonModel}
   * @memberof DepartureModel
   */
  season: SeasonModel
  /**
   *
   * @type {LocalTime}
   * @memberof DepartureModel
   */
  startAt: LocalTime
  /**
   *
   * @type {string}
   * @memberof DepartureModel
   */
  gate?: DepartureModelGateEnum
  /**
   *
   * @type {string}
   * @memberof DepartureModel
   */
  description: string
  /**
   *
   * @type {boolean}
   * @memberof DepartureModel
   */
  isPublished: boolean
  /**
   *
   * @type {number}
   * @memberof DepartureModel
   */
  id: number
  /**
   *
   * @type {boolean}
   * @memberof DepartureModel
   */
  isFull: boolean
  /**
   *
   * @type {boolean}
   * @memberof DepartureModel
   */
  isCancelled: boolean
  /**
   *
   * @type {string}
   * @memberof DepartureModel
   */
  startDate?: string
  /**
   *
   * @type {boolean}
   * @memberof DepartureModel
   */
  isKifutott: boolean
  /**
   *
   * @type {boolean}
   * @memberof DepartureModel
   */
  isSoronKovetkezo: boolean
  /**
   *
   * @type {boolean}
   * @memberof DepartureModel
   */
  unique: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum DepartureModelDayEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}
/**
 * @export
 * @enum {string}
 */
export enum DepartureModelGateEnum {
  GATE1 = 'GATE1',
  GATE2 = 'GATE2',
  GATE3 = 'GATE3',
}

/**
 *
 * @export
 * @interface DeparturesByDateRequest
 */
export interface DeparturesByDateRequest {
  /**
   *
   * @type {string}
   * @memberof DeparturesByDateRequest
   */
  date: string
}
/**
 *
 * @export
 * @interface DeviceModel
 */
export interface DeviceModel {
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  modelName?: string
  /**
   *
   * @type {number}
   * @memberof DeviceModel
   */
  width: number
  /**
   *
   * @type {number}
   * @memberof DeviceModel
   */
  height: number
  /**
   *
   * @type {number}
   * @memberof DeviceModel
   */
  volume: number
  /**
   *
   * @type {boolean}
   * @memberof DeviceModel
   */
  isMuted: boolean
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  ipAddress: string
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  connection: DeviceModelConnectionEnum
}

/**
 * @export
 * @enum {string}
 */
export enum DeviceModelConnectionEnum {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

/**
 *
 * @export
 * @interface DeviceRegistrationRequest
 */
export interface DeviceRegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof DeviceRegistrationRequest
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof DeviceRegistrationRequest
   */
  width: number
  /**
   *
   * @type {number}
   * @memberof DeviceRegistrationRequest
   */
  height: number
  /**
   *
   * @type {string}
   * @memberof DeviceRegistrationRequest
   */
  ipAddress: string
  /**
   *
   * @type {string}
   * @memberof DeviceRegistrationRequest
   */
  modelName: string
}
/**
 *
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   *
   * @type {number}
   * @memberof ErrorMessage
   */
  statusCode: number
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  timestamp: string
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  message: string
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  description: string
}
/**
 *
 * @export
 * @interface ExchangeSettingsDTOResponse
 */
export interface ExchangeSettingsDTOResponse {
  /**
   *
   * @type {string}
   * @memberof ExchangeSettingsDTOResponse
   */
  exchangeRate?: string
  /**
   *
   * @type {boolean}
   * @memberof ExchangeSettingsDTOResponse
   */
  useExternalFetch?: boolean
  /**
   *
   * @type {string}
   * @memberof ExchangeSettingsDTOResponse
   */
  externalExchangeRate?: string
  /**
   *
   * @type {number}
   * @memberof ExchangeSettingsDTOResponse
   */
  externalFetchDelay?: number
}
/**
 *
 * @export
 * @interface FelsoTerasz
 */
export interface FelsoTerasz {
  /**
   *
   * @type {Meta}
   * @memberof FelsoTerasz
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof FelsoTerasz
   */
  weatherTemp?: string
  /**
   *
   * @type {string}
   * @memberof FelsoTerasz
   */
  weatherIcon?: string
  /**
   *
   * @type {Array<DepartureModel>}
   * @memberof FelsoTerasz
   */
  nextDepartures: Array<DepartureModel>
  /**
   *
   * @type {string}
   * @memberof FelsoTerasz
   */
  serverDateTime?: string
}
/**
 *
 * @export
 * @interface GrantRoleRequest
 */
export interface GrantRoleRequest {
  /**
   *
   * @type {string}
   * @memberof GrantRoleRequest
   */
  username: string
  /**
   *
   * @type {Set<string>}
   * @memberof GrantRoleRequest
   */
  roles: Set<string>
}
/**
 *
 * @export
 * @interface InformModel
 */
export interface InformModel {
  /**
   *
   * @type {string}
   * @memberof InformModel
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof InformModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof InformModel
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof InformModel
   */
  contentJSON?: string
  /**
   *
   * @type {string}
   * @memberof InformModel
   */
  contentHTML?: string
  /**
   *
   * @type {string}
   * @memberof InformModel
   */
  type?: InformModelTypeEnum
  /**
   *
   * @type {boolean}
   * @memberof InformModel
   */
  isTodayShown: boolean
  /**
   *
   * @type {string}
   * @memberof InformModel
   */
  updatedAt?: string
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof InformModel
   */
  contentMap: { [key: string]: string }
}

/**
 * @export
 * @enum {string}
 */
export enum InformModelTypeEnum {
  INFO = 'INFO',
  ACTUALITY = 'ACTUALITY',
}

/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {any}
   * @memberof InlineObject
   */
  file: any
}
/**
 *
 * @export
 * @interface JwtResponse
 */
export interface JwtResponse {
  /**
   *
   * @type {string}
   * @memberof JwtResponse
   */
  accessToken: string
  /**
   *
   * @type {string}
   * @memberof JwtResponse
   */
  refreshToken: string
  /**
   *
   * @type {boolean}
   * @memberof JwtResponse
   */
  isSuperUser: boolean
  /**
   *
   * @type {string}
   * @memberof JwtResponse
   */
  username: string
  /**
   *
   * @type {string}
   * @memberof JwtResponse
   */
  fullname: string
  /**
   *
   * @type {string}
   * @memberof JwtResponse
   */
  email: string
  /**
   *
   * @type {Array<string>}
   * @memberof JwtResponse
   */
  roles: Array<string>
}
/**
 *
 * @export
 * @interface KulsoVaro
 */
export interface KulsoVaro {
  /**
   *
   * @type {Meta}
   * @memberof KulsoVaro
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof KulsoVaro
   */
  weatherTemp?: string
  /**
   *
   * @type {string}
   * @memberof KulsoVaro
   */
  weatherIcon?: string
  /**
   *
   * @type {{ [key: string]: DepartureModel; }}
   * @memberof KulsoVaro
   */
  nextDepartures: { [key: string]: DepartureModel }
  /**
   *
   * @type {string}
   * @memberof KulsoVaro
   */
  serverDateTime?: string
}
/**
 *
 * @export
 * @interface KulsoVaroV2
 */
export interface KulsoVaroV2 {
  /**
   *
   * @type {Meta}
   * @memberof KulsoVaroV2
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof KulsoVaroV2
   */
  weatherTemp?: string
  /**
   *
   * @type {string}
   * @memberof KulsoVaroV2
   */
  weatherIcon?: string
  /**
   *
   * @type {{ [key: string]: Array<DepartureModel>; }}
   * @memberof KulsoVaroV2
   */
  nextDepartures: { [key: string]: Array<DepartureModel> }
  /**
   *
   * @type {string}
   * @memberof KulsoVaroV2
   */
  serverDateTime?: string
}
/**
 *
 * @export
 * @interface LegendaLoginResponse
 */
export interface LegendaLoginResponse {
  /**
   *
   * @type {number}
   * @memberof LegendaLoginResponse
   */
  userId?: number
  /**
   *
   * @type {string}
   * @memberof LegendaLoginResponse
   */
  fullName?: string
  /**
   *
   * @type {number}
   * @memberof LegendaLoginResponse
   */
  loginId?: number
  /**
   *
   * @type {string}
   * @memberof LegendaLoginResponse
   */
  email?: string
}
/**
 *
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  hour?: number
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  minute?: number
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  second?: number
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  nano?: number
}
/**
 *
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  username: string
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  password: string
}
/**
 *
 * @export
 * @interface MediaFileDTO
 */
export interface MediaFileDTO {
  /**
   *
   * @type {string}
   * @memberof MediaFileDTO
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof MediaFileDTO
   */
  filename: string
  /**
   *
   * @type {string}
   * @memberof MediaFileDTO
   */
  fileType: MediaFileDTOFileTypeEnum
  /**
   *
   * @type {string}
   * @memberof MediaFileDTO
   */
  fileURL?: string
  /**
   *
   * @type {string}
   * @memberof MediaFileDTO
   */
  thumbnailURL?: string
  /**
   *
   * @type {number}
   * @memberof MediaFileDTO
   */
  id?: number
}

/**
 * @export
 * @enum {string}
 */
export enum MediaFileDTOFileTypeEnum {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

/**
 *
 * @export
 * @interface Mediafile
 */
export interface Mediafile {
  /**
   *
   * @type {Meta}
   * @memberof Mediafile
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof Mediafile
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof Mediafile
   */
  fileURL?: string
  /**
   *
   * @type {string}
   * @memberof Mediafile
   */
  filetype?: string
  /**
   *
   * @type {string}
   * @memberof Mediafile
   */
  thumbnailURL?: string
  /**
   *
   * @type {number}
   * @memberof Mediafile
   */
  id?: number
}
/**
 *
 * @export
 * @interface Meta
 */
export interface Meta {
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  contentType: MetaContentTypeEnum
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  gate?: MetaGateEnum
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  thumnailURL: string
  /**
   *
   * @type {boolean}
   * @memberof Meta
   */
  canHandleActuality: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum MetaContentTypeEnum {
  SPLASH = 'SPLASH',
  WARNING = 'WARNING',
  MEDIAFILE = 'MEDIAFILE',
  RAKPART_PROMO = 'RAKPART_PROMO',
  RAKPART_PROMO_V2 = 'RAKPART_PROMO_V2',
  RAKPART_PROMO_V3 = 'RAKPART_PROMO_V3',
  RAKPART_PROMO_V4 = 'RAKPART_PROMO_V4',
  RAKPART_PROMO_V5 = 'RAKPART_PROMO_V5',
  RAKPART_PROMO_V6 = 'RAKPART_PROMO_V6',
  BESZALLO_HID1 = 'BESZALLO_HID1',
  BESZALLO_HID1_V2 = 'BESZALLO_HID1_V2',
  PENZTAR = 'PENZTAR',
  PENZTAR_V2 = 'PENZTAR_V2',
  PENZTAR_V3 = 'PENZTAR_V3',
  KULSO_VARO1 = 'KULSO_VARO1',
  KULSO_VARO1_V2 = 'KULSO_VARO1_V2',
  KULSO_VARO2 = 'KULSO_VARO2',
  KULSO_VARO2_V2 = 'KULSO_VARO2_V2',
  UTAS_VARO1 = 'UTAS_VARO1',
  UTAS_VARO1_V5 = 'UTAS_VARO1_V5',
  UTAS_VARO1_V6 = 'UTAS_VARO1_V6',
  UTAS_VARO1_V7 = 'UTAS_VARO1_V7',
  UTAS_VARO2 = 'UTAS_VARO2',
  UTAS_VARO2_V5 = 'UTAS_VARO2_V5',
  UTAS_VARO2_V6 = 'UTAS_VARO2_V6',
  UTAS_VARO2_V7 = 'UTAS_VARO2_V7',
  UTAS_VARO3 = 'UTAS_VARO3',
  BAR = 'BAR',
  FELSO_TERASZ = 'FELSO_TERASZ',
}
/**
 * @export
 * @enum {string}
 */
export enum MetaGateEnum {
  GATE1 = 'GATE1',
  GATE2 = 'GATE2',
  GATE3 = 'GATE3',
}

/**
 *
 * @export
 * @interface Penztar
 */
export interface Penztar {
  /**
   *
   * @type {Meta}
   * @memberof Penztar
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof Penztar
   */
  exchangeRate?: string
  /**
   *
   * @type {Array<PricetableItem>}
   * @memberof Penztar
   */
  ticketPrices: Array<PricetableItem>
  /**
   *
   * @type {Array<DepartureModel>}
   * @memberof Penztar
   */
  departures: Array<DepartureModel>
  /**
   *
   * @type {InformModel}
   * @memberof Penztar
   */
  actuality?: InformModel
}
/**
 *
 * @export
 * @interface PricetableItem
 */
export interface PricetableItem {
  /**
   *
   * @type {Cruise}
   * @memberof PricetableItem
   */
  cruise: Cruise
  /**
   *
   * @type {Array<TicketPriceDTO>}
   * @memberof PricetableItem
   */
  prices: Array<TicketPriceDTO>
}
/**
 *
 * @export
 * @interface RakpartPromo
 */
export interface RakpartPromo {
  /**
   *
   * @type {Meta}
   * @memberof RakpartPromo
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof RakpartPromo
   */
  exchangeRate?: string
  /**
   *
   * @type {Array<PricetableItem>}
   * @memberof RakpartPromo
   */
  ticketPrices: Array<PricetableItem>
  /**
   *
   * @type {Array<DepartureModel>}
   * @memberof RakpartPromo
   */
  departures: Array<DepartureModel>
  /**
   *
   * @type {InformModel}
   * @memberof RakpartPromo
   */
  actuality?: InformModel
}
/**
 *
 * @export
 * @interface RenameScreenRequest
 */
export interface RenameScreenRequest {
  /**
   *
   * @type {string}
   * @memberof RenameScreenRequest
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof RenameScreenRequest
   */
  name: string
}
/**
 *
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
  /**
   *
   * @type {string}
   * @memberof RoleModel
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof RoleModel
   */
  title: string
  /**
   *
   * @type {Array<AuthorityModel>}
   * @memberof RoleModel
   */
  authorities: Array<AuthorityModel>
  /**
   *
   * @type {number}
   * @memberof RoleModel
   */
  id: number
}
/**
 *
 * @export
 * @interface RunnableContentDTO
 */
export interface RunnableContentDTO {
  /**
   *
   * @type {string}
   * @memberof RunnableContentDTO
   */
  contentType: RunnableContentDTOContentTypeEnum
  /**
   *
   * @type {string}
   * @memberof RunnableContentDTO
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof RunnableContentDTO
   */
  gate?: RunnableContentDTOGateEnum
  /**
   *
   * @type {string}
   * @memberof RunnableContentDTO
   */
  thumnailURL: string
  /**
   *
   * @type {boolean}
   * @memberof RunnableContentDTO
   */
  canHandleActuality: boolean
  /**
   *
   * @type {string}
   * @memberof RunnableContentDTO
   */
  contentId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum RunnableContentDTOContentTypeEnum {
  SPLASH = 'SPLASH',
  WARNING = 'WARNING',
  MEDIAFILE = 'MEDIAFILE',
  RAKPART_PROMO = 'RAKPART_PROMO',
  RAKPART_PROMO_V2 = 'RAKPART_PROMO_V2',
  RAKPART_PROMO_V3 = 'RAKPART_PROMO_V3',
  RAKPART_PROMO_V4 = 'RAKPART_PROMO_V4',
  RAKPART_PROMO_V5 = 'RAKPART_PROMO_V5',
  RAKPART_PROMO_V6 = 'RAKPART_PROMO_V6',
  BESZALLO_HID1 = 'BESZALLO_HID1',
  BESZALLO_HID1_V2 = 'BESZALLO_HID1_V2',
  PENZTAR = 'PENZTAR',
  PENZTAR_V2 = 'PENZTAR_V2',
  PENZTAR_V3 = 'PENZTAR_V3',
  KULSO_VARO1 = 'KULSO_VARO1',
  KULSO_VARO1_V2 = 'KULSO_VARO1_V2',
  KULSO_VARO2 = 'KULSO_VARO2',
  KULSO_VARO2_V2 = 'KULSO_VARO2_V2',
  UTAS_VARO1 = 'UTAS_VARO1',
  UTAS_VARO1_V5 = 'UTAS_VARO1_V5',
  UTAS_VARO1_V6 = 'UTAS_VARO1_V6',
  UTAS_VARO1_V7 = 'UTAS_VARO1_V7',
  UTAS_VARO2 = 'UTAS_VARO2',
  UTAS_VARO2_V5 = 'UTAS_VARO2_V5',
  UTAS_VARO2_V6 = 'UTAS_VARO2_V6',
  UTAS_VARO2_V7 = 'UTAS_VARO2_V7',
  UTAS_VARO3 = 'UTAS_VARO3',
  BAR = 'BAR',
  FELSO_TERASZ = 'FELSO_TERASZ',
}
/**
 * @export
 * @enum {string}
 */
export enum RunnableContentDTOGateEnum {
  GATE1 = 'GATE1',
  GATE2 = 'GATE2',
  GATE3 = 'GATE3',
}

/**
 *
 * @export
 * @interface RunningContent
 */
export interface RunningContent {
  /**
   *
   * @type {string}
   * @memberof RunningContent
   */
  contentType: RunningContentContentTypeEnum
  /**
   *
   * @type {Warning}
   * @memberof RunningContent
   */
  warningInstance?: Warning
  /**
   *
   * @type {Bar}
   * @memberof RunningContent
   */
  barInstance?: Bar
  /**
   *
   * @type {Mediafile}
   * @memberof RunningContent
   */
  mediaFileInstance?: Mediafile
  /**
   *
   * @type {RakpartPromo}
   * @memberof RunningContent
   */
  rakpartPromoInstance?: RakpartPromo
  /**
   *
   * @type {Splash}
   * @memberof RunningContent
   */
  splashInstance?: Splash
  /**
   *
   * @type {BeszalloHid}
   * @memberof RunningContent
   */
  beszallohidInstance?: BeszalloHid
  /**
   *
   * @type {BeszalloHid1V2}
   * @memberof RunningContent
   */
  beszallohidV2Instance?: BeszalloHid1V2
  /**
   *
   * @type {Penztar}
   * @memberof RunningContent
   */
  penztarInstance?: Penztar
  /**
   *
   * @type {KulsoVaro}
   * @memberof RunningContent
   */
  kulsoVaroInstance?: KulsoVaro
  /**
   *
   * @type {KulsoVaroV2}
   * @memberof RunningContent
   */
  kulsoVaroV2Instance?: KulsoVaroV2
  /**
   *
   * @type {UtasVaroV5}
   * @memberof RunningContent
   */
  utasVaroV5Instance?: UtasVaroV5
  /**
   *
   * @type {Utasvaro}
   * @memberof RunningContent
   */
  utasvaroInstance?: Utasvaro
  /**
   *
   * @type {FelsoTerasz}
   * @memberof RunningContent
   */
  felsoTeraszInstance?: FelsoTerasz
}

/**
 * @export
 * @enum {string}
 */
export enum RunningContentContentTypeEnum {
  SPLASH = 'SPLASH',
  WARNING = 'WARNING',
  MEDIAFILE = 'MEDIAFILE',
  RAKPART_PROMO = 'RAKPART_PROMO',
  RAKPART_PROMO_V2 = 'RAKPART_PROMO_V2',
  RAKPART_PROMO_V3 = 'RAKPART_PROMO_V3',
  RAKPART_PROMO_V4 = 'RAKPART_PROMO_V4',
  RAKPART_PROMO_V5 = 'RAKPART_PROMO_V5',
  RAKPART_PROMO_V6 = 'RAKPART_PROMO_V6',
  BESZALLO_HID1 = 'BESZALLO_HID1',
  BESZALLO_HID1_V2 = 'BESZALLO_HID1_V2',
  PENZTAR = 'PENZTAR',
  PENZTAR_V2 = 'PENZTAR_V2',
  PENZTAR_V3 = 'PENZTAR_V3',
  KULSO_VARO1 = 'KULSO_VARO1',
  KULSO_VARO1_V2 = 'KULSO_VARO1_V2',
  KULSO_VARO2 = 'KULSO_VARO2',
  KULSO_VARO2_V2 = 'KULSO_VARO2_V2',
  UTAS_VARO1 = 'UTAS_VARO1',
  UTAS_VARO1_V5 = 'UTAS_VARO1_V5',
  UTAS_VARO1_V6 = 'UTAS_VARO1_V6',
  UTAS_VARO1_V7 = 'UTAS_VARO1_V7',
  UTAS_VARO2 = 'UTAS_VARO2',
  UTAS_VARO2_V5 = 'UTAS_VARO2_V5',
  UTAS_VARO2_V6 = 'UTAS_VARO2_V6',
  UTAS_VARO2_V7 = 'UTAS_VARO2_V7',
  UTAS_VARO3 = 'UTAS_VARO3',
  BAR = 'BAR',
  FELSO_TERASZ = 'FELSO_TERASZ',
}

/**
 *
 * @export
 * @interface ScreenContentSetRequest
 */
export interface ScreenContentSetRequest {
  /**
   *
   * @type {string}
   * @memberof ScreenContentSetRequest
   */
  screenId: string
  /**
   *
   * @type {string}
   * @memberof ScreenContentSetRequest
   */
  contentType: ScreenContentSetRequestContentTypeEnum
  /**
   *
   * @type {string}
   * @memberof ScreenContentSetRequest
   */
  contentId: string
}

/**
 * @export
 * @enum {string}
 */
export enum ScreenContentSetRequestContentTypeEnum {
  SPLASH = 'SPLASH',
  WARNING = 'WARNING',
  MEDIAFILE = 'MEDIAFILE',
  RAKPART_PROMO = 'RAKPART_PROMO',
  RAKPART_PROMO_V2 = 'RAKPART_PROMO_V2',
  RAKPART_PROMO_V3 = 'RAKPART_PROMO_V3',
  RAKPART_PROMO_V4 = 'RAKPART_PROMO_V4',
  RAKPART_PROMO_V5 = 'RAKPART_PROMO_V5',
  RAKPART_PROMO_V6 = 'RAKPART_PROMO_V6',
  BESZALLO_HID1 = 'BESZALLO_HID1',
  BESZALLO_HID1_V2 = 'BESZALLO_HID1_V2',
  PENZTAR = 'PENZTAR',
  PENZTAR_V2 = 'PENZTAR_V2',
  PENZTAR_V3 = 'PENZTAR_V3',
  KULSO_VARO1 = 'KULSO_VARO1',
  KULSO_VARO1_V2 = 'KULSO_VARO1_V2',
  KULSO_VARO2 = 'KULSO_VARO2',
  KULSO_VARO2_V2 = 'KULSO_VARO2_V2',
  UTAS_VARO1 = 'UTAS_VARO1',
  UTAS_VARO1_V5 = 'UTAS_VARO1_V5',
  UTAS_VARO1_V6 = 'UTAS_VARO1_V6',
  UTAS_VARO1_V7 = 'UTAS_VARO1_V7',
  UTAS_VARO2 = 'UTAS_VARO2',
  UTAS_VARO2_V5 = 'UTAS_VARO2_V5',
  UTAS_VARO2_V6 = 'UTAS_VARO2_V6',
  UTAS_VARO2_V7 = 'UTAS_VARO2_V7',
  UTAS_VARO3 = 'UTAS_VARO3',
  BAR = 'BAR',
  FELSO_TERASZ = 'FELSO_TERASZ',
}

/**
 *
 * @export
 * @interface ScreenModel
 */
export interface ScreenModel {
  /**
   *
   * @type {string}
   * @memberof ScreenModel
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ScreenModel
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ScreenModel
   */
  contentType: ScreenModelContentTypeEnum
  /**
   *
   * @type {string}
   * @memberof ScreenModel
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof ScreenModel
   */
  gate?: ScreenModelGateEnum
  /**
   *
   * @type {string}
   * @memberof ScreenModel
   */
  status: ScreenModelStatusEnum
  /**
   *
   * @type {DeviceModel}
   * @memberof ScreenModel
   */
  device?: DeviceModel
  /**
   *
   * @type {RunningContent}
   * @memberof ScreenModel
   */
  runningContent?: RunningContent
  /**
   *
   * @type {InformModel}
   * @memberof ScreenModel
   */
  info?: InformModel
  /**
   *
   * @type {InformModel}
   * @memberof ScreenModel
   */
  actuality?: InformModel
  /**
   *
   * @type {string}
   * @memberof ScreenModel
   */
  connectedAt?: string
  /**
   *
   * @type {string}
   * @memberof ScreenModel
   */
  disconnectedAt?: string
  /**
   *
   * @type {boolean}
   * @memberof ScreenModel
   */
  connected: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum ScreenModelContentTypeEnum {
  SPLASH = 'SPLASH',
  WARNING = 'WARNING',
  MEDIAFILE = 'MEDIAFILE',
  RAKPART_PROMO = 'RAKPART_PROMO',
  RAKPART_PROMO_V2 = 'RAKPART_PROMO_V2',
  RAKPART_PROMO_V3 = 'RAKPART_PROMO_V3',
  RAKPART_PROMO_V4 = 'RAKPART_PROMO_V4',
  RAKPART_PROMO_V5 = 'RAKPART_PROMO_V5',
  RAKPART_PROMO_V6 = 'RAKPART_PROMO_V6',
  BESZALLO_HID1 = 'BESZALLO_HID1',
  BESZALLO_HID1_V2 = 'BESZALLO_HID1_V2',
  PENZTAR = 'PENZTAR',
  PENZTAR_V2 = 'PENZTAR_V2',
  PENZTAR_V3 = 'PENZTAR_V3',
  KULSO_VARO1 = 'KULSO_VARO1',
  KULSO_VARO1_V2 = 'KULSO_VARO1_V2',
  KULSO_VARO2 = 'KULSO_VARO2',
  KULSO_VARO2_V2 = 'KULSO_VARO2_V2',
  UTAS_VARO1 = 'UTAS_VARO1',
  UTAS_VARO1_V5 = 'UTAS_VARO1_V5',
  UTAS_VARO1_V6 = 'UTAS_VARO1_V6',
  UTAS_VARO1_V7 = 'UTAS_VARO1_V7',
  UTAS_VARO2 = 'UTAS_VARO2',
  UTAS_VARO2_V5 = 'UTAS_VARO2_V5',
  UTAS_VARO2_V6 = 'UTAS_VARO2_V6',
  UTAS_VARO2_V7 = 'UTAS_VARO2_V7',
  UTAS_VARO3 = 'UTAS_VARO3',
  BAR = 'BAR',
  FELSO_TERASZ = 'FELSO_TERASZ',
}
/**
 * @export
 * @enum {string}
 */
export enum ScreenModelGateEnum {
  GATE1 = 'GATE1',
  GATE2 = 'GATE2',
  GATE3 = 'GATE3',
}
/**
 * @export
 * @enum {string}
 */
export enum ScreenModelStatusEnum {
  LIVE = 'LIVE',
  PAUSED = 'PAUSED',
}

/**
 *
 * @export
 * @interface ScreenRegistrationResponse
 */
export interface ScreenRegistrationResponse {
  /**
   *
   * @type {string}
   * @memberof ScreenRegistrationResponse
   */
  screenId: string
  /**
   *
   * @type {string}
   * @memberof ScreenRegistrationResponse
   */
  environment: string
  /**
   *
   * @type {string}
   * @memberof ScreenRegistrationResponse
   */
  serverTime: string
  /**
   *
   * @type {WeatherDataDTO}
   * @memberof ScreenRegistrationResponse
   */
  weatherDataDTO?: WeatherDataDTO
  /**
   *
   * @type {DeviceModel}
   * @memberof ScreenRegistrationResponse
   */
  device?: DeviceModel
  /**
   *
   * @type {string}
   * @memberof ScreenRegistrationResponse
   */
  contentType?: ScreenRegistrationResponseContentTypeEnum
  /**
   *
   * @type {RunningContent}
   * @memberof ScreenRegistrationResponse
   */
  content: RunningContent
  /**
   *
   * @type {InformModel}
   * @memberof ScreenRegistrationResponse
   */
  info?: InformModel
  /**
   *
   * @type {boolean}
   * @memberof ScreenRegistrationResponse
   */
  exitApp: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum ScreenRegistrationResponseContentTypeEnum {
  SPLASH = 'SPLASH',
  WARNING = 'WARNING',
  MEDIAFILE = 'MEDIAFILE',
  RAKPART_PROMO = 'RAKPART_PROMO',
  RAKPART_PROMO_V2 = 'RAKPART_PROMO_V2',
  RAKPART_PROMO_V3 = 'RAKPART_PROMO_V3',
  RAKPART_PROMO_V4 = 'RAKPART_PROMO_V4',
  RAKPART_PROMO_V5 = 'RAKPART_PROMO_V5',
  RAKPART_PROMO_V6 = 'RAKPART_PROMO_V6',
  BESZALLO_HID1 = 'BESZALLO_HID1',
  BESZALLO_HID1_V2 = 'BESZALLO_HID1_V2',
  PENZTAR = 'PENZTAR',
  PENZTAR_V2 = 'PENZTAR_V2',
  PENZTAR_V3 = 'PENZTAR_V3',
  KULSO_VARO1 = 'KULSO_VARO1',
  KULSO_VARO1_V2 = 'KULSO_VARO1_V2',
  KULSO_VARO2 = 'KULSO_VARO2',
  KULSO_VARO2_V2 = 'KULSO_VARO2_V2',
  UTAS_VARO1 = 'UTAS_VARO1',
  UTAS_VARO1_V5 = 'UTAS_VARO1_V5',
  UTAS_VARO1_V6 = 'UTAS_VARO1_V6',
  UTAS_VARO1_V7 = 'UTAS_VARO1_V7',
  UTAS_VARO2 = 'UTAS_VARO2',
  UTAS_VARO2_V5 = 'UTAS_VARO2_V5',
  UTAS_VARO2_V6 = 'UTAS_VARO2_V6',
  UTAS_VARO2_V7 = 'UTAS_VARO2_V7',
  UTAS_VARO3 = 'UTAS_VARO3',
  BAR = 'BAR',
  FELSO_TERASZ = 'FELSO_TERASZ',
}

/**
 *
 * @export
 * @interface SearchInformRequest
 */
export interface SearchInformRequest {
  /**
   *
   * @type {string}
   * @memberof SearchInformRequest
   */
  type: SearchInformRequestTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum SearchInformRequestTypeEnum {
  INFO = 'INFO',
  ACTUALITY = 'ACTUALITY',
}

/**
 *
 * @export
 * @interface SeasonModel
 */
export interface SeasonModel {
  /**
   *
   * @type {string}
   * @memberof SeasonModel
   */
  name: string
  /**
   *
   * @type {SeasonModelStartDay}
   * @memberof SeasonModel
   */
  startDay: SeasonModelStartDay
  /**
   *
   * @type {SeasonModelStartDay}
   * @memberof SeasonModel
   */
  endDay: SeasonModelStartDay
  /**
   *
   * @type {number}
   * @memberof SeasonModel
   */
  id: number
  /**
   *
   * @type {boolean}
   * @memberof SeasonModel
   */
  isRunningSeason: boolean
}
/**
 *
 * @export
 * @interface SeasonModelStartDay
 */
export interface SeasonModelStartDay {
  /**
   *
   * @type {string}
   * @memberof SeasonModelStartDay
   */
  month?: SeasonModelStartDayMonthEnum
  /**
   *
   * @type {number}
   * @memberof SeasonModelStartDay
   */
  monthValue?: number
  /**
   *
   * @type {number}
   * @memberof SeasonModelStartDay
   */
  dayOfMonth?: number
}

/**
 * @export
 * @enum {string}
 */
export enum SeasonModelStartDayMonthEnum {
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}

/**
 *
 * @export
 * @interface SendActualityToContentRequest
 */
export interface SendActualityToContentRequest {
  /**
   *
   * @type {string}
   * @memberof SendActualityToContentRequest
   */
  informId: string
  /**
   *
   * @type {Array<string>}
   * @memberof SendActualityToContentRequest
   */
  contentTypes: Array<SendActualityToContentRequestContentTypesEnum>
  /**
   *
   * @type {boolean}
   * @memberof SendActualityToContentRequest
   */
  toDisplay: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum SendActualityToContentRequestContentTypesEnum {
  SPLASH = 'SPLASH',
  WARNING = 'WARNING',
  MEDIAFILE = 'MEDIAFILE',
  RAKPART_PROMO = 'RAKPART_PROMO',
  RAKPART_PROMO_V2 = 'RAKPART_PROMO_V2',
  RAKPART_PROMO_V3 = 'RAKPART_PROMO_V3',
  RAKPART_PROMO_V4 = 'RAKPART_PROMO_V4',
  RAKPART_PROMO_V5 = 'RAKPART_PROMO_V5',
  RAKPART_PROMO_V6 = 'RAKPART_PROMO_V6',
  BESZALLO_HID1 = 'BESZALLO_HID1',
  BESZALLO_HID1_V2 = 'BESZALLO_HID1_V2',
  PENZTAR = 'PENZTAR',
  PENZTAR_V2 = 'PENZTAR_V2',
  PENZTAR_V3 = 'PENZTAR_V3',
  KULSO_VARO1 = 'KULSO_VARO1',
  KULSO_VARO1_V2 = 'KULSO_VARO1_V2',
  KULSO_VARO2 = 'KULSO_VARO2',
  KULSO_VARO2_V2 = 'KULSO_VARO2_V2',
  UTAS_VARO1 = 'UTAS_VARO1',
  UTAS_VARO1_V5 = 'UTAS_VARO1_V5',
  UTAS_VARO1_V6 = 'UTAS_VARO1_V6',
  UTAS_VARO1_V7 = 'UTAS_VARO1_V7',
  UTAS_VARO2 = 'UTAS_VARO2',
  UTAS_VARO2_V5 = 'UTAS_VARO2_V5',
  UTAS_VARO2_V6 = 'UTAS_VARO2_V6',
  UTAS_VARO2_V7 = 'UTAS_VARO2_V7',
  UTAS_VARO3 = 'UTAS_VARO3',
  BAR = 'BAR',
  FELSO_TERASZ = 'FELSO_TERASZ',
}

/**
 *
 * @export
 * @interface SendInformToScreenRequest
 */
export interface SendInformToScreenRequest {
  /**
   *
   * @type {string}
   * @memberof SendInformToScreenRequest
   */
  informId: string
  /**
   *
   * @type {Array<string>}
   * @memberof SendInformToScreenRequest
   */
  screenIds: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof SendInformToScreenRequest
   */
  toDisplay: boolean
}
/**
 *
 * @export
 * @interface SetPrivilegesRequest
 */
export interface SetPrivilegesRequest {
  /**
   *
   * @type {string}
   * @memberof SetPrivilegesRequest
   */
  roleName: string
  /**
   *
   * @type {Set<number>}
   * @memberof SetPrivilegesRequest
   */
  privileges: Set<number>
}
/**
 *
 * @export
 * @interface Splash
 */
export interface Splash {
  /**
   *
   * @type {Meta}
   * @memberof Splash
   */
  meta: Meta
}
/**
 *
 * @export
 * @interface TicketPriceCreateRequest
 */
export interface TicketPriceCreateRequest {
  /**
   *
   * @type {string}
   * @memberof TicketPriceCreateRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof TicketPriceCreateRequest
   */
  cruiseId: string
  /**
   *
   * @type {string}
   * @memberof TicketPriceCreateRequest
   */
  priceEUR: string
  /**
   *
   * @type {string}
   * @memberof TicketPriceCreateRequest
   */
  type: TicketPriceCreateRequestTypeEnum
  /**
   *
   * @type {number}
   * @memberof TicketPriceCreateRequest
   */
  index: number
}

/**
 * @export
 * @enum {string}
 */
export enum TicketPriceCreateRequestTypeEnum {
  NORMAL = 'NORMAL',
  ADULT = 'ADULT',
  BPCARD = 'BPCARD',
}

/**
 *
 * @export
 * @interface TicketPriceDTO
 */
export interface TicketPriceDTO {
  /**
   *
   * @type {number}
   * @memberof TicketPriceDTO
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TicketPriceDTO
   */
  name: string
  /**
   *
   * @type {Cruise}
   * @memberof TicketPriceDTO
   */
  cruise: Cruise
  /**
   *
   * @type {string}
   * @memberof TicketPriceDTO
   */
  priceEUR: string
  /**
   *
   * @type {string}
   * @memberof TicketPriceDTO
   */
  priceHUF: string
  /**
   *
   * @type {number}
   * @memberof TicketPriceDTO
   */
  index: number
  /**
   *
   * @type {boolean}
   * @memberof TicketPriceDTO
   */
  isPublished: boolean
  /**
   *
   * @type {string}
   * @memberof TicketPriceDTO
   */
  internalId?: string
  /**
   *
   * @type {string}
   * @memberof TicketPriceDTO
   */
  internalName?: string
  /**
   *
   * @type {Set<string>}
   * @memberof TicketPriceDTO
   */
  tags?: Set<string>
  /**
   *
   * @type {string}
   * @memberof TicketPriceDTO
   */
  type?: TicketPriceDTOTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum TicketPriceDTOTypeEnum {
  NORMAL = 'NORMAL',
  ADULT = 'ADULT',
  BPCARD = 'BPCARD',
}

/**
 *
 * @export
 * @interface TicketPriceIndexUpdateRequests
 */
export interface TicketPriceIndexUpdateRequests {
  /**
   *
   * @type {Array<TicketPriceStatusUpdateRequest>}
   * @memberof TicketPriceIndexUpdateRequests
   */
  requests: Array<TicketPriceStatusUpdateRequest>
}
/**
 *
 * @export
 * @interface TicketPriceStatusUpdateRequest
 */
export interface TicketPriceStatusUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof TicketPriceStatusUpdateRequest
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof TicketPriceStatusUpdateRequest
   */
  isPublished: boolean
  /**
   *
   * @type {number}
   * @memberof TicketPriceStatusUpdateRequest
   */
  index: number
}
/**
 *
 * @export
 * @interface TicketPriceUpdateRequest
 */
export interface TicketPriceUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof TicketPriceUpdateRequest
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TicketPriceUpdateRequest
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TicketPriceUpdateRequest
   */
  priceEUR?: string
  /**
   *
   * @type {Set<string>}
   * @memberof TicketPriceUpdateRequest
   */
  tags?: Set<string>
  /**
   *
   * @type {number}
   * @memberof TicketPriceUpdateRequest
   */
  index?: number
  /**
   *
   * @type {string}
   * @memberof TicketPriceUpdateRequest
   */
  type?: TicketPriceUpdateRequestTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum TicketPriceUpdateRequestTypeEnum {
  NORMAL = 'NORMAL',
  ADULT = 'ADULT',
  BPCARD = 'BPCARD',
}

/**
 *
 * @export
 * @interface TicketPricesDeleteRequest
 */
export interface TicketPricesDeleteRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof TicketPricesDeleteRequest
   */
  priceIds: Array<number>
}
/**
 *
 * @export
 * @interface TokenRefreshRequest
 */
export interface TokenRefreshRequest {
  /**
   *
   * @type {string}
   * @memberof TokenRefreshRequest
   */
  refreshToken: string
}
/**
 *
 * @export
 * @interface TokenRefreshResponse
 */
export interface TokenRefreshResponse {
  /**
   *
   * @type {string}
   * @memberof TokenRefreshResponse
   */
  accessToken: string
  /**
   *
   * @type {string}
   * @memberof TokenRefreshResponse
   */
  refreshToken: string
  /**
   *
   * @type {string}
   * @memberof TokenRefreshResponse
   */
  tokenType: string
}
/**
 *
 * @export
 * @interface UpdateDepartureStatusesRequest
 */
export interface UpdateDepartureStatusesRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateDepartureStatusesRequest
   */
  seasonId: number
  /**
   *
   * @type {number}
   * @memberof UpdateDepartureStatusesRequest
   */
  cruiseId: number
  /**
   *
   * @type {string}
   * @memberof UpdateDepartureStatusesRequest
   */
  dayOfWeek?: UpdateDepartureStatusesRequestDayOfWeekEnum
  /**
   *
   * @type {LocalTime}
   * @memberof UpdateDepartureStatusesRequest
   */
  startTime: LocalTime
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateDepartureStatusesRequest
   */
  startDays?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof UpdateDepartureStatusesRequest
   */
  isFull?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateDepartureStatusesRequest
   */
  isCancelled?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateDepartureStatusesRequest
   */
  gate?: UpdateDepartureStatusesRequestGateEnum
  /**
   *
   * @type {boolean}
   * @memberof UpdateDepartureStatusesRequest
   */
  isPublished?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateDepartureStatusesRequest
   */
  includesUnique: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateDepartureStatusesRequestDayOfWeekEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateDepartureStatusesRequestGateEnum {
  GATE1 = 'GATE1',
  GATE2 = 'GATE2',
  GATE3 = 'GATE3',
}

/**
 *
 * @export
 * @interface UpdateDeparturesDescriptionRequest
 */
export interface UpdateDeparturesDescriptionRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateDeparturesDescriptionRequest
   */
  cruiseId: number
  /**
   *
   * @type {string}
   * @memberof UpdateDeparturesDescriptionRequest
   */
  startAt: string
  /**
   *
   * @type {string}
   * @memberof UpdateDeparturesDescriptionRequest
   */
  text: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateDeparturesDescriptionRequest
   */
  includesUnique: boolean
}
/**
 *
 * @export
 * @interface UpdateEURExchangeRateRequest
 */
export interface UpdateEURExchangeRateRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateEURExchangeRateRequest
   */
  rate: string
}
/**
 *
 * @export
 * @interface UpdateUniqueDepartureDescriptionRequest
 */
export interface UpdateUniqueDepartureDescriptionRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateUniqueDepartureDescriptionRequest
   */
  departureId: number
  /**
   *
   * @type {string}
   * @memberof UpdateUniqueDepartureDescriptionRequest
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof UpdateUniqueDepartureDescriptionRequest
   */
  startDate: string
}
/**
 *
 * @export
 * @interface UpdateUniqueDepartureStatusesRequest
 */
export interface UpdateUniqueDepartureStatusesRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateUniqueDepartureStatusesRequest
   */
  departureId: number
  /**
   *
   * @type {string}
   * @memberof UpdateUniqueDepartureStatusesRequest
   */
  date: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateUniqueDepartureStatusesRequest
   */
  isFull?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateUniqueDepartureStatusesRequest
   */
  isCancelled?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateUniqueDepartureStatusesRequest
   */
  gate?: UpdateUniqueDepartureStatusesRequestGateEnum
  /**
   *
   * @type {boolean}
   * @memberof UpdateUniqueDepartureStatusesRequest
   */
  isPublished?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateUniqueDepartureStatusesRequestGateEnum {
  GATE1 = 'GATE1',
  GATE2 = 'GATE2',
  GATE3 = 'GATE3',
}

/**
 *
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
  /**
   *
   * @type {string}
   * @memberof UploadFileResponse
   */
  fileName: string
  /**
   *
   * @type {string}
   * @memberof UploadFileResponse
   */
  fileDownloadUri: string
  /**
   *
   * @type {string}
   * @memberof UploadFileResponse
   */
  fileType: string
  /**
   *
   * @type {number}
   * @memberof UploadFileResponse
   */
  size: number
}
/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  username: string
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  fullName: string
  /**
   *
   * @type {boolean}
   * @memberof UserDto
   */
  isEnabled: boolean
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  lastLogin?: string
  /**
   *
   * @type {boolean}
   * @memberof UserDto
   */
  isSuperuser: boolean
  /**
   *
   * @type {Set<string>}
   * @memberof UserDto
   */
  privileges: Set<string>
}
/**
 *
 * @export
 * @interface UserEnabledReqest
 */
export interface UserEnabledReqest {
  /**
   *
   * @type {string}
   * @memberof UserEnabledReqest
   */
  username: string
  /**
   *
   * @type {boolean}
   * @memberof UserEnabledReqest
   */
  enabled: boolean
}
/**
 *
 * @export
 * @interface UsersReqest
 */
export interface UsersReqest {
  /**
   *
   * @type {boolean}
   * @memberof UsersReqest
   */
  isAdmin?: boolean
}
/**
 *
 * @export
 * @interface UtasVaroV5
 */
export interface UtasVaroV5 {
  /**
   *
   * @type {Meta}
   * @memberof UtasVaroV5
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof UtasVaroV5
   */
  weatherTemp?: string
  /**
   *
   * @type {string}
   * @memberof UtasVaroV5
   */
  weatherIcon?: string
  /**
   *
   * @type {{ [key: string]: Array<DepartureModel>; }}
   * @memberof UtasVaroV5
   */
  nextDepartures: { [key: string]: Array<DepartureModel> }
  /**
   *
   * @type {string}
   * @memberof UtasVaroV5
   */
  serverDateTime?: string
}
/**
 *
 * @export
 * @interface Utasvaro
 */
export interface Utasvaro {
  /**
   *
   * @type {Meta}
   * @memberof Utasvaro
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof Utasvaro
   */
  weatherTemp?: string
  /**
   *
   * @type {string}
   * @memberof Utasvaro
   */
  weatherIcon?: string
  /**
   *
   * @type {{ [key: string]: Array<DepartureModel>; }}
   * @memberof Utasvaro
   */
  nextDepartures: { [key: string]: Array<DepartureModel> }
  /**
   *
   * @type {string}
   * @memberof Utasvaro
   */
  serverDateTime?: string
}
/**
 *
 * @export
 * @interface WakeOnLanRequest
 */
export interface WakeOnLanRequest {
  /**
   *
   * @type {string}
   * @memberof WakeOnLanRequest
   */
  macAddress: string
}
/**
 *
 * @export
 * @interface Warning
 */
export interface Warning {
  /**
   *
   * @type {Meta}
   * @memberof Warning
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof Warning
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof Warning
   */
  details: string
}
/**
 *
 * @export
 * @interface WeatherDataDTO
 */
export interface WeatherDataDTO {
  /**
   *
   * @type {string}
   * @memberof WeatherDataDTO
   */
  temp?: string
  /**
   *
   * @type {string}
   * @memberof WeatherDataDTO
   */
  iconCode?: string
  /**
   *
   * @type {boolean}
   * @memberof WeatherDataDTO
   */
  isDay?: boolean
}
/**
 *
 * @export
 * @interface WeeklyDepartureResponse
 */
export interface WeeklyDepartureResponse {
  /**
   *
   * @type {LocalTime}
   * @memberof WeeklyDepartureResponse
   */
  startTime: LocalTime
  /**
   *
   * @type {Cruise}
   * @memberof WeeklyDepartureResponse
   */
  cruise: Cruise
  /**
   *
   * @type {boolean}
   * @memberof WeeklyDepartureResponse
   */
  isAllPublished?: boolean
  /**
   *
   * @type {string}
   * @memberof WeeklyDepartureResponse
   */
  allGate?: WeeklyDepartureResponseAllGateEnum
  /**
   *
   * @type {{ [key: string]: DepartureModel; }}
   * @memberof WeeklyDepartureResponse
   */
  departuresByDay: { [key: string]: DepartureModel }
}

/**
 * @export
 * @enum {string}
 */
export enum WeeklyDepartureResponseAllGateEnum {
  GATE1 = 'GATE1',
  GATE2 = 'GATE2',
  GATE3 = 'GATE3',
}

/**
 * AppSettingResourceControllerApi - axios parameter creator
 * @export
 */
export const AppSettingResourceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Applikáció beállítások listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppSettings: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/app-settings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary EUR árfolyam beállítások
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExchangeRateSettings: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/app-settings/exchrate-settings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Applikáció beállítások frissítése
     * @param {AppSettingUpdateRequest} appSettingUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppSettings: async (
      appSettingUpdateRequest: AppSettingUpdateRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appSettingUpdateRequest' is not null or undefined
      assertParamExists('updateAppSettings', 'appSettingUpdateRequest', appSettingUpdateRequest)
      const localVarPath = `/api/v1.0/admin/app-settings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        appSettingUpdateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary EUR árfolyam frissítése
     * @param {UpdateEURExchangeRateRequest} updateEURExchangeRateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEURExchangeRate: async (
      updateEURExchangeRateRequest: UpdateEURExchangeRateRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateEURExchangeRateRequest' is not null or undefined
      assertParamExists(
        'updateEURExchangeRate',
        'updateEURExchangeRateRequest',
        updateEURExchangeRateRequest,
      )
      const localVarPath = `/api/v1.0/admin/app-settings/exchrate`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateEURExchangeRateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AppSettingResourceControllerApi - functional programming interface
 * @export
 */
export const AppSettingResourceControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AppSettingResourceControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Applikáció beállítások listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppSettings(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppSettingDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSettings(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary EUR árfolyam beállítások
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExchangeRateSettings(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeSettingsDTOResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeRateSettings(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Applikáció beállítások frissítése
     * @param {AppSettingUpdateRequest} appSettingUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAppSettings(
      appSettingUpdateRequest: AppSettingUpdateRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppSettings(
        appSettingUpdateRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary EUR árfolyam frissítése
     * @param {UpdateEURExchangeRateRequest} updateEURExchangeRateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEURExchangeRate(
      updateEURExchangeRateRequest: UpdateEURExchangeRateRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEURExchangeRate(
        updateEURExchangeRateRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * AppSettingResourceControllerApi - factory interface
 * @export
 */
export const AppSettingResourceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppSettingResourceControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Applikáció beállítások listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppSettings(options?: any): AxiosPromise<Array<AppSettingDTO>> {
      return localVarFp.getAppSettings(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary EUR árfolyam beállítások
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExchangeRateSettings(options?: any): AxiosPromise<ExchangeSettingsDTOResponse> {
      return localVarFp.getExchangeRateSettings(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Applikáció beállítások frissítése
     * @param {AppSettingUpdateRequest} appSettingUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppSettings(
      appSettingUpdateRequest: AppSettingUpdateRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateAppSettings(appSettingUpdateRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary EUR árfolyam frissítése
     * @param {UpdateEURExchangeRateRequest} updateEURExchangeRateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEURExchangeRate(
      updateEURExchangeRateRequest: UpdateEURExchangeRateRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateEURExchangeRate(updateEURExchangeRateRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AppSettingResourceControllerApi - object-oriented interface
 * @export
 * @class AppSettingResourceControllerApi
 * @extends {BaseAPI}
 */
export class AppSettingResourceControllerApi extends BaseAPI {
  /**
   *
   * @summary Applikáció beállítások listája
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppSettingResourceControllerApi
   */
  public getAppSettings(options?: any) {
    return AppSettingResourceControllerApiFp(this.configuration)
      .getAppSettings(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary EUR árfolyam beállítások
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppSettingResourceControllerApi
   */
  public getExchangeRateSettings(options?: any) {
    return AppSettingResourceControllerApiFp(this.configuration)
      .getExchangeRateSettings(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Applikáció beállítások frissítése
   * @param {AppSettingUpdateRequest} appSettingUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppSettingResourceControllerApi
   */
  public updateAppSettings(appSettingUpdateRequest: AppSettingUpdateRequest, options?: any) {
    return AppSettingResourceControllerApiFp(this.configuration)
      .updateAppSettings(appSettingUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary EUR árfolyam frissítése
   * @param {UpdateEURExchangeRateRequest} updateEURExchangeRateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppSettingResourceControllerApi
   */
  public updateEURExchangeRate(
    updateEURExchangeRateRequest: UpdateEURExchangeRateRequest,
    options?: any,
  ) {
    return AppSettingResourceControllerApiFp(this.configuration)
      .updateEURExchangeRate(updateEURExchangeRateRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ContentAdminControllerApi - axios parameter creator
 * @export
 */
export const ContentAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary SUM: Futtatható tartalmak listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRunnableContentList: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/contents`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContentAdminControllerApi - functional programming interface
 * @export
 */
export const ContentAdminControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContentAdminControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary SUM: Futtatható tartalmak listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRunnableContentList(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RunnableContentDTO>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRunnableContentList(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ContentAdminControllerApi - factory interface
 * @export
 */
export const ContentAdminControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContentAdminControllerApiFp(configuration)
  return {
    /**
     *
     * @summary SUM: Futtatható tartalmak listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRunnableContentList(options?: any): AxiosPromise<Array<RunnableContentDTO>> {
      return localVarFp.getRunnableContentList(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * ContentAdminControllerApi - object-oriented interface
 * @export
 * @class ContentAdminControllerApi
 * @extends {BaseAPI}
 */
export class ContentAdminControllerApi extends BaseAPI {
  /**
   *
   * @summary SUM: Futtatható tartalmak listája
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentAdminControllerApi
   */
  public getRunnableContentList(options?: any) {
    return ContentAdminControllerApiFp(this.configuration)
      .getRunnableContentList(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * InformAdminControllerApi - axios parameter creator
 * @export
 */
export const InformAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Infó/aktualitás létrehozása, frissítése
     * @param {CreateUpdateInformRequest} createUpdateInformRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUpdateInform: async (
      createUpdateInformRequest: CreateUpdateInformRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUpdateInformRequest' is not null or undefined
      assertParamExists(
        'createUpdateInform',
        'createUpdateInformRequest',
        createUpdateInformRequest,
      )
      const localVarPath = `/api/v1.0/admin/inform`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUpdateInformRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Info/Aktualitas törlése
     * @param {DeleteInformRequest} deleteInformRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInformById: async (
      deleteInformRequest: DeleteInformRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteInformRequest' is not null or undefined
      assertParamExists('deleteInformById', 'deleteInformRequest', deleteInformRequest)
      const localVarPath = `/api/v1.0/admin/inform/delete`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteInformRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Infó lista lekérése szűrve
     * @param {SearchInformRequest} searchInformRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchInform: async (
      searchInformRequest: SearchInformRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'searchInformRequest' is not null or undefined
      assertParamExists('searchInform', 'searchInformRequest', searchInformRequest)
      const localVarPath = `/api/v1.0/admin/inform/search`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchInformRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Aktualitás hozzárendelése tartalomhoz
     * @param {SendActualityToContentRequest} sendActualityToContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendActualityToContent: async (
      sendActualityToContentRequest: SendActualityToContentRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendActualityToContentRequest' is not null or undefined
      assertParamExists(
        'sendActualityToContent',
        'sendActualityToContentRequest',
        sendActualityToContentRequest,
      )
      const localVarPath = `/api/v1.0/admin/inform/send-to-content`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendActualityToContentRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Infó hozzárendelése kijelzőhöz
     * @param {SendInformToScreenRequest} sendInformToScreenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendInfoToScreen: async (
      sendInformToScreenRequest: SendInformToScreenRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendInformToScreenRequest' is not null or undefined
      assertParamExists('sendInfoToScreen', 'sendInformToScreenRequest', sendInformToScreenRequest)
      const localVarPath = `/api/v1.0/admin/inform/send-to-screen`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendInformToScreenRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * InformAdminControllerApi - functional programming interface
 * @export
 */
export const InformAdminControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InformAdminControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Infó/aktualitás létrehozása, frissítése
     * @param {CreateUpdateInformRequest} createUpdateInformRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUpdateInform(
      createUpdateInformRequest: CreateUpdateInformRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUpdateInform(
        createUpdateInformRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Info/Aktualitas törlése
     * @param {DeleteInformRequest} deleteInformRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInformById(
      deleteInformRequest: DeleteInformRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInformById(
        deleteInformRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Infó lista lekérése szűrve
     * @param {SearchInformRequest} searchInformRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchInform(
      searchInformRequest: SearchInformRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InformModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchInform(
        searchInformRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Aktualitás hozzárendelése tartalomhoz
     * @param {SendActualityToContentRequest} sendActualityToContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendActualityToContent(
      sendActualityToContentRequest: SendActualityToContentRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendActualityToContent(
        sendActualityToContentRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Infó hozzárendelése kijelzőhöz
     * @param {SendInformToScreenRequest} sendInformToScreenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendInfoToScreen(
      sendInformToScreenRequest: SendInformToScreenRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendInfoToScreen(
        sendInformToScreenRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * InformAdminControllerApi - factory interface
 * @export
 */
export const InformAdminControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InformAdminControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Infó/aktualitás létrehozása, frissítése
     * @param {CreateUpdateInformRequest} createUpdateInformRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUpdateInform(
      createUpdateInformRequest: CreateUpdateInformRequest,
      options?: any,
    ): AxiosPromise<InformModel> {
      return localVarFp
        .createUpdateInform(createUpdateInformRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Info/Aktualitas törlése
     * @param {DeleteInformRequest} deleteInformRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInformById(deleteInformRequest: DeleteInformRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteInformById(deleteInformRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Infó lista lekérése szűrve
     * @param {SearchInformRequest} searchInformRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchInform(
      searchInformRequest: SearchInformRequest,
      options?: any,
    ): AxiosPromise<Array<InformModel>> {
      return localVarFp
        .searchInform(searchInformRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Aktualitás hozzárendelése tartalomhoz
     * @param {SendActualityToContentRequest} sendActualityToContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendActualityToContent(
      sendActualityToContentRequest: SendActualityToContentRequest,
      options?: any,
    ): AxiosPromise<InformModel> {
      return localVarFp
        .sendActualityToContent(sendActualityToContentRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Infó hozzárendelése kijelzőhöz
     * @param {SendInformToScreenRequest} sendInformToScreenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendInfoToScreen(
      sendInformToScreenRequest: SendInformToScreenRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .sendInfoToScreen(sendInformToScreenRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * InformAdminControllerApi - object-oriented interface
 * @export
 * @class InformAdminControllerApi
 * @extends {BaseAPI}
 */
export class InformAdminControllerApi extends BaseAPI {
  /**
   *
   * @summary Infó/aktualitás létrehozása, frissítése
   * @param {CreateUpdateInformRequest} createUpdateInformRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InformAdminControllerApi
   */
  public createUpdateInform(createUpdateInformRequest: CreateUpdateInformRequest, options?: any) {
    return InformAdminControllerApiFp(this.configuration)
      .createUpdateInform(createUpdateInformRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Info/Aktualitas törlése
   * @param {DeleteInformRequest} deleteInformRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InformAdminControllerApi
   */
  public deleteInformById(deleteInformRequest: DeleteInformRequest, options?: any) {
    return InformAdminControllerApiFp(this.configuration)
      .deleteInformById(deleteInformRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Infó lista lekérése szűrve
   * @param {SearchInformRequest} searchInformRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InformAdminControllerApi
   */
  public searchInform(searchInformRequest: SearchInformRequest, options?: any) {
    return InformAdminControllerApiFp(this.configuration)
      .searchInform(searchInformRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Aktualitás hozzárendelése tartalomhoz
   * @param {SendActualityToContentRequest} sendActualityToContentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InformAdminControllerApi
   */
  public sendActualityToContent(
    sendActualityToContentRequest: SendActualityToContentRequest,
    options?: any,
  ) {
    return InformAdminControllerApiFp(this.configuration)
      .sendActualityToContent(sendActualityToContentRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Infó hozzárendelése kijelzőhöz
   * @param {SendInformToScreenRequest} sendInformToScreenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InformAdminControllerApi
   */
  public sendInfoToScreen(sendInformToScreenRequest: SendInformToScreenRequest, options?: any) {
    return InformAdminControllerApiFp(this.configuration)
      .sendInfoToScreen(sendInformToScreenRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * MediaFileControllerApi - axios parameter creator
 * @export
 */
export const MediaFileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Média fájl hozzáadása
     * @param {CreateUpdateMediaFileRequest} createUpdateMediaFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMediaFile: async (
      createUpdateMediaFileRequest: CreateUpdateMediaFileRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUpdateMediaFileRequest' is not null or undefined
      assertParamExists(
        'createMediaFile',
        'createUpdateMediaFileRequest',
        createUpdateMediaFileRequest,
      )
      const localVarPath = `/api/v1.0/admin/mediafiles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUpdateMediaFileRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Média fájlok listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/mediafiles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Média fájl módosítása
     * @param {CreateUpdateMediaFileRequest} createUpdateMediaFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMediaFile: async (
      createUpdateMediaFileRequest: CreateUpdateMediaFileRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUpdateMediaFileRequest' is not null or undefined
      assertParamExists(
        'updateMediaFile',
        'createUpdateMediaFileRequest',
        createUpdateMediaFileRequest,
      )
      const localVarPath = `/api/v1.0/admin/mediafiles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUpdateMediaFileRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Média fájl feltöltése
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile: async (inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/mediafiles/upload`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * MediaFileControllerApi - functional programming interface
 * @export
 */
export const MediaFileControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MediaFileControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Média fájl hozzáadása
     * @param {CreateUpdateMediaFileRequest} createUpdateMediaFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMediaFile(
      createUpdateMediaFileRequest: CreateUpdateMediaFileRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaFileDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMediaFile(
        createUpdateMediaFileRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Média fájlok listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaFileDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Média fájl módosítása
     * @param {CreateUpdateMediaFileRequest} createUpdateMediaFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMediaFile(
      createUpdateMediaFileRequest: CreateUpdateMediaFileRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaFileDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMediaFile(
        createUpdateMediaFileRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Média fájl feltöltése
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFile(
      inlineObject?: InlineObject,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(inlineObject, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * MediaFileControllerApi - factory interface
 * @export
 */
export const MediaFileControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MediaFileControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Média fájl hozzáadása
     * @param {CreateUpdateMediaFileRequest} createUpdateMediaFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMediaFile(
      createUpdateMediaFileRequest: CreateUpdateMediaFileRequest,
      options?: any,
    ): AxiosPromise<MediaFileDTO> {
      return localVarFp
        .createMediaFile(createUpdateMediaFileRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Média fájlok listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(options?: any): AxiosPromise<Array<MediaFileDTO>> {
      return localVarFp.getAll(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Média fájl módosítása
     * @param {CreateUpdateMediaFileRequest} createUpdateMediaFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMediaFile(
      createUpdateMediaFileRequest: CreateUpdateMediaFileRequest,
      options?: any,
    ): AxiosPromise<MediaFileDTO> {
      return localVarFp
        .updateMediaFile(createUpdateMediaFileRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Média fájl feltöltése
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(inlineObject?: InlineObject, options?: any): AxiosPromise<UploadFileResponse> {
      return localVarFp
        .uploadFile(inlineObject, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * MediaFileControllerApi - object-oriented interface
 * @export
 * @class MediaFileControllerApi
 * @extends {BaseAPI}
 */
export class MediaFileControllerApi extends BaseAPI {
  /**
   *
   * @summary Média fájl hozzáadása
   * @param {CreateUpdateMediaFileRequest} createUpdateMediaFileRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaFileControllerApi
   */
  public createMediaFile(
    createUpdateMediaFileRequest: CreateUpdateMediaFileRequest,
    options?: any,
  ) {
    return MediaFileControllerApiFp(this.configuration)
      .createMediaFile(createUpdateMediaFileRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Média fájlok listája
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaFileControllerApi
   */
  public getAll(options?: any) {
    return MediaFileControllerApiFp(this.configuration)
      .getAll(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Média fájl módosítása
   * @param {CreateUpdateMediaFileRequest} createUpdateMediaFileRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaFileControllerApi
   */
  public updateMediaFile(
    createUpdateMediaFileRequest: CreateUpdateMediaFileRequest,
    options?: any,
  ) {
    return MediaFileControllerApiFp(this.configuration)
      .updateMediaFile(createUpdateMediaFileRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Média fájl feltöltése
   * @param {InlineObject} [inlineObject]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaFileControllerApi
   */
  public uploadFile(inlineObject?: InlineObject, options?: any) {
    return MediaFileControllerApiFp(this.configuration)
      .uploadFile(inlineObject, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ScreenResourceAdminControllerApi - axios parameter creator
 * @export
 */
export const ScreenResourceAdminControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Generate fresh static cache buster string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewCacheBuster: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/screens/refresh-cachebuster`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Kijelzők listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScreens: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/screens`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Kijelző átnevezése
     * @param {RenameScreenRequest} renameScreenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameScreen1: async (
      renameScreenRequest: RenameScreenRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'renameScreenRequest' is not null or undefined
      assertParamExists('renameScreen1', 'renameScreenRequest', renameScreenRequest)
      const localVarPath = `/api/v1.0/admin/screens/rename`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        renameScreenRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Kijelző tartalom beállítása
     * @param {ScreenContentSetRequest} screenContentSetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setScreenContent: async (
      screenContentSetRequest: ScreenContentSetRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'screenContentSetRequest' is not null or undefined
      assertParamExists('setScreenContent', 'screenContentSetRequest', screenContentSetRequest)
      const localVarPath = `/api/v1.0/admin/screens/set-content`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        screenContentSetRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Összes kijelző bekapcsolása
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wakeUpAllScreen: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/screens/wake-all`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Kijelzők bekapcsolása MAC cím szerint
     * @param {WakeOnLanRequest} wakeOnLanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wakeUpByMAC: async (
      wakeOnLanRequest: WakeOnLanRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'wakeOnLanRequest' is not null or undefined
      assertParamExists('wakeUpByMAC', 'wakeOnLanRequest', wakeOnLanRequest)
      const localVarPath = `/api/v1.0/admin/screens/wake-by-mac`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        wakeOnLanRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ScreenResourceAdminControllerApi - functional programming interface
 * @export
 */
export const ScreenResourceAdminControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ScreenResourceAdminControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Generate fresh static cache buster string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNewCacheBuster(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNewCacheBuster(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Kijelzők listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScreens(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScreenModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScreens(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Kijelző átnevezése
     * @param {RenameScreenRequest} renameScreenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async renameScreen1(
      renameScreenRequest: RenameScreenRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.renameScreen1(
        renameScreenRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Kijelző tartalom beállítása
     * @param {ScreenContentSetRequest} screenContentSetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setScreenContent(
      screenContentSetRequest: ScreenContentSetRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setScreenContent(
        screenContentSetRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Összes kijelző bekapcsolása
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wakeUpAllScreen(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wakeUpAllScreen(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Kijelzők bekapcsolása MAC cím szerint
     * @param {WakeOnLanRequest} wakeOnLanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wakeUpByMAC(
      wakeOnLanRequest: WakeOnLanRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wakeUpByMAC(
        wakeOnLanRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ScreenResourceAdminControllerApi - factory interface
 * @export
 */
export const ScreenResourceAdminControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ScreenResourceAdminControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Generate fresh static cache buster string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewCacheBuster(options?: any): AxiosPromise<void> {
      return localVarFp.getNewCacheBuster(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Kijelzők listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScreens(options?: any): AxiosPromise<Array<ScreenModel>> {
      return localVarFp.getScreens(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Kijelző átnevezése
     * @param {RenameScreenRequest} renameScreenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameScreen1(renameScreenRequest: RenameScreenRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .renameScreen1(renameScreenRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Kijelző tartalom beállítása
     * @param {ScreenContentSetRequest} screenContentSetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setScreenContent(
      screenContentSetRequest: ScreenContentSetRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .setScreenContent(screenContentSetRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Összes kijelző bekapcsolása
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wakeUpAllScreen(options?: any): AxiosPromise<void> {
      return localVarFp.wakeUpAllScreen(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Kijelzők bekapcsolása MAC cím szerint
     * @param {WakeOnLanRequest} wakeOnLanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wakeUpByMAC(wakeOnLanRequest: WakeOnLanRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .wakeUpByMAC(wakeOnLanRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ScreenResourceAdminControllerApi - object-oriented interface
 * @export
 * @class ScreenResourceAdminControllerApi
 * @extends {BaseAPI}
 */
export class ScreenResourceAdminControllerApi extends BaseAPI {
  /**
   *
   * @summary Generate fresh static cache buster string
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreenResourceAdminControllerApi
   */
  public getNewCacheBuster(options?: any) {
    return ScreenResourceAdminControllerApiFp(this.configuration)
      .getNewCacheBuster(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Kijelzők listája
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreenResourceAdminControllerApi
   */
  public getScreens(options?: any) {
    return ScreenResourceAdminControllerApiFp(this.configuration)
      .getScreens(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Kijelző átnevezése
   * @param {RenameScreenRequest} renameScreenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreenResourceAdminControllerApi
   */
  public renameScreen1(renameScreenRequest: RenameScreenRequest, options?: any) {
    return ScreenResourceAdminControllerApiFp(this.configuration)
      .renameScreen1(renameScreenRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Kijelző tartalom beállítása
   * @param {ScreenContentSetRequest} screenContentSetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreenResourceAdminControllerApi
   */
  public setScreenContent(screenContentSetRequest: ScreenContentSetRequest, options?: any) {
    return ScreenResourceAdminControllerApiFp(this.configuration)
      .setScreenContent(screenContentSetRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Összes kijelző bekapcsolása
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreenResourceAdminControllerApi
   */
  public wakeUpAllScreen(options?: any) {
    return ScreenResourceAdminControllerApiFp(this.configuration)
      .wakeUpAllScreen(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Kijelzők bekapcsolása MAC cím szerint
   * @param {WakeOnLanRequest} wakeOnLanRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreenResourceAdminControllerApi
   */
  public wakeUpByMAC(wakeOnLanRequest: WakeOnLanRequest, options?: any) {
    return ScreenResourceAdminControllerApiFp(this.configuration)
      .wakeUpByMAC(wakeOnLanRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ScreenResourceControllerApi - axios parameter creator
 * @export
 */
export const ScreenResourceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Kijelző regisztrálása, újra meghívható
     * @param {DeviceRegistrationRequest} deviceRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerScreen: async (
      deviceRegistrationRequest: DeviceRegistrationRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceRegistrationRequest' is not null or undefined
      assertParamExists('registerScreen', 'deviceRegistrationRequest', deviceRegistrationRequest)
      const localVarPath = `/api/v1.0/screens/register`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        deviceRegistrationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ScreenResourceControllerApi - functional programming interface
 * @export
 */
export const ScreenResourceControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ScreenResourceControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Kijelző regisztrálása, újra meghívható
     * @param {DeviceRegistrationRequest} deviceRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerScreen(
      deviceRegistrationRequest: DeviceRegistrationRequest,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScreenRegistrationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registerScreen(
        deviceRegistrationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ScreenResourceControllerApi - factory interface
 * @export
 */
export const ScreenResourceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ScreenResourceControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Kijelző regisztrálása, újra meghívható
     * @param {DeviceRegistrationRequest} deviceRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerScreen(
      deviceRegistrationRequest: DeviceRegistrationRequest,
      options?: any,
    ): AxiosPromise<ScreenRegistrationResponse> {
      return localVarFp
        .registerScreen(deviceRegistrationRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ScreenResourceControllerApi - object-oriented interface
 * @export
 * @class ScreenResourceControllerApi
 * @extends {BaseAPI}
 */
export class ScreenResourceControllerApi extends BaseAPI {
  /**
   *
   * @summary Kijelző regisztrálása, újra meghívható
   * @param {DeviceRegistrationRequest} deviceRegistrationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreenResourceControllerApi
   */
  public registerScreen(deviceRegistrationRequest: DeviceRegistrationRequest, options?: any) {
    return ScreenResourceControllerApiFp(this.configuration)
      .registerScreen(deviceRegistrationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SecurityControllerApi - axios parameter creator
 * @export
 */
export const SecurityControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Jogkör létrehozása
     * @param {CreateRoleRequest} createRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRole: async (
      createRoleRequest: CreateRoleRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRoleRequest' is not null or undefined
      assertParamExists('createRole', 'createRoleRequest', createRoleRequest)
      const localVarPath = `/api/v1.0/admin/roles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRoleRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jogkör törlése
     * @param {DeleteRoleRequest} deleteRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRole: async (
      deleteRoleRequest: DeleteRoleRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteRoleRequest' is not null or undefined
      assertParamExists('deleteRole', 'deleteRoleRequest', deleteRoleRequest)
      const localVarPath = `/api/v1.0/admin/roles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteRoleRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jogkör lista lekérdezése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAuthorities: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/authorities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jogkör lista lekérdezése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRoles: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/roles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Felhasználók listája
     * @param {UsersReqest} usersReqest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers: async (usersReqest: UsersReqest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'usersReqest' is not null or undefined
      assertParamExists('getUsers', 'usersReqest', usersReqest)
      const localVarPath = `/api/v1.0/admin/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        usersReqest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jogkör hozzárendelése felhasználóhoz
     * @param {string} username
     * @param {GrantRoleRequest} grantRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    grantRoles: async (
      username: string,
      grantRoleRequest: GrantRoleRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('grantRoles', 'username', username)
      // verify required parameter 'grantRoleRequest' is not null or undefined
      assertParamExists('grantRoles', 'grantRoleRequest', grantRoleRequest)
      const localVarPath = `/api/v1.0/admin/users/{username}/grant-roles`.replace(
        `{${'username'}}`,
        encodeURIComponent(String(username)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        grantRoleRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Bejelentkezés
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'loginRequest' is not null or undefined
      assertParamExists('login', 'loginRequest', loginRequest)
      const localVarPath = `/api/v1.0/auth/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Token frissítése
     * @param {TokenRefreshRequest} tokenRefreshRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshtoken: async (
      tokenRefreshRequest: TokenRefreshRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenRefreshRequest' is not null or undefined
      assertParamExists('refreshtoken', 'tokenRefreshRequest', tokenRefreshRequest)
      const localVarPath = `/api/v1.0/auth/refreshtoken`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tokenRefreshRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jogok beállítása egy jogkörhöz
     * @param {SetPrivilegesRequest} setPrivilegesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setPrivilegesToRole: async (
      setPrivilegesRequest: SetPrivilegesRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'setPrivilegesRequest' is not null or undefined
      assertParamExists('setPrivilegesToRole', 'setPrivilegesRequest', setPrivilegesRequest)
      const localVarPath = `/api/v1.0/admin/roles/add-privileges`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        setPrivilegesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Felhasználó engedélyezése
     * @param {string} username
     * @param {UserEnabledReqest} userEnabledReqest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setUsersEnabled: async (
      username: string,
      userEnabledReqest: UserEnabledReqest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('setUsersEnabled', 'username', username)
      // verify required parameter 'userEnabledReqest' is not null or undefined
      assertParamExists('setUsersEnabled', 'userEnabledReqest', userEnabledReqest)
      const localVarPath = `/api/v1.0/admin/users/{username}/enabled`.replace(
        `{${'username'}}`,
        encodeURIComponent(String(username)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userEnabledReqest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SecurityControllerApi - functional programming interface
 * @export
 */
export const SecurityControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SecurityControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Jogkör létrehozása
     * @param {CreateRoleRequest} createRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRole(
      createRoleRequest: CreateRoleRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(
        createRoleRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jogkör törlése
     * @param {DeleteRoleRequest} deleteRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRole(
      deleteRoleRequest: DeleteRoleRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(
        deleteRoleRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jogkör lista lekérdezése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAuthorities(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthorityModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAuthorities(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jogkör lista lekérdezése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllRoles(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRoles(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Felhasználók listája
     * @param {UsersReqest} usersReqest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsers(
      usersReqest: UsersReqest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(usersReqest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jogkör hozzárendelése felhasználóhoz
     * @param {string} username
     * @param {GrantRoleRequest} grantRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async grantRoles(
      username: string,
      grantRoleRequest: GrantRoleRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.grantRoles(
        username,
        grantRoleRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Bejelentkezés
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      loginRequest: LoginRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Token frissítése
     * @param {TokenRefreshRequest} tokenRefreshRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshtoken(
      tokenRefreshRequest: TokenRefreshRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefreshResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshtoken(
        tokenRefreshRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jogok beállítása egy jogkörhöz
     * @param {SetPrivilegesRequest} setPrivilegesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setPrivilegesToRole(
      setPrivilegesRequest: SetPrivilegesRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setPrivilegesToRole(
        setPrivilegesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Felhasználó engedélyezése
     * @param {string} username
     * @param {UserEnabledReqest} userEnabledReqest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setUsersEnabled(
      username: string,
      userEnabledReqest: UserEnabledReqest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setUsersEnabled(
        username,
        userEnabledReqest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SecurityControllerApi - factory interface
 * @export
 */
export const SecurityControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SecurityControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Jogkör létrehozása
     * @param {CreateRoleRequest} createRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRole(createRoleRequest: CreateRoleRequest, options?: any): AxiosPromise<RoleModel> {
      return localVarFp
        .createRole(createRoleRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jogkör törlése
     * @param {DeleteRoleRequest} deleteRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRole(deleteRoleRequest: DeleteRoleRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteRole(deleteRoleRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jogkör lista lekérdezése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAuthorities(options?: any): AxiosPromise<Array<AuthorityModel>> {
      return localVarFp.getAllAuthorities(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jogkör lista lekérdezése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRoles(options?: any): AxiosPromise<Array<RoleModel>> {
      return localVarFp.getAllRoles(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Felhasználók listája
     * @param {UsersReqest} usersReqest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(usersReqest: UsersReqest, options?: any): AxiosPromise<Array<UserDto>> {
      return localVarFp.getUsers(usersReqest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jogkör hozzárendelése felhasználóhoz
     * @param {string} username
     * @param {GrantRoleRequest} grantRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    grantRoles(
      username: string,
      grantRoleRequest: GrantRoleRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .grantRoles(username, grantRoleRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Bejelentkezés
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(loginRequest: LoginRequest, options?: any): AxiosPromise<JwtResponse> {
      return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Token frissítése
     * @param {TokenRefreshRequest} tokenRefreshRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshtoken(
      tokenRefreshRequest: TokenRefreshRequest,
      options?: any,
    ): AxiosPromise<TokenRefreshResponse> {
      return localVarFp
        .refreshtoken(tokenRefreshRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jogok beállítása egy jogkörhöz
     * @param {SetPrivilegesRequest} setPrivilegesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setPrivilegesToRole(
      setPrivilegesRequest: SetPrivilegesRequest,
      options?: any,
    ): AxiosPromise<RoleModel> {
      return localVarFp
        .setPrivilegesToRole(setPrivilegesRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Felhasználó engedélyezése
     * @param {string} username
     * @param {UserEnabledReqest} userEnabledReqest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setUsersEnabled(
      username: string,
      userEnabledReqest: UserEnabledReqest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .setUsersEnabled(username, userEnabledReqest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * SecurityControllerApi - object-oriented interface
 * @export
 * @class SecurityControllerApi
 * @extends {BaseAPI}
 */
export class SecurityControllerApi extends BaseAPI {
  /**
   *
   * @summary Jogkör létrehozása
   * @param {CreateRoleRequest} createRoleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public createRole(createRoleRequest: CreateRoleRequest, options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .createRole(createRoleRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jogkör törlése
   * @param {DeleteRoleRequest} deleteRoleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public deleteRole(deleteRoleRequest: DeleteRoleRequest, options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .deleteRole(deleteRoleRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jogkör lista lekérdezése
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public getAllAuthorities(options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .getAllAuthorities(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jogkör lista lekérdezése
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public getAllRoles(options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .getAllRoles(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Felhasználók listája
   * @param {UsersReqest} usersReqest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public getUsers(usersReqest: UsersReqest, options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .getUsers(usersReqest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jogkör hozzárendelése felhasználóhoz
   * @param {string} username
   * @param {GrantRoleRequest} grantRoleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public grantRoles(username: string, grantRoleRequest: GrantRoleRequest, options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .grantRoles(username, grantRoleRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Bejelentkezés
   * @param {LoginRequest} loginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public login(loginRequest: LoginRequest, options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .login(loginRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Token frissítése
   * @param {TokenRefreshRequest} tokenRefreshRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public refreshtoken(tokenRefreshRequest: TokenRefreshRequest, options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .refreshtoken(tokenRefreshRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jogok beállítása egy jogkörhöz
   * @param {SetPrivilegesRequest} setPrivilegesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public setPrivilegesToRole(setPrivilegesRequest: SetPrivilegesRequest, options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .setPrivilegesToRole(setPrivilegesRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Felhasználó engedélyezése
   * @param {string} username
   * @param {UserEnabledReqest} userEnabledReqest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityControllerApi
   */
  public setUsersEnabled(username: string, userEnabledReqest: UserEnabledReqest, options?: any) {
    return SecurityControllerApiFp(this.configuration)
      .setUsersEnabled(username, userEnabledReqest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SimpleLoginControllerApi - axios parameter creator
 * @export
 */
export const SimpleLoginControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Bejelentkezés
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameScreen: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'loginRequest' is not null or undefined
      assertParamExists('renameScreen', 'loginRequest', loginRequest)
      const localVarPath = `/api/v1.0/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SimpleLoginControllerApi - functional programming interface
 * @export
 */
export const SimpleLoginControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SimpleLoginControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Bejelentkezés
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async renameScreen(
      loginRequest: LoginRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegendaLoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.renameScreen(loginRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SimpleLoginControllerApi - factory interface
 * @export
 */
export const SimpleLoginControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SimpleLoginControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Bejelentkezés
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameScreen(loginRequest: LoginRequest, options?: any): AxiosPromise<LegendaLoginResponse> {
      return localVarFp
        .renameScreen(loginRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * SimpleLoginControllerApi - object-oriented interface
 * @export
 * @class SimpleLoginControllerApi
 * @extends {BaseAPI}
 */
export class SimpleLoginControllerApi extends BaseAPI {
  /**
   *
   * @summary Bejelentkezés
   * @param {LoginRequest} loginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimpleLoginControllerApi
   */
  public renameScreen(loginRequest: LoginRequest, options?: any) {
    return SimpleLoginControllerApiFp(this.configuration)
      .renameScreen(loginRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TicketPriceResourceControllerApi - axios parameter creator
 * @export
 */
export const TicketPriceResourceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Új jegyár hozzáadása
     * @param {TicketPriceCreateRequest} ticketPriceCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTicketPrice: async (
      ticketPriceCreateRequest: TicketPriceCreateRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketPriceCreateRequest' is not null or undefined
      assertParamExists('createTicketPrice', 'ticketPriceCreateRequest', ticketPriceCreateRequest)
      const localVarPath = `/api/v1.0/admin/ticketprices`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        ticketPriceCreateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jegyárak törlése
     * @param {TicketPricesDeleteRequest} ticketPricesDeleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTicketPrices: async (
      ticketPricesDeleteRequest: TicketPricesDeleteRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketPricesDeleteRequest' is not null or undefined
      assertParamExists(
        'deleteTicketPrices',
        'ticketPricesDeleteRequest',
        ticketPricesDeleteRequest,
      )
      const localVarPath = `/api/v1.0/admin/ticketprices`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        ticketPricesDeleteRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jegyárak listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTicketPrices: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/ticketprices`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jegyár módosítása
     * @param {TicketPriceUpdateRequest} ticketPriceUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTicketPrice: async (
      ticketPriceUpdateRequest: TicketPriceUpdateRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketPriceUpdateRequest' is not null or undefined
      assertParamExists('updateTicketPrice', 'ticketPriceUpdateRequest', ticketPriceUpdateRequest)
      const localVarPath = `/api/v1.0/admin/ticketprices`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        ticketPriceUpdateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jegyár sorrend módosítása
     * @param {TicketPriceIndexUpdateRequests} ticketPriceIndexUpdateRequests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTicketPriceIndexes: async (
      ticketPriceIndexUpdateRequests: TicketPriceIndexUpdateRequests,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketPriceIndexUpdateRequests' is not null or undefined
      assertParamExists(
        'updateTicketPriceIndexes',
        'ticketPriceIndexUpdateRequests',
        ticketPriceIndexUpdateRequests,
      )
      const localVarPath = `/api/v1.0/admin/ticketprices/sort-order`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        ticketPriceIndexUpdateRequests,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Jegyár státusz módosítása
     * @param {TicketPriceStatusUpdateRequest} ticketPriceStatusUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTicketPriceStatus: async (
      ticketPriceStatusUpdateRequest: TicketPriceStatusUpdateRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketPriceStatusUpdateRequest' is not null or undefined
      assertParamExists(
        'updateTicketPriceStatus',
        'ticketPriceStatusUpdateRequest',
        ticketPriceStatusUpdateRequest,
      )
      const localVarPath = `/api/v1.0/admin/ticketprices/status`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        ticketPriceStatusUpdateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TicketPriceResourceControllerApi - functional programming interface
 * @export
 */
export const TicketPriceResourceControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TicketPriceResourceControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Új jegyár hozzáadása
     * @param {TicketPriceCreateRequest} ticketPriceCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTicketPrice(
      ticketPriceCreateRequest: TicketPriceCreateRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketPriceDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTicketPrice(
        ticketPriceCreateRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jegyárak törlése
     * @param {TicketPricesDeleteRequest} ticketPricesDeleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTicketPrices(
      ticketPricesDeleteRequest: TicketPricesDeleteRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicketPrices(
        ticketPricesDeleteRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jegyárak listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTicketPrices(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketPriceDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketPrices(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jegyár módosítása
     * @param {TicketPriceUpdateRequest} ticketPriceUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTicketPrice(
      ticketPriceUpdateRequest: TicketPriceUpdateRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketPriceDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicketPrice(
        ticketPriceUpdateRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jegyár sorrend módosítása
     * @param {TicketPriceIndexUpdateRequests} ticketPriceIndexUpdateRequests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTicketPriceIndexes(
      ticketPriceIndexUpdateRequests: TicketPriceIndexUpdateRequests,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicketPriceIndexes(
        ticketPriceIndexUpdateRequests,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Jegyár státusz módosítása
     * @param {TicketPriceStatusUpdateRequest} ticketPriceStatusUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTicketPriceStatus(
      ticketPriceStatusUpdateRequest: TicketPriceStatusUpdateRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketPriceDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicketPriceStatus(
        ticketPriceStatusUpdateRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TicketPriceResourceControllerApi - factory interface
 * @export
 */
export const TicketPriceResourceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TicketPriceResourceControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Új jegyár hozzáadása
     * @param {TicketPriceCreateRequest} ticketPriceCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTicketPrice(
      ticketPriceCreateRequest: TicketPriceCreateRequest,
      options?: any,
    ): AxiosPromise<TicketPriceDTO> {
      return localVarFp
        .createTicketPrice(ticketPriceCreateRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jegyárak törlése
     * @param {TicketPricesDeleteRequest} ticketPricesDeleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTicketPrices(
      ticketPricesDeleteRequest: TicketPricesDeleteRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteTicketPrices(ticketPricesDeleteRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jegyárak listája
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTicketPrices(options?: any): AxiosPromise<Array<TicketPriceDTO>> {
      return localVarFp.getTicketPrices(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jegyár módosítása
     * @param {TicketPriceUpdateRequest} ticketPriceUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTicketPrice(
      ticketPriceUpdateRequest: TicketPriceUpdateRequest,
      options?: any,
    ): AxiosPromise<TicketPriceDTO> {
      return localVarFp
        .updateTicketPrice(ticketPriceUpdateRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jegyár sorrend módosítása
     * @param {TicketPriceIndexUpdateRequests} ticketPriceIndexUpdateRequests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTicketPriceIndexes(
      ticketPriceIndexUpdateRequests: TicketPriceIndexUpdateRequests,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateTicketPriceIndexes(ticketPriceIndexUpdateRequests, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Jegyár státusz módosítása
     * @param {TicketPriceStatusUpdateRequest} ticketPriceStatusUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTicketPriceStatus(
      ticketPriceStatusUpdateRequest: TicketPriceStatusUpdateRequest,
      options?: any,
    ): AxiosPromise<TicketPriceDTO> {
      return localVarFp
        .updateTicketPriceStatus(ticketPriceStatusUpdateRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * TicketPriceResourceControllerApi - object-oriented interface
 * @export
 * @class TicketPriceResourceControllerApi
 * @extends {BaseAPI}
 */
export class TicketPriceResourceControllerApi extends BaseAPI {
  /**
   *
   * @summary Új jegyár hozzáadása
   * @param {TicketPriceCreateRequest} ticketPriceCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketPriceResourceControllerApi
   */
  public createTicketPrice(ticketPriceCreateRequest: TicketPriceCreateRequest, options?: any) {
    return TicketPriceResourceControllerApiFp(this.configuration)
      .createTicketPrice(ticketPriceCreateRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jegyárak törlése
   * @param {TicketPricesDeleteRequest} ticketPricesDeleteRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketPriceResourceControllerApi
   */
  public deleteTicketPrices(ticketPricesDeleteRequest: TicketPricesDeleteRequest, options?: any) {
    return TicketPriceResourceControllerApiFp(this.configuration)
      .deleteTicketPrices(ticketPricesDeleteRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jegyárak listája
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketPriceResourceControllerApi
   */
  public getTicketPrices(options?: any) {
    return TicketPriceResourceControllerApiFp(this.configuration)
      .getTicketPrices(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jegyár módosítása
   * @param {TicketPriceUpdateRequest} ticketPriceUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketPriceResourceControllerApi
   */
  public updateTicketPrice(ticketPriceUpdateRequest: TicketPriceUpdateRequest, options?: any) {
    return TicketPriceResourceControllerApiFp(this.configuration)
      .updateTicketPrice(ticketPriceUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jegyár sorrend módosítása
   * @param {TicketPriceIndexUpdateRequests} ticketPriceIndexUpdateRequests
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketPriceResourceControllerApi
   */
  public updateTicketPriceIndexes(
    ticketPriceIndexUpdateRequests: TicketPriceIndexUpdateRequests,
    options?: any,
  ) {
    return TicketPriceResourceControllerApiFp(this.configuration)
      .updateTicketPriceIndexes(ticketPriceIndexUpdateRequests, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Jegyár státusz módosítása
   * @param {TicketPriceStatusUpdateRequest} ticketPriceStatusUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketPriceResourceControllerApi
   */
  public updateTicketPriceStatus(
    ticketPriceStatusUpdateRequest: TicketPriceStatusUpdateRequest,
    options?: any,
  ) {
    return TicketPriceResourceControllerApiFp(this.configuration)
      .updateTicketPriceStatus(ticketPriceStatusUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TimetableAdminControllerApi - axios parameter creator
 * @export
 */
export const TimetableAdminControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Szolgáltatás létrehozása
     * @param {CreateUpdateCruiseRequest} createUpdateCruiseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCruise: async (
      createUpdateCruiseRequest: CreateUpdateCruiseRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUpdateCruiseRequest' is not null or undefined
      assertParamExists('createCruise', 'createUpdateCruiseRequest', createUpdateCruiseRequest)
      const localVarPath = `/api/v1.0/admin/cruises`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUpdateCruiseRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Járatindulások létrehozása a hét bizonyos napjaira
     * @param {AddDepartureRequest} addDepartureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDepartures: async (
      addDepartureRequest: AddDepartureRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addDepartureRequest' is not null or undefined
      assertParamExists('createDepartures', 'addDepartureRequest', addDepartureRequest)
      const localVarPath = `/api/v1.0/admin/departures`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addDepartureRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Szezon létrehozása
     * @param {CreateSeasonRequest} createSeasonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSeason: async (
      createSeasonRequest: CreateSeasonRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSeasonRequest' is not null or undefined
      assertParamExists('createSeason', 'createSeasonRequest', createSeasonRequest)
      const localVarPath = `/api/v1.0/admin/season`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSeasonRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Járatindulások létrehozása a hét bizonyos napjaira
     * @param {AddUniqueDepartureRequest} addUniqueDepartureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUniqueDepartures: async (
      addUniqueDepartureRequest: AddUniqueDepartureRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addUniqueDepartureRequest' is not null or undefined
      assertParamExists(
        'createUniqueDepartures',
        'addUniqueDepartureRequest',
        addUniqueDepartureRequest,
      )
      const localVarPath = `/api/v1.0/admin/departures/unique`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addUniqueDepartureRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Szolgáltatás törlése
     * @param {number} cruiseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCruise: async (cruiseId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'cruiseId' is not null or undefined
      assertParamExists('deleteCruise', 'cruiseId', cruiseId)
      const localVarPath = `/api/v1.0/admin/cruises/{cruiseId}`.replace(
        `{${'cruiseId'}}`,
        encodeURIComponent(String(cruiseId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Szezon törlése
     * @param {number} seasonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSeason: async (seasonId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'seasonId' is not null or undefined
      assertParamExists('deleteSeason', 'seasonId', seasonId)
      const localVarPath = `/api/v1.0/admin/season/{seasonId}`.replace(
        `{${'seasonId'}}`,
        encodeURIComponent(String(seasonId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Szolgáltatás lista lekérése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllCruises: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/cruises`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Járatindulások lekérése adott dátumra
     * @param {DeparturesByDateRequest} departuresByDateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllDeparturesByDate: async (
      departuresByDateRequest: DeparturesByDateRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'departuresByDateRequest' is not null or undefined
      assertParamExists(
        'findAllDeparturesByDate',
        'departuresByDateRequest',
        departuresByDateRequest,
      )
      const localVarPath = `/api/v1.0/admin/departures/by-date`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        departuresByDateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Szinkronizálja a járatindulásokat az adott szezonra, adott évben
     * @param {string} seasonId
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateDeparturesFromSync: async (
      seasonId: string,
      year: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'seasonId' is not null or undefined
      assertParamExists('generateDeparturesFromSync', 'seasonId', seasonId)
      // verify required parameter 'year' is not null or undefined
      assertParamExists('generateDeparturesFromSync', 'year', year)
      const localVarPath = `/api/v1.0/admin/departures/season/{seasonId}/{year}/sync`
        .replace(`{${'seasonId'}}`, encodeURIComponent(String(seasonId)))
        .replace(`{${'year'}}`, encodeURIComponent(String(year)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Minden járatindulás lekérése adott szezonra
     * @param {string} seasonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeparturesBySeason: async (seasonId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'seasonId' is not null or undefined
      assertParamExists('getDeparturesBySeason', 'seasonId', seasonId)
      const localVarPath = `/api/v1.0/admin/departures/season/{seasonId}`.replace(
        `{${'seasonId'}}`,
        encodeURIComponent(String(seasonId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Összekapcsolható szolgáltatások lekérése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInternalCruisesNotMapped: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/cruises/not-mapped-internals`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Szezon lista lekérése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSeasonList: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1.0/admin/season`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Szolgáltatás adatainak frissítése
     * @param {CreateUpdateCruiseRequest} createUpdateCruiseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCruise: async (
      createUpdateCruiseRequest: CreateUpdateCruiseRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUpdateCruiseRequest' is not null or undefined
      assertParamExists('updateCruise', 'createUpdateCruiseRequest', createUpdateCruiseRequest)
      const localVarPath = `/api/v1.0/admin/cruises`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUpdateCruiseRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Járatindulások státusz frissítése
     * @param {UpdateDepartureStatusesRequest} updateDepartureStatusesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDeparturesStatus: async (
      updateDepartureStatusesRequest: UpdateDepartureStatusesRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDepartureStatusesRequest' is not null or undefined
      assertParamExists(
        'updateDeparturesStatus',
        'updateDepartureStatusesRequest',
        updateDepartureStatusesRequest,
      )
      const localVarPath = `/api/v1.0/admin/departures`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDepartureStatusesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Járatindulások kiegészítő infojának frissítése
     * @param {UpdateDeparturesDescriptionRequest} updateDeparturesDescriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDescription: async (
      updateDeparturesDescriptionRequest: UpdateDeparturesDescriptionRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeparturesDescriptionRequest' is not null or undefined
      assertParamExists(
        'updateDescription',
        'updateDeparturesDescriptionRequest',
        updateDeparturesDescriptionRequest,
      )
      const localVarPath = `/api/v1.0/admin/departures/description`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeparturesDescriptionRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Dátumos járatindulások státusz frissítése
     * @param {UpdateUniqueDepartureStatusesRequest} updateUniqueDepartureStatusesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUniqueDeparturesStatus: async (
      updateUniqueDepartureStatusesRequest: UpdateUniqueDepartureStatusesRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUniqueDepartureStatusesRequest' is not null or undefined
      assertParamExists(
        'updateUniqueDeparturesStatus',
        'updateUniqueDepartureStatusesRequest',
        updateUniqueDepartureStatusesRequest,
      )
      const localVarPath = `/api/v1.0/admin/departures/unique`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUniqueDepartureStatusesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Dátumos járatindulás kiegészítő infojának frissítése
     * @param {UpdateUniqueDepartureDescriptionRequest} updateUniqueDepartureDescriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUniqueDescription: async (
      updateUniqueDepartureDescriptionRequest: UpdateUniqueDepartureDescriptionRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUniqueDepartureDescriptionRequest' is not null or undefined
      assertParamExists(
        'updateUniqueDescription',
        'updateUniqueDepartureDescriptionRequest',
        updateUniqueDepartureDescriptionRequest,
      )
      const localVarPath = `/api/v1.0/admin/departures/description-unique`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUniqueDepartureDescriptionRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TimetableAdminControllerApi - functional programming interface
 * @export
 */
export const TimetableAdminControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TimetableAdminControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Szolgáltatás létrehozása
     * @param {CreateUpdateCruiseRequest} createUpdateCruiseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCruise(
      createUpdateCruiseRequest: CreateUpdateCruiseRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cruise>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCruise(
        createUpdateCruiseRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Járatindulások létrehozása a hét bizonyos napjaira
     * @param {AddDepartureRequest} addDepartureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDepartures(
      addDepartureRequest: AddDepartureRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartures(
        addDepartureRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Szezon létrehozása
     * @param {CreateSeasonRequest} createSeasonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSeason(
      createSeasonRequest: CreateSeasonRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeasonModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSeason(
        createSeasonRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Járatindulások létrehozása a hét bizonyos napjaira
     * @param {AddUniqueDepartureRequest} addUniqueDepartureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUniqueDepartures(
      addUniqueDepartureRequest: AddUniqueDepartureRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUniqueDepartures(
        addUniqueDepartureRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Szolgáltatás törlése
     * @param {number} cruiseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCruise(
      cruiseId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCruise(cruiseId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Szezon törlése
     * @param {number} seasonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSeason(
      seasonId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSeason(seasonId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Szolgáltatás lista lekérése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findAllCruises(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Cruise>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findAllCruises(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Járatindulások lekérése adott dátumra
     * @param {DeparturesByDateRequest} departuresByDateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findAllDeparturesByDate(
      departuresByDateRequest: DeparturesByDateRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartureModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findAllDeparturesByDate(
        departuresByDateRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Szinkronizálja a járatindulásokat az adott szezonra, adott évben
     * @param {string} seasonId
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateDeparturesFromSync(
      seasonId: string,
      year: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateDeparturesFromSync(
        seasonId,
        year,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Minden járatindulás lekérése adott szezonra
     * @param {string} seasonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDeparturesBySeason(
      seasonId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WeeklyDepartureResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDeparturesBySeason(
        seasonId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Összekapcsolható szolgáltatások lekérése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInternalCruisesNotMapped(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalCruisesNotMapped(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Szezon lista lekérése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSeasonList(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SeasonModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSeasonList(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Szolgáltatás adatainak frissítése
     * @param {CreateUpdateCruiseRequest} createUpdateCruiseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCruise(
      createUpdateCruiseRequest: CreateUpdateCruiseRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cruise>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCruise(
        createUpdateCruiseRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Járatindulások státusz frissítése
     * @param {UpdateDepartureStatusesRequest} updateDepartureStatusesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDeparturesStatus(
      updateDepartureStatusesRequest: UpdateDepartureStatusesRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeparturesStatus(
        updateDepartureStatusesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Járatindulások kiegészítő infojának frissítése
     * @param {UpdateDeparturesDescriptionRequest} updateDeparturesDescriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDescription(
      updateDeparturesDescriptionRequest: UpdateDeparturesDescriptionRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDescription(
        updateDeparturesDescriptionRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Dátumos járatindulások státusz frissítése
     * @param {UpdateUniqueDepartureStatusesRequest} updateUniqueDepartureStatusesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUniqueDeparturesStatus(
      updateUniqueDepartureStatusesRequest: UpdateUniqueDepartureStatusesRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUniqueDeparturesStatus(
        updateUniqueDepartureStatusesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Dátumos járatindulás kiegészítő infojának frissítése
     * @param {UpdateUniqueDepartureDescriptionRequest} updateUniqueDepartureDescriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUniqueDescription(
      updateUniqueDepartureDescriptionRequest: UpdateUniqueDepartureDescriptionRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUniqueDescription(
        updateUniqueDepartureDescriptionRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TimetableAdminControllerApi - factory interface
 * @export
 */
export const TimetableAdminControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TimetableAdminControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Szolgáltatás létrehozása
     * @param {CreateUpdateCruiseRequest} createUpdateCruiseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCruise(
      createUpdateCruiseRequest: CreateUpdateCruiseRequest,
      options?: any,
    ): AxiosPromise<Cruise> {
      return localVarFp
        .createCruise(createUpdateCruiseRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Járatindulások létrehozása a hét bizonyos napjaira
     * @param {AddDepartureRequest} addDepartureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDepartures(addDepartureRequest: AddDepartureRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .createDepartures(addDepartureRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Szezon létrehozása
     * @param {CreateSeasonRequest} createSeasonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSeason(
      createSeasonRequest: CreateSeasonRequest,
      options?: any,
    ): AxiosPromise<SeasonModel> {
      return localVarFp
        .createSeason(createSeasonRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Járatindulások létrehozása a hét bizonyos napjaira
     * @param {AddUniqueDepartureRequest} addUniqueDepartureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUniqueDepartures(
      addUniqueDepartureRequest: AddUniqueDepartureRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createUniqueDepartures(addUniqueDepartureRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Szolgáltatás törlése
     * @param {number} cruiseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCruise(cruiseId: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCruise(cruiseId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Szezon törlése
     * @param {number} seasonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSeason(seasonId: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteSeason(seasonId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Szolgáltatás lista lekérése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllCruises(options?: any): AxiosPromise<Array<Cruise>> {
      return localVarFp.findAllCruises(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Járatindulások lekérése adott dátumra
     * @param {DeparturesByDateRequest} departuresByDateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllDeparturesByDate(
      departuresByDateRequest: DeparturesByDateRequest,
      options?: any,
    ): AxiosPromise<Array<DepartureModel>> {
      return localVarFp
        .findAllDeparturesByDate(departuresByDateRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Szinkronizálja a járatindulásokat az adott szezonra, adott évben
     * @param {string} seasonId
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateDeparturesFromSync(seasonId: string, year: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .generateDeparturesFromSync(seasonId, year, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Minden járatindulás lekérése adott szezonra
     * @param {string} seasonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeparturesBySeason(
      seasonId: string,
      options?: any,
    ): AxiosPromise<Array<WeeklyDepartureResponse>> {
      return localVarFp
        .getDeparturesBySeason(seasonId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Összekapcsolható szolgáltatások lekérése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInternalCruisesNotMapped(options?: any): AxiosPromise<{ [key: string]: string }> {
      return localVarFp
        .getInternalCruisesNotMapped(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Szezon lista lekérése
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSeasonList(options?: any): AxiosPromise<Array<SeasonModel>> {
      return localVarFp.getSeasonList(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Szolgáltatás adatainak frissítése
     * @param {CreateUpdateCruiseRequest} createUpdateCruiseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCruise(
      createUpdateCruiseRequest: CreateUpdateCruiseRequest,
      options?: any,
    ): AxiosPromise<Cruise> {
      return localVarFp
        .updateCruise(createUpdateCruiseRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Járatindulások státusz frissítése
     * @param {UpdateDepartureStatusesRequest} updateDepartureStatusesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDeparturesStatus(
      updateDepartureStatusesRequest: UpdateDepartureStatusesRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateDeparturesStatus(updateDepartureStatusesRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Járatindulások kiegészítő infojának frissítése
     * @param {UpdateDeparturesDescriptionRequest} updateDeparturesDescriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDescription(
      updateDeparturesDescriptionRequest: UpdateDeparturesDescriptionRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateDescription(updateDeparturesDescriptionRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Dátumos járatindulások státusz frissítése
     * @param {UpdateUniqueDepartureStatusesRequest} updateUniqueDepartureStatusesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUniqueDeparturesStatus(
      updateUniqueDepartureStatusesRequest: UpdateUniqueDepartureStatusesRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUniqueDeparturesStatus(updateUniqueDepartureStatusesRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Dátumos járatindulás kiegészítő infojának frissítése
     * @param {UpdateUniqueDepartureDescriptionRequest} updateUniqueDepartureDescriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUniqueDescription(
      updateUniqueDepartureDescriptionRequest: UpdateUniqueDepartureDescriptionRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUniqueDescription(updateUniqueDepartureDescriptionRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * TimetableAdminControllerApi - object-oriented interface
 * @export
 * @class TimetableAdminControllerApi
 * @extends {BaseAPI}
 */
export class TimetableAdminControllerApi extends BaseAPI {
  /**
   *
   * @summary Szolgáltatás létrehozása
   * @param {CreateUpdateCruiseRequest} createUpdateCruiseRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public createCruise(createUpdateCruiseRequest: CreateUpdateCruiseRequest, options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .createCruise(createUpdateCruiseRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Járatindulások létrehozása a hét bizonyos napjaira
   * @param {AddDepartureRequest} addDepartureRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public createDepartures(addDepartureRequest: AddDepartureRequest, options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .createDepartures(addDepartureRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Szezon létrehozása
   * @param {CreateSeasonRequest} createSeasonRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public createSeason(createSeasonRequest: CreateSeasonRequest, options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .createSeason(createSeasonRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Járatindulások létrehozása a hét bizonyos napjaira
   * @param {AddUniqueDepartureRequest} addUniqueDepartureRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public createUniqueDepartures(
    addUniqueDepartureRequest: AddUniqueDepartureRequest,
    options?: any,
  ) {
    return TimetableAdminControllerApiFp(this.configuration)
      .createUniqueDepartures(addUniqueDepartureRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Szolgáltatás törlése
   * @param {number} cruiseId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public deleteCruise(cruiseId: number, options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .deleteCruise(cruiseId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Szezon törlése
   * @param {number} seasonId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public deleteSeason(seasonId: number, options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .deleteSeason(seasonId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Szolgáltatás lista lekérése
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public findAllCruises(options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .findAllCruises(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Járatindulások lekérése adott dátumra
   * @param {DeparturesByDateRequest} departuresByDateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public findAllDeparturesByDate(departuresByDateRequest: DeparturesByDateRequest, options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .findAllDeparturesByDate(departuresByDateRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Szinkronizálja a járatindulásokat az adott szezonra, adott évben
   * @param {string} seasonId
   * @param {string} year
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public generateDeparturesFromSync(seasonId: string, year: string, options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .generateDeparturesFromSync(seasonId, year, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Minden járatindulás lekérése adott szezonra
   * @param {string} seasonId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public getDeparturesBySeason(seasonId: string, options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .getDeparturesBySeason(seasonId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Összekapcsolható szolgáltatások lekérése
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public getInternalCruisesNotMapped(options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .getInternalCruisesNotMapped(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Szezon lista lekérése
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public getSeasonList(options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .getSeasonList(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Szolgáltatás adatainak frissítése
   * @param {CreateUpdateCruiseRequest} createUpdateCruiseRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public updateCruise(createUpdateCruiseRequest: CreateUpdateCruiseRequest, options?: any) {
    return TimetableAdminControllerApiFp(this.configuration)
      .updateCruise(createUpdateCruiseRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Járatindulások státusz frissítése
   * @param {UpdateDepartureStatusesRequest} updateDepartureStatusesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public updateDeparturesStatus(
    updateDepartureStatusesRequest: UpdateDepartureStatusesRequest,
    options?: any,
  ) {
    return TimetableAdminControllerApiFp(this.configuration)
      .updateDeparturesStatus(updateDepartureStatusesRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Járatindulások kiegészítő infojának frissítése
   * @param {UpdateDeparturesDescriptionRequest} updateDeparturesDescriptionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public updateDescription(
    updateDeparturesDescriptionRequest: UpdateDeparturesDescriptionRequest,
    options?: any,
  ) {
    return TimetableAdminControllerApiFp(this.configuration)
      .updateDescription(updateDeparturesDescriptionRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Dátumos járatindulások státusz frissítése
   * @param {UpdateUniqueDepartureStatusesRequest} updateUniqueDepartureStatusesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public updateUniqueDeparturesStatus(
    updateUniqueDepartureStatusesRequest: UpdateUniqueDepartureStatusesRequest,
    options?: any,
  ) {
    return TimetableAdminControllerApiFp(this.configuration)
      .updateUniqueDeparturesStatus(updateUniqueDepartureStatusesRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Dátumos járatindulás kiegészítő infojának frissítése
   * @param {UpdateUniqueDepartureDescriptionRequest} updateUniqueDepartureDescriptionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableAdminControllerApi
   */
  public updateUniqueDescription(
    updateUniqueDepartureDescriptionRequest: UpdateUniqueDepartureDescriptionRequest,
    options?: any,
  ) {
    return TimetableAdminControllerApiFp(this.configuration)
      .updateUniqueDescription(updateUniqueDepartureDescriptionRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TimetableControllerApi - axios parameter creator
 * @export
 */
export const TimetableControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Aktuális járatindulások lekérése az adott napra
     * @param {string} dayofweek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllPublishedDeparturesByDay: async (
      dayofweek: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dayofweek' is not null or undefined
      assertParamExists('findAllPublishedDeparturesByDay', 'dayofweek', dayofweek)
      const localVarPath = `/api/v1.0/departures/day-published/{dayofweek}`.replace(
        `{${'dayofweek'}}`,
        encodeURIComponent(String(dayofweek)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TimetableControllerApi - functional programming interface
 * @export
 */
export const TimetableControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TimetableControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Aktuális járatindulások lekérése az adott napra
     * @param {string} dayofweek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findAllPublishedDeparturesByDay(
      dayofweek: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartureModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findAllPublishedDeparturesByDay(
        dayofweek,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TimetableControllerApi - factory interface
 * @export
 */
export const TimetableControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TimetableControllerApiFp(configuration)
  return {
    /**
     *
     * @summary Aktuális járatindulások lekérése az adott napra
     * @param {string} dayofweek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllPublishedDeparturesByDay(
      dayofweek: string,
      options?: any,
    ): AxiosPromise<Array<DepartureModel>> {
      return localVarFp
        .findAllPublishedDeparturesByDay(dayofweek, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * TimetableControllerApi - object-oriented interface
 * @export
 * @class TimetableControllerApi
 * @extends {BaseAPI}
 */
export class TimetableControllerApi extends BaseAPI {
  /**
   *
   * @summary Aktuális járatindulások lekérése az adott napra
   * @param {string} dayofweek
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimetableControllerApi
   */
  public findAllPublishedDeparturesByDay(dayofweek: string, options?: any) {
    return TimetableControllerApiFp(this.configuration)
      .findAllPublishedDeparturesByDay(dayofweek, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
