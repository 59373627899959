import {
  CalendarOutlined,
  DesktopOutlined,
  EuroCircleFilled,
  EuroOutlined,
  FieldTimeOutlined,
  LogoutOutlined,
  NotificationOutlined,
  SafetyOutlined,
  StarOutlined,
  UsergroupAddOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons'
import { JwtResponse } from '@hmedia/legenda-ds-api-client'
import { Avatar, Button, Layout, Menu } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ACTUALITYVIEW_META, INFOVIEW_META } from '../../domains/inform/InformView'
import { MEDIAFILESVIEW_META } from '../../domains/mediafile/MediafilePage'
import { SCREENSVIEW_META } from '../../domains/screens/ScreensView'
import { SETTINGSVIEW_META } from '../../domains/settings/SettingsView'
import { TICKETPRICESVIEW_META } from '../../domains/ticketPrices/TicketPricesPage'
import { TIMETABLEVIEW_META } from '../../domains/timetable/TimetableView'
import { UNIQUEDEPARTURESVIEW_META } from '../../domains/uniqueDepartures/UniqueDeparturesView'
import { AUTHORITIESVIEW_META } from '../../domains/users/AuthoritiesView'
import { USERSVIEW_META } from '../../domains/users/UsersView'
import AuthService from '../../services/authService'
import { withPermission } from '../../services/security/permissionContext'
import logo from './new-logo.svg'

const StyledAvatar = styled(Avatar)`
  justify-self: center;
  align-self: center;
`

const RestrictedMenuItem = withPermission(Menu.Item)

const ActiveMenu = ({ onLogout, ...props }) => {
  let location = useLocation()
  let navigate = useNavigate()
  const [user, setUser] = useState<JwtResponse | undefined>()
  const [initials, setInitials] = useState('AB')

  useEffect(() => {
    setUser(AuthService.getCurrentUser())
    return () => {}
  }, [])

  useEffect(() => {
    setInitials(
      user?.fullname
        ?.split(' ', 2)
        ?.map((name) => name.substring(0, 1))
        ?.join(''),
    )
    return () => {}
  }, [user])

  return (
    <Layout.Sider id="MainMenuSider" width={'220px'}>
      <div id={'logo'}>
        <img src={logo} alt={''} width={139} />
      </div>
      <Menu mode={'vertical'} defaultSelectedKeys={['/']} selectedKeys={[location.pathname]}>
        <RestrictedMenuItem
          to={['MENU_ACCESS__SCREENS']}
          key={SCREENSVIEW_META.path}
          onClick={() => navigate(SCREENSVIEW_META.path)}
        >
          <DesktopOutlined style={{ fontSize: '18px' }} />
          <span>{SCREENSVIEW_META.menuCaption || SCREENSVIEW_META.title}</span>
        </RestrictedMenuItem>
        <RestrictedMenuItem
          to={['MENU_ACCESS__TIMETABLE']}
          style={{ marginTop: '2rem' }}
          key={TIMETABLEVIEW_META.path}
          onClick={() => navigate(TIMETABLEVIEW_META.path)}
        >
          <CalendarOutlined style={{ fontSize: '18px' }} />
          <span>{TIMETABLEVIEW_META.title}</span>
        </RestrictedMenuItem>
        <RestrictedMenuItem
          to={['MENU_ACCESS__DEPARTURES']}
          // className={'yellow-menu'}
          key={UNIQUEDEPARTURESVIEW_META.path}
          onClick={() => navigate(UNIQUEDEPARTURESVIEW_META.path)}
          style={{ height: '56px' }}
        >
          <FieldTimeOutlined style={{ fontSize: '18px' }} />
          <span>{UNIQUEDEPARTURESVIEW_META.title}</span>
          <br />
          <small>egyedi szerkesztés</small>
        </RestrictedMenuItem>
        <RestrictedMenuItem
          to={['MENU_ACCESS__PERMISSIONS']}
          onlySU={true}
          key={MEDIAFILESVIEW_META.path}
          onClick={() => navigate(MEDIAFILESVIEW_META.path)}
        >
          <VideoCameraAddOutlined style={{ fontSize: '18px' }} />
          <span>{MEDIAFILESVIEW_META.title}</span>
        </RestrictedMenuItem>
        <RestrictedMenuItem
          to={['MENU_ACCESS__PRICES']}
          key={TICKETPRICESVIEW_META.path}
          onClick={() => navigate(TICKETPRICESVIEW_META.path)}
        >
          <EuroOutlined style={{ fontSize: '18px' }} />
          <span>{TICKETPRICESVIEW_META.title}</span>
        </RestrictedMenuItem>
        <RestrictedMenuItem
          to={['MENU_ACCESS__INFO']}
          key={INFOVIEW_META.path}
          onClick={() => navigate(INFOVIEW_META.path)}
          style={{ marginTop: '2rem' }}
        >
          <NotificationOutlined style={{ fontSize: '18px' }} />
          <span>{INFOVIEW_META.title}</span>
        </RestrictedMenuItem>
        <RestrictedMenuItem
          to={['MENU_ACCESS__ACTUALITY']}
          key={ACTUALITYVIEW_META.path}
          onClick={() => navigate(ACTUALITYVIEW_META.path)}
        >
          <StarOutlined style={{ fontSize: '18px' }} />
          <span>{ACTUALITYVIEW_META.title}</span>
        </RestrictedMenuItem>
        <RestrictedMenuItem
          to={['MENU_ACCESS__USERS']}
          key={USERSVIEW_META.path}
          onClick={() => navigate(USERSVIEW_META.path)}
          style={{ marginTop: '2rem' }}
        >
          <UsergroupAddOutlined style={{ fontSize: '18px' }} />
          <span>{USERSVIEW_META.title}</span>
        </RestrictedMenuItem>
        <RestrictedMenuItem
          to={['MENU_ACCESS__PERMISSIONS']}
          key={AUTHORITIESVIEW_META.path}
          onClick={() => navigate(AUTHORITIESVIEW_META.path)}
        >
          <SafetyOutlined style={{ fontSize: '18px' }} />
          <span>{AUTHORITIESVIEW_META.title}</span>
        </RestrictedMenuItem>
        <RestrictedMenuItem
          to={['MENU_ACCESS__EXCHANGE']}
          key={SETTINGSVIEW_META.path}
          onClick={() => navigate(SETTINGSVIEW_META.path)}
          style={{ marginTop: '2rem' }}
        >
          <EuroCircleFilled style={{ fontSize: '18px' }} />
          <span>{SETTINGSVIEW_META.title}</span>
        </RestrictedMenuItem>
      </Menu>

      <div className={'menu-footer'}>
        <StyledAvatar size={'large'}>{initials}</StyledAvatar>
        <div>
          <LogoutOutlined style={{ fontSize: '14px', marginRight: '0.4rem' }} />
          <Button
            type="link"
            onClick={() => {
              AuthService.logout()
              onLogout()
            }}
          >
            Kilépés
          </Button>
        </div>
      </div>
    </Layout.Sider>
  )
}

export default ActiveMenu
