import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'

export type Props = {
  pageTitle?: string
}

const DefaultSplitLayout: FC = (props) => {
  return (
    <Layout hasSider={true}>
      <Content>{props.children}</Content>
    </Layout>
  )
}

export const CONTENTSVIEW_META: ViewMeta = {
  path: '/contents',
  title: 'Tartalmak',
}

const ContentsView: FC<Props> = (props) => {
  let location = useLocation()
  return (
    <DefaultSplitLayout>
      <h1>{props.pageTitle || location.pathname}</h1>
    </DefaultSplitLayout>
  )
}

export default ContentsView
