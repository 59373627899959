const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  return user?.refreshToken
}

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  return user?.accessToken
}

const updateLocalAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem('user'))
  user.accessToken = token
  localStorage.setItem('user', JSON.stringify(user))
}

const isAccessTokenExpired = () => {
  return getLocalAccessToken()
    ? Date.now() >= JSON.parse(atob(getLocalAccessToken().split('.')[1])).exp * 1000
    : true
}

const getUser = () => {
  return JSON.parse(localStorage.getItem('user'))
}

const setUser = (user) => {
  console.log(JSON.stringify(user))
  localStorage.setItem('user', JSON.stringify(user))
}

const removeUser = () => {
  localStorage.removeItem('user')
}

export const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  isAccessTokenExpired,
  getUser,
  setUser,
  removeUser,
}

export default TokenService
