import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'

if (import.meta.env.DEV) {
  if (import.meta.env.VITE_FAKE_API_ENABLED === true) {
    // ;(async () => {
    //     import('./services/FakeAPIService')
    // })()
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
