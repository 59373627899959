import Icon, { BoldOutlined, HighlightOutlined } from '@ant-design/icons'
import { InformModelTypeEnum } from '@hmedia/legenda-ds-api-client'
import { Button, Toolbar } from 'components/SlateEditor/components'
import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { createEditor, Descendant, Editor, Element as SlateElement, Transforms } from 'slate'
import { Editable, Slate, useSlate, withReact } from 'slate-react'

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']
const HL1Style: CSSProperties = {
  backgroundColor: '#ffc801',
  color: '#0d172d',
  fontWeight: 'bold',
}
const HLActualityStyle: CSSProperties = {
  backgroundColor: '#ff7225',
  color: '#ffffff',
  fontWeight: 'bold',
}

interface SlateEitorProps {
  onChange: (descendant: Descendant[], currentCharLength: number, maxLength: number) => void
  onTextLimitReached: (limit: number) => void
  initialValue: Descendant[]
  maxLength: number
  type: InformModelTypeEnum
}

export const emptyNode: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

const SlateEditor: FC<SlateEitorProps> = ({
  onChange,
  initialValue = emptyNode,
  type = InformModelTypeEnum.INFO,
  onTextLimitReached,
  maxLength = 9999,
  ...props
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

  const withTextLimit = ({ limit = maxLength, onReachLimit = onTextLimitReached } = {}) =>
    function Plugin(editor: Editor) {
      const { insertText, deleteBackward } = editor
      editor.insertText = (text) => {
        let currentLength = Editor.string(editor, []).length
        if (currentLength < limit) {
          insertText(text)
        } else {
          deleteBackward('character')
          onReachLimit(limit)
        }
      }
      return editor
    }

  // Create a Slate editor object that won't change across renders.
  const editor = useMemo(() => withTextLimit()(withReact(createEditor())), [])

  // Keep track of state for the value of the editor.
  const [value, setValue] = useState<Descendant[]>(initialValue)

  useEffect(() => {
    setValue(initialValue)

    return () => {}
  }, [])

  const HL1 = () => (
    <span style={{ ...HL1Style, padding: '2px 4px' }}>
      <Icon component={HighlightOutlined} style={{ marginRight: '5px' }} />
      Kiemel
    </span>
  )

  const HLActuality = () => (
    <span style={{ ...HLActualityStyle, padding: '2px 4px' }}>
      <Icon component={HighlightOutlined} style={{ marginRight: '5px' }} />
      Kiemel
    </span>
  )

  return (
    <div style={{ border: '1px solid #d9d9d9' }}>
      <Slate
        editor={editor}
        value={value}
        onChange={(descendant) => {
          let currentLength = Editor.string(editor, []).length
          onChange(descendant, currentLength, maxLength)
        }}
      >
        <Toolbar>
          <MarkButton format="bold" icon={BoldOutlined} />
          {type === InformModelTypeEnum.INFO ? (
            <MarkButton format="highlighted1" icon={HL1} />
          ) : (
            <MarkButton format="highlightedActuality" icon={HLActuality} />
          )}
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Add meg a tartalom szövegét..."
          spellCheck
          autoFocus
          style={{ padding: '0 14px' }}
        />
      </Slate>
    </div>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      type: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    }),
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align }
  switch (element.type) {
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.highlighted1) {
    children = <span style={HL1Style}>{children}</span>
  }

  if (leaf.highlightedActuality) {
    children = <span style={HLActualityStyle}>{children}</span>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon component={icon} />
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon component={icon} />
    </Button>
  )
}

export default SlateEditor
