import { ArrowUpOutlined, DeleteFilled, EditOutlined, PlusOutlined } from '@ant-design/icons'
import type {
  CreateUpdateInformRequestTypeEnum,
  InformModelTypeEnum,
} from '@hmedia/legenda-ds-api-client'
import { dayjs } from '@hmedia/legenda-ds-common'
import { Button, Popconfirm, Space, Table } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { slateSerializer } from 'components/SlateEditor/serializer'
import { type FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import { ContentSelectorModal } from './ContentSelectorModal'
import InformEditorDrawerForm, { type FormValues } from './InformEditorDrawerForm'
import { ScreenSelectorModal } from './ScreenSelectorModal'
import type { InformDTO } from './models'
import {
  useCreateUpdateInformMutation,
  useDeleteInforByIdMutation,
  useSearchInformByTypeQuery,
} from './queries'
// import { useAllRolesQuery, useSetUserEnabledMutation, useSetUserRoleMutation, useUsersQuery } from './queries'

export type Props = {
  pageTitle?: string
  type: InformModelTypeEnum
}

export const INFOVIEW_META: ViewMeta = {
  path: '/inform',
  title: 'Infó',
}
export const ACTUALITYVIEW_META: ViewMeta = {
  path: '/actuality',
  title: 'Aktualitás',
}

const InformView: FC<Props> = (props) => {
  const isActualityType = props.type === 'ACTUALITY'
  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const [isScreenSelectorOpen, setIsScreenSelectorOpen] = useState(false)
  const [editingInform, setEditingInform] = useState<InformDTO | undefined>(undefined)
  const searchInformByTypeQuery = useSearchInformByTypeQuery(props.type)
  const createUpdateMutation = useCreateUpdateInformMutation(
    () => {
      queryClient.invalidateQueries('informByType')
      closeCancelEditing()
    },
    (err) => {
      console.error(err)
    },
  )
  const deleteInformByIdMutation = useDeleteInforByIdMutation(() => {
    queryClient.invalidateQueries('informByType')
  })
  const isControlDisabled = (username): boolean => ['miki', 'turcsana'].includes(username)
  const queryClient = useQueryClient()
  const sendToScreen = (inform: InformDTO) => {
    setEditingInform(inform)
    setIsScreenSelectorOpen(true)
  }
  const editInform = (inform: InformDTO) => {
    setEditingInform(inform)
    setIsEditorOpen(true)
  }

  const tableCols: ColumnType<InformDTO>[] = [
    {
      title: 'Frissítve',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      align: 'left',
      width: '150px',
      render: (updatedAt: string, inform: InformDTO) => (
        <span>{dayjs(updatedAt).format('YYYY.MM.DD. HH:mm')}</span>
      ),
    },
    {
      title: 'Admin elnevezés',
      key: 'name',
      dataIndex: 'name',
      align: 'left',
      width: '350px',
      render: (name: string, inform: InformDTO) => <span>{name}</span>,
    },
    {
      title: 'Tartalom címe',
      key: 'title',
      dataIndex: 'title',
      align: 'left',
      render: (title: string, inform: InformDTO) => <span>{title}</span>,
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      width: '350px',
      render: (inform: InformDTO) => (
        <>
          <Space direction="horizontal">
            <Button
              type="primary"
              icon={<ArrowUpOutlined />}
              onClick={() => {
                sendToScreen(inform)
              }}
            >
              {isActualityType ? 'Megjelenítés' : 'Kijelzőre'}
            </Button>
            <Button type="default" icon={<EditOutlined />} onClick={() => editInform(inform)}>
              Szerkeszt
            </Button>
            <Popconfirm
              title="Biztos, hogy törlöd?"
              okText="Igen"
              cancelText="Nem"
              onConfirm={(e) =>
                deleteInformByIdMutation.mutate({
                  id: inform.id.toString(),
                })
              }
            >
              <Button type="link" icon={<DeleteFilled />} />
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ]

  const closeCancelEditing = () => {
    setIsEditorOpen(false)
    setEditingInform(undefined)
  }

  const onCreate = (values: FormValues) => {
    createUpdateMutation.mutate({
      contentHTML: slateSerializer(values.content),
      contentJSON: JSON.stringify(values.content),
      isTodayShown: values.isTodayShown,
      name: values.name,
      type: props.type as undefined as CreateUpdateInformRequestTypeEnum,
      id: editingInform?.id?.toString(),
      title: values.title,
    })
  }

  return (
    <div style={{ width: '100%' }}>
      <Space direction="horizontal" align="start" style={{ marginTop: '2rem', width: '100%' }}>
        <h1>{isActualityType ? 'Aktualitás' : 'Infó'} tartalmak</h1>
      </Space>
      <Space direction="vertical" style={{ width: '100%', marginTop: '2em' }} size={20}>
        <Space direction="horizontal" align="start" style={{ width: '100%' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            // loading={screensQuiery.isLoading}
            onClick={() => setIsEditorOpen(true)}
            size={'large'}
          >
            Új tartalom hozzáadása
          </Button>
        </Space>
        <Table
          pagination={{ position: ['bottomCenter'] }}
          columns={tableCols}
          sortDirections={['ascend']}
          dataSource={searchInformByTypeQuery.data}
          rowKey={'key'}
        />
      </Space>
      {isEditorOpen && (
        <InformEditorDrawerForm
          visible={isEditorOpen}
          inform={editingInform}
          type={props.type}
          onCreate={onCreate}
          onClose={closeCancelEditing}
          onCancel={closeCancelEditing}
        />
      )}
      {isScreenSelectorOpen && isActualityType ? (
        <ContentSelectorModal
          visible={isScreenSelectorOpen}
          inform={editingInform}
          onClose={() => {
            setIsScreenSelectorOpen(false)
            setEditingInform(undefined)
            searchInformByTypeQuery.refetch()
          }}
        />
      ) : (
        <ScreenSelectorModal
          visible={isScreenSelectorOpen}
          inform={editingInform}
          onClose={() => {
            setIsScreenSelectorOpen(false)
            setEditingInform(undefined)
          }}
        />
      )}
    </div>
  )
}

export default InformView
