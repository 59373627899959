import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  type DepartureModel,
  DepartureModelGateEnum,
  type LocalTime,
  type UpdateUniqueDepartureStatusesRequestGateEnum,
} from '@hmedia/legenda-ds-api-client'
import { dayjs } from '@hmedia/legenda-ds-common'
import { DatePicker } from '@hmedia/legenda-ds-react'
import { Button, Select, Space, Spin, Switch, Table, Tag, Tooltip } from 'antd'
import locale from 'antd/es/date-picker/locale/hu_HU'
import Paragraph from 'antd/es/skeleton/Paragraph'
import type { ColumnType } from 'antd/es/table'
import type { TableRowSelection } from 'antd/es/table/interface'
import type { Dayjs } from 'dayjs'
import { size } from 'lodash'
import { type FC, type Key, useState } from 'react'
import { useQueryClient } from 'react-query'
import { DepartureDescUpdater } from '../../components/DepartureDescUpdater/DepartureDescUpdater'
import { formattedLocalTime } from '../../utils/helpers'
import { useCruisesQuery } from '../timetable/queries'
import UniqueDepartureEditor from './UniqueDepartureEditor'
import {
  useCreateUniqueDeparturesMutation,
  useDeparturesByDateQuery,
  useUpdateUniqueDeparturesMutation,
} from './queries'
import { GateSelector } from 'components/GateSelector/GateSelector'

export type Props = {
  pageTitle?: string
}

export const UNIQUEDEPARTURESVIEW_META: ViewMeta = {
  path: '/unique-departures',
  title: 'Megtelt/Törölt',
}

export const switchChildrens = {
  checkedChildren: 'IGEN',
  unCheckedChildren: 'NEM',
}

const UniqueDeparturesView: FC<Props> = ({ pageTitle }) => {
  const queryClient = useQueryClient()

  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs())
  const [selectedDepartures, setSelectedDepartures] = useState<Key[] | null>(null)
  const [createDepartureDrawerIsOpen, setCreateDepartureDrawerIsOpen] = useState(false)

  const query = useDeparturesByDateQuery(selectedDate.format('YYYY-MM-DD'))
  const cruisesQuery = useCruisesQuery()
  const departureMutation = useUpdateUniqueDeparturesMutation(() => query.refetch())

  const rowSelection: TableRowSelection<DepartureModel> = {
    selectedRowKeys: selectedDepartures,
    onChange: (selectedRowKeys: Key[], selectedRows: DepartureModel[]) => {
      setSelectedDepartures(selectedRowKeys)
    },
  }

  const onDateSelect = (date: Dayjs) => setSelectedDate(date)
  const onPublishedHandler = (depId: number, isPublished: boolean) =>
    departureMutation.mutate({
      date: selectedDate.format('YYYY-MM-DD'),
      departureId: depId,
      isPublished: isPublished,
    })
  const onCancellHandler = (depId: number, isCancel: boolean) =>
    departureMutation.mutate({
      date: selectedDate.format('YYYY-MM-DD'),
      departureId: depId,
      isCancelled: isCancel,
    })
  const onFullHandler = (depId: number, isFull: boolean) =>
    departureMutation.mutate({
      date: selectedDate.format('YYYY-MM-DD'),
      departureId: depId,
      isFull: isFull,
    })
  const onGateChangeHandler = (depId: number, gate: DepartureModelGateEnum) =>
    departureMutation.mutate({
      date: selectedDate.format('YYYY-MM-DD'),
      departureId: depId,
      gate: gate as unknown as UpdateUniqueDepartureStatusesRequestGateEnum,
    })

  const tableCols: ColumnType<DepartureModel>[] = [
    {
      title: 'módosított',
      key: 'unique',
      dataIndex: 'startDate',
      align: 'center',
      width: '50px',
      render: (startDate: string, dep: DepartureModel) =>
        startDate ? (
          <Tooltip overlay={'Ez a járat vagy egy egyedileg létrehozott, vagy módosított járat.'}>
            <Tag icon={<InfoCircleOutlined />} color={'green'}>
              módosított
            </Tag>
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      title: 'indul?',
      key: 'published',
      dataIndex: 'isPublished',
      width: '70px',
      align: 'center',
      render: (isPublished: boolean, dep: DepartureModel) => {
        return (
          <span>
            <Switch
              loading={departureMutation.isLoading}
              checked={isPublished}
              {...switchChildrens}
              onChange={(bool) => onPublishedHandler(dep.id, bool)}
            />
          </span>
        )
      },
    },
    {
      title: 'Indulás',
      key: 'startTime',
      dataIndex: 'startAt',
      align: 'center',
      render: (startAt: LocalTime, dep: DepartureModel) => {
        return <span>{formattedLocalTime(startAt)}</span>
      },
    },
    {
      title: 'Szolgáltatás',
      key: 'name',
      dataIndex: ['cruise', 'displayNameMap', 'hu'],
      render: (cruise: string, dep: DepartureModel) => {
        return <span>{cruise}</span>
      },
    },
    {
      title: 'Kieg. információ',
      key: 'name',
      dataIndex: ['description'],
      render: (cruise: string, dep: DepartureModel) => {
        return (
          <DepartureDescUpdater
            departureId={dep.id}
            initialDesc={dep.description}
            startDate={selectedDate.format('YYYY-MM-DD')}
            onSuccessTextChange={() => query.refetch()}
          />
        )
      },
    },
    {
      title: 'Kapu',
      key: 'gate',
      dataIndex: 'gate',
      align: 'center',
      render: (gate: DepartureModelGateEnum, dep: DepartureModel) => {
        return (
          <GateSelector
            value={gate}
            size="small"
            onChange={(val) => onGateChangeHandler(dep.id, val)}
          />
        )
      },
    },
    {
      title: 'Megtelt',
      key: 'isFull',
      dataIndex: 'isFull',
      align: 'center',
      render: (isFull: boolean, dep: DepartureModel) => {
        return (
          <span>
            <Switch
              loading={departureMutation.isLoading}
              checked={isFull}
              {...switchChildrens}
              onChange={(bool) => onFullHandler(dep.id, bool)}
            />
          </span>
        )
      },
    },
    {
      title: 'Törölt',
      key: 'isCancelled',
      dataIndex: 'isCancelled',
      align: 'center',
      render: (isCancelled: boolean, dep: DepartureModel) => {
        return (
          <span>
            <Switch
              loading={departureMutation.isLoading}
              checked={isCancelled}
              {...switchChildrens}
              onChange={(bool) => onCancellHandler(dep.id, bool)}
            />
          </span>
        )
      },
    },
  ]

  if (import.meta.env.NODE_ENV === 'development') {
    tableCols.unshift({
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: '40px',
    })
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <h1 style={{ marginTop: '2rem' }}>Megtelt/Törölt járatok</h1>
        <Spin spinning={query.isLoading}>
          <Space direction="horizontal" align="start">
            <DatePicker
              locale={locale}
              style={{
                visibility: 'hidden',
                height: '0',
                width: '300px',
                marginBottom: '330px',
              }}
              disabledDate={(date: Dayjs) => date.isBefore(dayjs().subtract(1, 'day'))}
              open={true}
              defaultValue={dayjs()}
              defaultOpen={true}
              size={'small'}
              onChange={(date) => onDateSelect(date)}
            />
            <Space direction="vertical">
              <Space direction="horizontal" style={{ marginTop: '1.5rem' }}>
                <h2>{selectedDate.format('YYYY. MMMM D. dddd')}</h2>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => setCreateDepartureDrawerIsOpen(true)}
                  style={{ marginLeft: '3rem' }}
                >
                  Új indulás hozzáadása
                </Button>
              </Space>
              <Table<DepartureModel>
                dataSource={query.data}
                rowKey={'id'}
                columns={tableCols}
                size="middle"
                // rowSelection={rowSelection}
                pagination={{
                  hideOnSinglePage: true,
                  position: ['bottomCenter'],
                  showTotal: () => true,
                  pageSize: 35,
                }}
                style={{
                  minWidth: '1000px',
                  marginTop: '1rem',
                  minHeight: '70vh',
                  backgroundColor: 'white',
                }}
              />
            </Space>
          </Space>
        </Spin>
      </div>
      {createDepartureDrawerIsOpen && (
        <UniqueDepartureEditor
          visible={createDepartureDrawerIsOpen}
          cruises={cruisesQuery.data}
          selectedDate={selectedDate}
          onCreate={() => {
            queryClient.invalidateQueries(['departures', selectedDate.format('YYYY-MM-DD')])
            setCreateDepartureDrawerIsOpen(false)
          }}
          onCancel={(e) => setCreateDepartureDrawerIsOpen(false)}
          onClose={(e) => setCreateDepartureDrawerIsOpen(false)}
        />
      )}
    </>
  )
}

export default UniqueDeparturesView
