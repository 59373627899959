import {
  AddDepartureRequest,
  AddUniqueDepartureRequest,
  AppSettingDTO,
  AppSettingResourceControllerApi,
  AuthorityModel,
  ContentAdminControllerApi,
  CreateUpdateInformRequest,
  Cruise,
  DeleteInformRequest,
  DepartureModel,
  ExchangeSettingsDTOResponse,
  InformAdminControllerApi,
  InformModel,
  JwtResponse,
  MediaFileControllerApi,
  MediaFileDTO,
  RoleModel,
  RunnableContentDTO,
  ScreenContentSetRequestContentTypeEnum,
  ScreenModel,
  ScreenModelContentTypeEnum,
  ScreenResourceAdminControllerApi,
  ScreenResourceControllerApi,
  SearchInformRequestTypeEnum,
  SeasonModel,
  SecurityControllerApi,
  SendActualityToContentRequest,
  SendInformToScreenRequest,
  TicketPriceCreateRequestTypeEnum,
  TicketPriceDTO,
  TicketPriceIndexUpdateRequests,
  TicketPriceResourceControllerApi,
  TicketPriceUpdateRequest,
  TimetableAdminControllerApi,
  TimetableControllerApi,
  TokenRefreshResponse,
  UpdateDeparturesDescriptionRequest,
  UpdateDepartureStatusesRequest,
  UpdateUniqueDepartureDescriptionRequest,
  UpdateUniqueDepartureStatusesRequest,
  UploadFileResponse,
  UserDto,
  WeeklyDepartureResponse,
} from '@hmedia/legenda-ds-api-client'
import axios, { AxiosResponse } from 'axios'
import { InformTypeConstantDTO } from '../domains/inform/models'
import TokenService from './security/tokenService'

const isProd = import.meta.env.PROD
const baseURL = import.meta.env.VITE_API_URL

const axiosInstance = axios.create({
  baseURL: baseURL,
  responseType: 'json',
  timeout: isProd ? 6000 : 2 * 60 * 1000,
})

const contentAdminApi = new ContentAdminControllerApi(null, '', axiosInstance)
const screenAdminApi = new ScreenResourceAdminControllerApi(null, '', axiosInstance)
const screenApi = new ScreenResourceControllerApi(null, '', axiosInstance)
const ticketPriceApi = new TicketPriceResourceControllerApi(null, '', axiosInstance)
const timetableApi = new TimetableControllerApi(null, '', axiosInstance)
const mediafileApi = new MediaFileControllerApi(null, '', axiosInstance)
const timetableAdminApi = new TimetableAdminControllerApi(null, '', axiosInstance)
const appSettingsApi = new AppSettingResourceControllerApi(null, '', axiosInstance)
const securityApi = new SecurityControllerApi(null, '', axiosInstance)
const informApi = new InformAdminControllerApi(null, '', axiosInstance)

axiosInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config

    if (originalConfig.url !== `/api/v1.0/auth/login` && err.response) {
      if (err.response?.data?.errorCode === 'refresh-token-expired') {
        return Promise.reject(err.response?.data)
      }
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          const rs = (
            await securityApi.refreshtoken({
              refreshToken: TokenService.getLocalRefreshToken(),
            })
          ).data

          const { accessToken } = rs
          TokenService.updateLocalAccessToken(accessToken)

          return axiosInstance(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }

    return Promise.reject(err)
  },
)

interface DSApiService {
  baseURL: string
  getAppSettings: () => Promise<AxiosResponse<AppSettingDTO[]>>
  updateAppSettings: (settings: AppSettingDTO[]) => Promise<AxiosResponse<void>>
  getExchangeRateSettings: () => Promise<AxiosResponse<ExchangeSettingsDTOResponse>>
  getScreens: () => Promise<AxiosResponse<ScreenModel[]>>
  getRunnableContentList: () => Promise<AxiosResponse<RunnableContentDTO[]>>
  renameScreen: (id: string, name: string) => Promise<AxiosResponse<void>>
  getTicketPrices: () => Promise<AxiosResponse<TicketPriceDTO[]>>
  deleteTicketPrices: (priceIds: number[]) => Promise<AxiosResponse<void>>
  getCruises: () => Promise<AxiosResponse<Cruise[]>>
  syncDepartures: (seasonId: string, year: string) => Promise<AxiosResponse<void>>
  createDepartures: (addDepartureRequest: AddDepartureRequest) => Promise<AxiosResponse<void>>
  createUniqueDepartures: (request: AddUniqueDepartureRequest) => Promise<AxiosResponse<void>>
  getAllDeparturesByDate: (date: string) => Promise<AxiosResponse<DepartureModel[]>>
  getAllDeparturesBySeason: (seasonId: string) => Promise<AxiosResponse<WeeklyDepartureResponse[]>>
  updateDepartures: (
    updateDepartureStatusesRequest: UpdateDepartureStatusesRequest,
  ) => Promise<AxiosResponse<void>>
  updateUniqueDepartureStatus: (
    request: UpdateUniqueDepartureStatusesRequest,
  ) => Promise<AxiosResponse<void>>
  updateDepartureDescription: (
    request: UpdateDeparturesDescriptionRequest,
  ) => Promise<AxiosResponse<void>>
  updateUniqueDepartureDescription: (
    request: UpdateUniqueDepartureDescriptionRequest,
  ) => Promise<AxiosResponse<void>>

  updateEURExchangeRate: (rate: number) => Promise<AxiosResponse<void>>
  createTicketPrice: (
    name: string,
    cruiseId: string,
    priceEUR: string,
    type: TicketPriceCreateRequestTypeEnum,
    index: number,
  ) => Promise<AxiosResponse<TicketPriceDTO>>
  updateTicketPrice: (request: TicketPriceUpdateRequest) => Promise<AxiosResponse<TicketPriceDTO>>
  updateTicketPriceStatus: (
    ticketPriceId: number,
    isPublished?: boolean,
    index?: number,
  ) => Promise<AxiosResponse<TicketPriceDTO>>
  createSeason: (
    name: string,
    startDate: string,
    endDate: string,
  ) => Promise<AxiosResponse<SeasonModel>>
  getSeasons: () => Promise<AxiosResponse<SeasonModel[]>>
  deleteSeason: (seasonId: number) => Promise<AxiosResponse<void>>
  uploadFile: (file: File) => Promise<AxiosResponse<UploadFileResponse>>
  getInternalCruisesNotMapped: () => Promise<AxiosResponse<{ [key: string]: string }>>
  createCruise: (
    name: string,
    description?: string,
    internalName?: string,
    internalId?: string,
  ) => Promise<AxiosResponse<Cruise>>
  updateCruise: (
    id: string,
    name: string,
    description?: string,
    internalName?: string,
    internalId?: string,
  ) => Promise<AxiosResponse<Cruise>>
  deleteCruise: (id: number) => Promise<AxiosResponse<void>>
  setScreenContent: (
    screenId: string,
    contentType: ScreenModelContentTypeEnum,
    contentId: string,
  ) => Promise<AxiosResponse<void>>
  getMediaFiles: () => Promise<AxiosResponse<MediaFileDTO[]>>
  createMediaFile: (title, filename) => Promise<AxiosResponse<MediaFileDTO>>
  updateMediaFile: (title, filename) => Promise<AxiosResponse<MediaFileDTO>>
  wakeUpScreens: () => Promise<AxiosResponse<void>>
  wakeUpScreenByMAC: (mac: string) => Promise<AxiosResponse<void>>
  login: (username: string, password: string) => Promise<AxiosResponse<JwtResponse>>
  refreshToken: (refreshToken: string) => Promise<AxiosResponse<TokenRefreshResponse>>
  logout: () => void
  getUsers: () => Promise<AxiosResponse<UserDto[]>>
  setUserEnabled: (username: string, enabled: boolean) => Promise<AxiosResponse<void>>
  setUserRole: (username: string, role: string) => Promise<AxiosResponse<void>>
  getAllRoles: () => Promise<AxiosResponse<RoleModel[]>>
  getAllAuthorities: () => Promise<AxiosResponse<AuthorityModel[]>>
  setPrivilegesToRole: (
    rolename: string,
    privilegeId: number[],
  ) => Promise<AxiosResponse<RoleModel>>
  createRole: (title: string) => Promise<AxiosResponse<RoleModel>>
  deleteRole: (roleToDelete: string, replacementRole: string) => Promise<AxiosResponse<void>>
  updateTicketPriceIndexes: (
    request: TicketPriceIndexUpdateRequests,
  ) => Promise<AxiosResponse<void>>

  searchInformBy: (type: InformTypeConstantDTO) => Promise<AxiosResponse<InformModel[]>>
  createUpdateInform: (request: CreateUpdateInformRequest) => Promise<AxiosResponse<InformModel>>
  sendInfoToScreen: (request: SendInformToScreenRequest) => Promise<AxiosResponse<void>>
  sendActualityToContent: (
    request: SendActualityToContentRequest,
  ) => Promise<AxiosResponse<InformModel>>
  deleteInformById: (request: DeleteInformRequest) => Promise<AxiosResponse<void>>
}

const API: DSApiService = {
  baseURL: baseURL,
  getAppSettings: async () => await appSettingsApi.getAppSettings(),
  updateAppSettings: async (settings: AppSettingDTO[]) =>
    await appSettingsApi.updateAppSettings({ settings: settings }),
  getExchangeRateSettings: async () => await appSettingsApi.getExchangeRateSettings(),
  getScreens: async () => await screenAdminApi.getScreens(),
  getRunnableContentList: async () => await contentAdminApi.getRunnableContentList(),
  renameScreen: async (id: string, name: string) =>
    await screenAdminApi.renameScreen1({ id: id, name: name }),
  getTicketPrices: async () => await ticketPriceApi.getTicketPrices(),
  getCruises: async () => await timetableAdminApi.findAllCruises(),
  updateTicketPriceStatus: async (ticketPriceId: number, isPublished?: boolean, index?: number) =>
    await ticketPriceApi.updateTicketPriceStatus({
      id: ticketPriceId.toString(),
      isPublished: isPublished,
      index: index,
    }),
  createDepartures: async (addDepartureRequest: AddDepartureRequest) =>
    await timetableAdminApi.createDepartures(addDepartureRequest),
  createUniqueDepartures: async (request: AddUniqueDepartureRequest) =>
    await timetableAdminApi.createUniqueDepartures(request),
  getAllDeparturesByDate: async (date: string) =>
    await timetableAdminApi.findAllDeparturesByDate({ date: date }),
  getAllDeparturesBySeason: async (seasonId: string) =>
    await timetableAdminApi.getDeparturesBySeason(seasonId),
  syncDepartures: async (seasonId: string, year: string) =>
    await timetableAdminApi.generateDeparturesFromSync(seasonId, year),
  updateDepartures: async (updateDepartureStatusesRequest: UpdateDepartureStatusesRequest) =>
    await timetableAdminApi.updateDeparturesStatus(updateDepartureStatusesRequest),
  updateUniqueDepartureStatus: (request: UpdateUniqueDepartureStatusesRequest) =>
    timetableAdminApi.updateUniqueDeparturesStatus(request),
  updateDepartureDescription: (request: UpdateDeparturesDescriptionRequest) =>
    timetableAdminApi.updateDescription(request),
  updateUniqueDepartureDescription: (request: UpdateUniqueDepartureDescriptionRequest) =>
    timetableAdminApi.updateUniqueDescription(request),
  updateEURExchangeRate: async (rate: number) =>
    await appSettingsApi.updateEURExchangeRate({ rate: rate.toString() }),
  deleteTicketPrices: async (priceIds: number[]) =>
    await ticketPriceApi.deleteTicketPrices({ priceIds: priceIds }),
  createTicketPrice: async (
    name: string,
    cruiseId: string,
    priceEUR: string,
    type: TicketPriceCreateRequestTypeEnum,
    index: number,
  ) =>
    await ticketPriceApi.createTicketPrice({
      name: name,
      priceEUR: priceEUR,
      cruiseId: cruiseId,
      type: type,
      index: index,
    }),
  updateTicketPrice: async (request: TicketPriceUpdateRequest) =>
    await ticketPriceApi.updateTicketPrice(request),
  createSeason: async (name: string, startDate: string, endDate: string) =>
    await timetableAdminApi.createSeason({
      name: name,
      startDate: startDate,
      endDate: endDate,
    }),
  getSeasons: async () => await timetableAdminApi.getSeasonList(),
  deleteSeason: async (seasonId: number) => await timetableAdminApi.deleteSeason(seasonId),
  getInternalCruisesNotMapped: async () => await timetableAdminApi.getInternalCruisesNotMapped(),
  uploadFile: async (file: File) => await mediafileApi.uploadFile({ file: file }),

  createCruise: async (
    name: string,
    description?: string,
    internalName?: string,
    internalId?: string,
  ) =>
    await timetableAdminApi.createCruise({
      name: name,
      description: description,
      internalName: internalName,
      internalId: internalId,
      id: null,
    }),

  updateCruise: async (
    id: string,
    name: string,
    description?: string,
    internalName?: string,
    internalId?: string,
  ) =>
    await timetableAdminApi.updateCruise({
      name: name,
      description: description,
      internalName: internalName,
      internalId: internalId,
      id: id,
    }),

  deleteCruise: async (id: number) => await timetableAdminApi.deleteCruise(id),
  setScreenContent: async (
    screenId: string,
    contentType: ScreenModelContentTypeEnum,
    contentId: string,
  ) =>
    await screenAdminApi.setScreenContent({
      screenId: screenId,
      contentType: contentType as unknown as ScreenContentSetRequestContentTypeEnum,
      contentId: contentId,
    }),
  getMediaFiles: async () => await mediafileApi.getAll(),
  createMediaFile: async (title, filename) =>
    await mediafileApi.createMediaFile({ name: title, filename: filename }),
  updateMediaFile: async (title, filename) =>
    await mediafileApi.updateMediaFile({ name: title, filename: filename }),
  wakeUpScreens: async () => await screenAdminApi.wakeUpAllScreen(),
  wakeUpScreenByMAC: async (mac: string) => await screenAdminApi.wakeUpByMAC({ macAddress: mac }),

  login: async (username, password) =>
    await securityApi.login({ username: username, password: password }),
  refreshToken: async (refreshToken) =>
    await securityApi.refreshtoken({ refreshToken: refreshToken }),
  logout: () => TokenService.removeUser(),
  getUsers: async () => await securityApi.getUsers({}),
  getAllRoles: async () => await securityApi.getAllRoles(),
  getAllAuthorities: async () => await securityApi.getAllAuthorities(),
  setUserEnabled: async (username: string, enabled: boolean) =>
    await securityApi.setUsersEnabled(username, { username: username, enabled: enabled }),
  setUserRole: async (username: string, role: string) =>
    await securityApi.grantRoles(username, {
      username: username,
      roles: [role] as unknown as Set<string>,
    }),
  setPrivilegesToRole: async (rolename: string, privilegeIds: number[]) =>
    await securityApi.setPrivilegesToRole({
      roleName: rolename,
      privileges: privilegeIds as unknown as Set<number>,
    }),
  createRole: async (title: string) => await securityApi.createRole({ title: title }),
  updateTicketPriceIndexes: async (request: TicketPriceIndexUpdateRequests) =>
    await ticketPriceApi.updateTicketPriceIndexes(request),
  deleteRole: async (roleToDelete: string, replacementRole: string) =>
    await securityApi.deleteRole({ roleName: roleToDelete, replacementRole: replacementRole }),

  searchInformBy: async (type: InformTypeConstantDTO) =>
    await informApi.searchInform({
      type: type as SearchInformRequestTypeEnum,
    }),
  createUpdateInform: async (request: CreateUpdateInformRequest) =>
    await informApi.createUpdateInform(request),
  sendInfoToScreen: async (request: SendInformToScreenRequest) =>
    await informApi.sendInfoToScreen(request),
  sendActualityToContent: async (request: SendActualityToContentRequest) =>
    await informApi.sendActualityToContent(request),
  deleteInformById: async (request: DeleteInformRequest) =>
    await informApi.deleteInformById(request),
}

export default API
