import { DatePicker } from '@hmedia/legenda-ds-react'
import { Form, Input, Modal } from 'antd'
import { useForm } from 'antd/es/form/Form'
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import type { FC } from 'react'
const { RangePicker } = DatePicker

export type FormValues = {
  title: string
}

interface CreateRoleModalFormProps {
  visible: boolean
  onCreate?: (values: FormValues) => void
  onCancel?: () => void
}

const initialValues = { name: null, timeRange: null }
const requiredMessage = 'Kötelező megadni'

const requiredRule = { required: true, message: requiredMessage }

const CreateRoleModalForm: FC<CreateRoleModalFormProps> = ({ visible, onCancel, onCreate }) => {
  const [form] = useForm()

  return (
    <>
      <Modal
        title="Jogkör létrehozása"
        width={400}
        open={visible}
        okText="Létrehozás"
        cancelText="Mégsem"
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values)
              form.resetFields()
            })
            .catch((errorInfo: ValidateErrorEntity<FormValues>) => {
              const desc = errorInfo.errorFields.map((err) => {
                return err.errors.join('/n/r')
              })
            })
        }}
        onCancel={(_evt): void => {
          form.resetFields()
          onCancel()
        }}
      >
        <Form layout="inline" form={form} initialValues={initialValues} autoComplete="off">
          <Form.Item label="Jogkör neve" name="title" rules={[requiredRule]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateRoleModalForm
