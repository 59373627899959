import { DeleteTwoTone, EditTwoTone, PlusOutlined, WarningOutlined } from '@ant-design/icons'
import type { Cruise } from '@hmedia/legenda-ds-api-client'
import { DatePicker } from '@hmedia/legenda-ds-react'
import { Button, Modal, Popconfirm, Space, Table, Tag, Tooltip } from 'antd'
import type { ColumnType } from 'antd/es/table'
import type { Dayjs } from 'dayjs'
import { type FC, type MouseEventHandler, useState } from 'react'
import CruiseEditorDrawerForm from './CruiseEditorDrawerForm'
import { useCruisesQuery, useDeleteCruiseMutation } from './queries'
const { RangePicker } = DatePicker

export type FormValues = {
  name: string
  timeRange: [Dayjs, Dayjs]
}

interface CruiseManagerProps {
  visible: boolean
  onCreate?: (e) => void
  onCancel?: (e) => void
}

const CruiseManagerModal: FC<CruiseManagerProps> = ({ visible, onCancel, onCreate }) => {
  const [cruiseEditorModalIsVisible, setCruiseEditorModalIsVisible] = useState(false)
  const [selectedCruiseToEdit, setSelectedCruiseToEdit] = useState<Cruise | undefined>(undefined)
  const cruiseQuery = useCruisesQuery()
  const deleteCruiseMutation = useDeleteCruiseMutation(() => {
    cruiseQuery.refetch()
  })

  const columns: ColumnType<Cruise>[] = [
    {
      title: 'megnevezés',
      key: 'name',
      dataIndex: ['displayNameMap', 'en'],
      defaultSortOrder: 'ascend',
      sorter: (a, b, sortOrder?) => {
        return a.displayNameMap['en'].localeCompare(b.displayNameMap['en'])
      },
      render: (name: string, cruise: Cruise) => {
        return <span>{name}</span>
      },
    },
    {
      title: 'kiegészítő információ',
      key: 'description',
      dataIndex: ['description'],
      ellipsis: {
        showTitle: false,
      },
      render: (description: string, cruise: Cruise) => {
        return (
          <Tooltip overlay={description} placement="topLeft">
            {description}
          </Tooltip>
        )
      },
    },
    {
      title: 'összekapcsolt szolg.',
      key: 'mapping',
      dataIndex: ['internalId'],
      render: (internalId?: string, cruise?: Cruise) => {
        return internalId ? (
          <span>
            {cruise.internalName} ({internalId})
          </span>
        ) : (
          <Tag color={'red'} icon={<WarningOutlined />}>
            Hiányzó összekapcsolás
          </Tag>
        )
      },
    },
    {
      title: '',
      key: 'actions',
      dataIndex: null,
      width: '90px',
      render: (internalId?: string, cruise?: Cruise) => {
        return (
          <Space direction="horizontal">
            <Button
              type="text"
              onClick={(e) => {
                setSelectedCruiseToEdit(cruise)
                setCruiseEditorModalIsVisible(true)
              }}
              icon={<EditTwoTone />}
            ></Button>
            <Popconfirm
              title="Biztos, hogy törlöd a szolgáltatást és a hozzá tartozó jegyárakat"
              okText="Igen"
              cancelText="Nem"
              onConfirm={(e) => deleteCruiseMutation.mutate(cruise.id)}
            >
              <Button type="text" icon={<DeleteTwoTone twoToneColor={'red'} />}></Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const createCruiseButton = (onClick: MouseEventHandler<HTMLElement>) => (
    <Button type="primary" onClick={onClick} icon={<PlusOutlined />}>
      Új szolgáltatás
    </Button>
  )

  const onClose: (e: any) => void = (e) => {
    setCruiseEditorModalIsVisible(false)
    setSelectedCruiseToEdit(undefined)
  }
  return (
    <>
      <Modal
        title="Szolgáltatások kezelése"
        width={960}
        open={visible}
        cancelText="Bezárás"
        onOk={onCreate}
        footer={false}
        onCancel={onCancel}
      >
        <Table
          dataSource={cruiseQuery.data}
          rowKey={'id'}
          size="middle"
          title={() =>
            createCruiseButton((e) => {
              setCruiseEditorModalIsVisible(true)
            })
          }
          pagination={{
            hideOnSinglePage: true,
            position: ['bottomCenter'],
          }}
          // rowSelection={rowSelection}
          style={{ width: 'auto' }}
          columns={columns}
        />
        {cruiseEditorModalIsVisible && (
          <CruiseEditorDrawerForm
            visible={cruiseEditorModalIsVisible}
            cruise={selectedCruiseToEdit}
            onCreate={(e) => {
              cruiseQuery.refetch()
              setCruiseEditorModalIsVisible(false)
              setSelectedCruiseToEdit(undefined)
            }}
            onCancel={onClose}
            onClose={onClose}
          />
        )}
      </Modal>
    </>
  )
}

export default CruiseManagerModal
